import { DateTime } from "luxon";
import { RootState } from "StoreTypes";
import { styles } from "./SimulatorReservation.styles";
import { useSelector } from "react-redux";
import { Player, SimulatorReservation } from "Models";
import { AnimatePresence, motion } from "framer-motion";
import { dailyQueueListItemVariant } from "utils/animations/animationHelper";
import { getSimulatorBySimId } from "modules/simulator/simulatorSelectors";
import SelectedSimulatorReservation from "./SimulatorReservationDetails";

const SimulatorReservationItem: React.FC<SimulatorReservationItemProps> = (
  props
) => {
  const { simulatorReservation, isOpen, callback, highlightItem } = props;
  const simulator = useSelector((state: RootState) =>
    getSimulatorBySimId(state, {
      simId: props.simulatorReservation.simulatorId,
    })
  );

  return (
    <motion.li
      animate={{ opacity: 1, x: 0 }}
      layout
      variants={dailyQueueListItemVariant}
      className={
        highlightItem
          ? styles.simulatorReservationListItem
          : styles.simulatorReservationListItemHighlight
      }
      key={`simulatorReservationListItem-${simulatorReservation?.id}`}
    >
      <motion.div animate={{ opacity: 1, x: 0 }} layout className="block">
        <div className={styles.simulatorReservationQueueContainer}>
          <div className={styles.simulatorReservationQueueTopRow}>
            <p className={styles.simulatorReservationTime}>
              {DateTime.fromISO(
                simulatorReservation.startTime.toString()
              ).toFormat("t")}{" "}
              -{" "}
              {DateTime.fromISO(
                simulatorReservation.endTime.toString()
              ).toFormat("t")}
            </p>

            <div className={styles.simulatorReservationQueueBottomRow}>
              <p className={styles.simulatorReservationName}>
                {simulator?.name}
              </p>
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <p className={styles.simulatorReservationPlayers}>
                {simulatorReservation.roundConfiguration?.roundInfo?.players?.map(
                  (player: Player) => (
                    <span className="mr-1">
                      {player.displayName || player.username}
                    </span>
                  )
                )}
              </p>
            </div>
            <div className={styles.simulatorReservationOpenItem}>
              <p>
                <button
                  onClick={() => callback("")}
                  className={`hover:text-black hover:underline text-green-900 ${
                    isOpen ? "text-gray-500" : ""
                  }`}
                >
                  {isOpen ? "Close" : "Load/Edit Round"}
                </button>
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      <AnimatePresence>
        {props.isOpen && (
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`${
              highlightItem ? "mx-4 sm:mx-6" : "mx-0 sm:mx-6"
            } py-4  border-t border-gray-200`}
            key="SelectedSimulatorReservationItemPreview"
          >
            <SelectedSimulatorReservation
              simulatorReservation={props.simulatorReservation}
              key={props.simulatorReservation.id}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.li>
  );
};
export default SimulatorReservationItem;

type SimulatorReservationItemProps = {
  simulatorReservation: SimulatorReservation;
  isOpen?: boolean;
  callback: React.Dispatch<React.SetStateAction<string>>;
  highlightItem?: boolean;
};

SimulatorReservationItem.defaultProps = {
  highlightItem: true,
};
