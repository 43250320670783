import {
  sendControlToSimulator,
  simulatorControlType,
} from "modules/simulator";
import { HiOutlineStop } from "react-icons/hi";
import ReactTooltip from "react-tooltip";
import {
  notificationMessageGroup,
  showResponseNotification,
} from "utils/notifications/notificationHelpers";

const SimEndRoundButton: React.FC<EndRoundButtonProps> = (props) => {
  const useHandleEndRound = () => {
    if (window.confirm("End the round?")) {
      const response = sendControlToSimulator(
        simulatorControlType.end,
        props.simulatorId,
        props.simulatorIds
      );

      showResponseNotification(response, notificationMessageGroup.endRound);
    }
  };

  return (
    <>
      <button
        className={props.className}
        onClick={useHandleEndRound}
        data-tip="End Round"
        data-testid="endRoundListViewButton"
        data-for={`simEndRoundButton-${props.simulatorId}`}
      >
        {props.showIcon ? (
          <HiOutlineStop className="text-lg p-2 font-semibold brand-gray hover:text-gray-700 active:shadow-lg cursor-pointer h-8 w-8 rounded-full border border-gray-200 bg-white brand-gray dark:text-white dark:bg-zinc-800 dark:border-2" />
        ) : null}
        {props.showText ? "End Round" : null}
      </button>
      <ReactTooltip
        place="left"
        delayShow={200}
        backgroundColor="#f9fafb"
        textColor="#4b5563"
        border={true}
        borderColor="#d1d5db"
        id={`simEndRoundButton-${props.simulatorId}`}
        className="!text-xs"
      />
    </>
  );
};
export default SimEndRoundButton;

type EndRoundButtonProps = {
  simulatorId?: string;
  simulatorIds?: string[];
  className?: string;
  showIcon?: boolean;
  showText?: boolean;
};
SimEndRoundButton.defaultProps = {
  className:
    "ml-0 shadow hover:shadow-lg py-2 px-6 inline-flex items-center w-full sm:w-1/2 md:w-36 h-12 bg-white text-gray-800 font-semibold text-sm rounded border-b-2 border-red-500 hover:border-red-600 hover:bg-red-100 active:bg-red-200 hover:text-red-800",
  showIcon: true,
  showText: true,
};
