import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { styles } from "./RoundConfigurationManagement.styles";
import { DefaultRoundSettings, RoundConfiguration } from "Models";
import Row from "components/ui/layout/Row";
import Label from "components/ui/form/Label";
import RoundConfigurationDeleteConfirm from "./RoundConfigurationDeleteConfirm";
import { isModalOpen, modalName, ModalType } from "utils/modals/modalHelpers";

const RoundConfigDeleteRow: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const setModalOpen = (modalName: string) =>
    dispatch(modalAction({ isOpen: true, modalName }));

  const useHandleDelete = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setModalOpen(deleteConfirmModalName);
  };

  const deleteConfirmModalName = modalName(
    ModalType.RoundConfigurationDeleteConfirm,
    props.config.id
  );

  const isRoundConfig = (
    config: RoundConfiguration | DefaultRoundSettings
  ): config is RoundConfiguration => {
    return (config as RoundConfiguration).name !== undefined;
  };

  return (
    <>
      {isModalOpen(deleteConfirmModalName) && (
        <RoundConfigurationDeleteConfirm
          title={props.config.name}
          modalName={deleteConfirmModalName}
          roundConfig={props.config}
        />
      )}
      <Row cssClasses={styles.rowSmall}>
        {isRoundConfig(props.config) ? (
          <Row cssClasses="w-full flex">
            <Row cssClasses="w-3/4 pl-0 md:pl-2">
              <Label
                name={props.config.name ?? "Default Settings"}
                cssClasses="w-32 mr-2 font-normal block text-md md:text-normal"
              />
            </Row>
            <Row cssClasses="w-1/4 float-right">
              <button
                className={styles.delete}
                onClick={useHandleDelete}
                value={props.config.id}
              >
                Delete
              </button>
            </Row>
          </Row>
        ) : null}
      </Row>
    </>
  );
};
export default RoundConfigDeleteRow;

type Props = {
  config: RoundConfiguration;
};
