import { DateTime } from "luxon";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { BreakPoints, useMediaQuery } from "utils/animations/animationHelper";
import { queueAction } from "ui-modules/nav/navSlice";
import {
  setLocalStorageValue,
  LocalStorageItems,
} from "utils/hooks/localStorage";
import { useAppDispatch } from "utils/hooks";

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "green" : "black",
    backgroundColor: state.isDarkMode ? "#e1f0ff" : "#ffffff",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: "2px solid #e5e7eb",
    borderRadius: 10,
    paddingTop: 5,
    paddingBottom: 5,
    ":valid": {
      border: "2px solid #22c55e",
    },
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#f3f4f6",
    color: "#000000",
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    color: "#000000",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: "#1f293",
    ":hover": {
      backgroundColor: "#E1341E",
      color: "white",
    },
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    padding: "10px",
  }),
};

export const customStylesMiminalist = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "green" : "black",
    backgroundColor: state.isDarkMode ? "#e1f0ff" : "#ffffff",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: "0px solid #e5e7eb",
    borderRadius: 10,
    paddingTop: 2,
    paddingBottom: 2,
    ":valid": {
      border: "2px solid #22c55e",
    },
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#f3f4f6",
    color: "#000000",
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    color: "#000000",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: "#1f293",
    ":hover": {
      backgroundColor: "#E1341E",
      color: "white",
    },
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    padding: "10px",
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
};

export const scorcardSearchStyle = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "green" : "black",
    backgroundColor: state.isSelected ? "#f5f5f5" : "#ffffff",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: "0px solid #e5e7eb",
    borderRadius: 30,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: "#F8F8F8",
    fontSize: "12px",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    display: "none",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: "none",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#f3f4f6",
    color: "#000000",
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    color: "#000000",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: "#1f293",
    ":hover": {
      backgroundColor: "#E1341E",
      color: "white",
    },
  }),
  noOptionsMessage: (provided: any, state: any) => ({
    ...provided,
    padding: "10px",
  }),
};

export const datetimeLocalFromUtcIso = (date: string) => {
  var offset = DateTime.fromISO(date).offset;

  return DateTime.fromISO(date)
    .plus({ minutes: offset })
    .toLocaleString(DateTime.DATETIME_MED);
};

export const datetimeStringWithOffset = (date: Date) => {
  let offset = DateTime.fromISO(date.toISOString()).offset;

  return DateTime.fromISO(date.toISOString())
    .plus({
      minutes: offset,
    })
    .toJSDate()
    .toISOString();
};

export const datetimeStringStartOfDay = (date: Date) => {
  return DateTime.fromISO(date.toISOString())
    .startOf("day")
    .plus({ minutes: 1 })
    .toLocaleString(DateTime.DATETIME_MED);
};

export const appendLocalTimezoneToIso = (date: string) => {
  return DateTime.fromISO(date).setZone("system").toISO();
};

export const useSidebarCollapsed = (): boolean => {
  const isDesktop = useMediaQuery(BreakPoints.MD);
  const sidebarOpen = useSelector(
    (state: RootState) => state.nav?.collapseSidebar?.isOpen
  );

  return isDesktop ? sidebarOpen : false;
};

export async function useToggleDashboardQueue(value: boolean) {
  const dispatch = useAppDispatch();
  await setLocalStorageValue(
    LocalStorageItems.DASHBOARDQUEUE,
    JSON.stringify(value)
  );
  dispatch(queueAction({ showOnDashboard: value }));
}

export const filterTime = (date: { getTime: () => number }) => {
  const isPastTime = new Date().getTime() > date.getTime();
  return !isPastTime;
};

export const disableBackgroundScroll = () => {
  if (typeof window != "undefined" && window.document) {
    document.body.style.overflow = "hidden";
  }
};

export const enableBackgroundScroll = () => {
  document.body.style.overflow = "unset";
};

export function enforceMinAndMaxScores(e: HTMLInputElement) {
  if (e.value !== "") {
    if (parseInt(e.value) < parseInt(e.min)) {
      e.value = e.min;
    }
    if (parseInt(e.value) > parseInt(e.max)) {
      e.value = e.max;
    }
  }
}
