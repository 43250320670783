import { PlayerUser } from "Models";
import { FaGolfBall } from "react-icons/fa";
import { FormatOptionLabelContext } from "react-select/src/Select";
import { Gender } from "types/enums";
import { teeTypeIconColorHelper } from "utils/icons/iconHelpers";

const FormattedUserSearchOption = ({
  value,
  context,
}: {
  value: PlayerUser;
  context: FormatOptionLabelContext;
}) => {
  return context === "menu" ? (
    <div>
      <FaGolfBall
        className={`mr-4 ${teeTypeIconColorHelper[value.teeType?.toString()]}`}
      />
      {value.displayName ? (
        <strong>
          {value.displayName}({Gender[value.gender]?.[0]})
        </strong>
      ) : null}{" "}
      {value.username ? (
        <span className="text-gray-500">- {value.username}</span>
      ) : null}{" "}
      {value.email ? (
        <span className="text-gray-500">| {value.email}</span>
      ) : null}
    </div>
  ) : (
    <div>{value.username || value.displayName || value.email}</div>
  );
};
export default FormattedUserSearchOption;
