import { useCallback } from "react";
import { RootState } from "StoreTypes";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import { queueItemTrayAction } from "ui-modules/nav/navSlice";
import { setSelectedQueueItem } from "ui-modules/queue/queueSlice";
import { styles } from "components/shared/helpdocs/HelpDocTray.styles";
import QueueItemContainer from "./QueueItemContainer";
import { useSelectedQueueItem } from "modules/simulator-reservations/simulatorReservationHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";

const CreateQueueItemTray: React.FC<CreateQueueItemTrayProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedQueueItem = useSelectedQueueItem(currentFacilityId);
  const isQueueItemTrayOpen = useSelector(
    (state: RootState) => state.nav?.queueItemTray?.isOpen
  );
  const handleCreateQueueTrayClosed = useCallback(() => {
    dispatch(
      setSelectedQueueItem({
        selectedQueueItem: "",
      })
    );
    dispatch(queueItemTrayAction({ isOpen: false }));
  }, [dispatch]);

  return (
    <AnimatePresence>
      {isQueueItemTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: "0px",
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.75 }}
            className={`${styles.trayContainer} z-9998`}
            key="createQueueItemTray"
            data-testid="createQueueItemTray"
            id="createQueueItemTray"
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => handleCreateQueueTrayClosed()}
                  className={styles.button}
                >
                  <span className="text-white">
                    <MdClose className="h-4 w-4" />
                  </span>{" "}
                  Close
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>
                  {" "}
                  {selectedQueueItem ? "Edit" : "Create"} Reservation
                </h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <QueueItemContainer />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            id="reservationTrayOverlay"
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => handleCreateQueueTrayClosed()}
            className={styles.overlay}
          ></motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
export default CreateQueueItemTray;

type CreateQueueItemTrayProps = {
  simulatorId?: string;
  createRound?: boolean;
};
