import { DateTime } from "luxon";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { FaArrowLeft } from "react-icons/fa";
import { styles } from "../scorecard/Scorecard.styles";
import { AnimatePresence, motion } from "framer-motion";
import { leagueScorecardTrayAction } from "ui-modules/nav/navSlice";
import { useSelectedLeague } from "../../modules/leagues/leagueHooks";
import { useSelectedLeagueRound } from "modules/leagues/leagueRoundHooks";
import LeagueScorecardSearch from "./LeagueScorecardSearch";
import { enableBackgroundScroll } from "utils/ui/uiHelpers";

const LeagueScorecardSearchTray = () => {
  const dispatch = useAppDispatch();
  const selectedLeague = useSelectedLeague();
  const selectedLeagueRound = useSelectedLeagueRound();

  const isLeagueScorecardTrayOpen = useSelector(
    (state: RootState) => state.nav.leagueScorecardTray?.isOpen
  );

  const setScorecardTray = () => {
    enableBackgroundScroll();
    dispatch(leagueScorecardTrayAction({ isOpen: !isLeagueScorecardTrayOpen }));
  };

  return (
    <AnimatePresence>
      {isLeagueScorecardTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: 0,
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.4 }}
            className={styles.trayContainer}
            data-testid="scorecardTray"
            id="scorecardTray"
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => setScorecardTray()}
                  className="rounded-full shadow bg-darkCream dark:bg-zinc-900 poppins py-1 px-1.5"
                >
                  <FaArrowLeft className="h-4 w-4" />
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>
                  {selectedLeague?.name} Scorecards
                </h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <div className="poppins text-center w-full pt-4">
                {selectedLeagueRound?.name}
                <br />
                <small>
                  {DateTime.fromISO(
                    selectedLeagueRound?.startPlayDate
                  ).toLocaleString(DateTime.DATETIME_MED)}{" "}
                  -{" "}
                  {DateTime.fromISO(
                    selectedLeagueRound?.endPlayDate
                  ).toLocaleString(DateTime.DATETIME_MED)}
                </small>
              </div>
              <LeagueScorecardSearch />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => setScorecardTray()}
            className={styles.overlay}
            data-testid="onlineEventTrayOverlay"
            id="onlineEventTrayOverlay"
          />
        </>
      )}
    </AnimatePresence>
  );
};
export default LeagueScorecardSearchTray;
