import { useState } from "react";
import { MdClose } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { styles } from "./RosterAndGroups.styles";
import { customStyles } from "utils/ui/uiHelpers";
import { useSelectedLeague } from "modules/leagues/leagueHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  useDeleteLeagueTeam,
  useUpdateLeagueTeam,
} from "modules/leagues/leagueTeamsHooks";
import { Gender, Handedness, TeamPlayerStatus } from "types/enums";

import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";
import {
  ClubhouseRoundTeamDto,
  CreateTeamCommandPayload,
  LeagueRosterEntryDto,
  PlayerDto,
  TeamPlayerDto,
} from "Models";
import { isApiStatusSuccess } from "utils/api/apiHelper";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import Select from "react-select";
import { useLeagueRosterByLeagueId } from "modules/leagues/leagueRosterHooks";

type TeamProps = {
  team: ClubhouseRoundTeamDto;
  leagueRoster: LeagueRosterEntryDto[];
  leagueTeams: ClubhouseRoundTeamDto[];
  callback?: any;
};

const Team: React.FC<TeamProps> = (props) => {
  const { team, callback } = props;

  const selectedLeague = useSelectedLeague();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const leagueTeamSize = selectedLeague?.settings.leagueTeamSize || 2;

  const [selectedOption, setSelectedOption] = useState<PlayerDto | null>(null);
  const [isEditTeam, setIsEditTeam] = useState(() => false);
  const [teamName, setTeamName] = useState<string>(() => team?.name || "");
  const [updatedTeamPlayerList, setUpdatedTeamPlayerList] = useState<
    TeamPlayerDto[]
  >(() => team?.teamMembers);

  const [, updateTeam] = useUpdateLeagueTeam(
    currentFacilityId,
    selectedLeague?.id,
    team?.id?.toString()
  );

  const [, deleteTeam] = useDeleteLeagueTeam(
    currentFacilityId,
    selectedLeague?.id,
    team?.id?.toString()
  );
  const [leagueRoster, ,] = useLeagueRosterByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );

  const [availableRosterPlayers, setAvailableRosterPlayers] = useState<
    LeagueRosterEntryDto[]
  >(() =>
    leagueRoster.filter((player: LeagueRosterEntryDto) => !player.teamId)
  );

  const handleNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTeamName(event?.currentTarget?.value);
  };

  const handleSelectedOptionChange = (val: LeagueRosterEntryDto) => {
    setUpdatedTeamPlayerList([
      ...updatedTeamPlayerList,
      {
        playerId: val.userGuid,
        playerStatus: TeamPlayerStatus.NORMAL,
        userName: val.userName,
        email: val.userEmail,
        phoneNumber: val.userPhone,
        agHandicap: val.aGHandicapIndex,
      },
    ]);
    setAvailableRosterPlayers((teamPlayers) => {
      return teamPlayers.filter((player) => player.userGuid !== val.userGuid);
    });
  };

  const handleDeleteTeam = () => {
    if (window.confirm(`Delete ${team.name}?`)) {
      let response = deleteTeam();

      response?.then((res) => {
        if (isApiStatusSuccess(res?.status)) {
          showCustomNotification(
            `${team.name} deleted successfully`,
            GenericNotificationType.SUCCESS
          );
        } else {
          showCustomNotification(
            "An error occurred",
            GenericNotificationType.ERROR
          );
        }
      });
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_DELETE_TEAM);
    }
  };

  const handleEditTeamClick = () => {
    setIsEditTeam(true);
    setUpdatedTeamPlayerList(team.teamMembers);
    setSelectedOption(null);
  };

  const handleCancelEditTeam = () => {
    setIsEditTeam(false);
    setAvailableRosterPlayers(
      leagueRoster.filter((player: LeagueRosterEntryDto) => !player.teamId)
    );
  };

  const handleUpdateTeamChange = () => {
    let payload = {} as CreateTeamCommandPayload;

    payload.name = teamName || team.name || "";
    payload.teamMembers = updatedTeamPlayerList.map(
      (player) => player.playerId
    );

    let response = updateTeam(payload);

    response?.then((res) => {
      if (isApiStatusSuccess(res?.status)) {
        showCustomNotification(team.name + " has been updated");
        setIsEditTeam(false);
        setTeamName("");
        pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_EDIT_TEAM);
        callback && callback();
      } else {
        showCustomNotification(res.body?.detail, GenericNotificationType.ERROR);
      }
    });
  };

  return (
    <div
      className={`${
        isEditTeam
          ? "border last:border-none border-gray-300 shadow-md"
          : "bg-white dark:bg-zinc-800"
      } rounded-lg p-4 my-8`}
    >
      <div className="flex flex-row">
        <div className="poppins text-black w-3/4 h-10">
          <div className="flex flex-row">
            <div className="flex w-4/5">
              {isEditTeam ? (
                <input
                  className={styles.editTeamName}
                  placeholder="Enter a team name"
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    handleNameChange(e)
                  }
                  value={teamName || team.name}
                />
              ) : (
                <span className={styles.teamName}>{team.name}</span>
              )}
            </div>
            <div className="flex w-1/5"></div>
          </div>
        </div>
        <div className="w-1/4 justify-items-end">
          <div className={styles.leagueEditDeleteContainer}>
            <button
              type="button"
              className={styles.leagueDeleteButton}
              data-for={`delete-${team.name}`}
              data-tip={`Delete ${team.name}`}
              onClick={() => handleDeleteTeam()}
            >
              <RiDeleteBinLine />
            </button>
            <button
              type="button"
              className={styles.leagueEditButton}
              data-for={`edit-${team.name}`}
              data-tip={`Edit ${team.name}`}
              onClick={() => handleEditTeamClick()}
            >
              {isEditTeam ? <MdClose /> : <FiEdit2 />}
            </button>
          </div>
        </div>
      </div>

      {updatedTeamPlayerList.map((teamMember: TeamPlayerDto, index) => {
        return (
          <div
            className={`${styles.teamPlayerListContainer}`}
            key={`team-${team.id}-${index}`}
          >
            <div className="col-span-4 text-ellipsis truncate w-full">
              {teamMember?.userName
                ? teamMember?.userName
                : teamMember.firstName + " " + teamMember?.lastName}
            </div>
            <div className="col-span-5 text-ellipsis truncate w-full">
              {teamMember.email}
            </div>
            <div className="col-span-1">{teamMember.agHandicap}</div>
            <div className="col-span-2 text-center">
              <button
                onClick={() => {
                  setUpdatedTeamPlayerList(
                    updatedTeamPlayerList.filter(
                      (player) => player.playerId !== teamMember.playerId
                    )
                  );
                  setAvailableRosterPlayers((teamPlayers) => {
                    return [
                      ...teamPlayers,
                      {
                        userGuid: teamMember.playerId,
                        userName: teamMember.userName || "",
                        firstName: teamMember.firstName || "",
                        lastName: teamMember.lastName || "",
                        userPhone: teamMember.phoneNumber || "",
                        playerStatus: teamMember.playerStatus,
                        userEmail: teamMember.email || "",
                        aGHandicapIndex: teamMember.agHandicap || 0,
                        gender: Gender.MALE,
                        handedness: Handedness.RIGHT,
                      },
                    ];
                  });
                }}
                className={isEditTeam ? "" : "hidden"}
              >
                <span className={styles.minusIcon}>-</span>
              </button>
            </div>
          </div>
        );
      })}

      <div className={`py-4 ${isEditTeam ? "items-center" : "hidden"}`}>
        <div className="col-span-12">
          <Select
            className=""
            placeholder={
              Object.entries(updatedTeamPlayerList).length >= leagueTeamSize
                ? "Team is full (two players maximum)"
                : `Select a player to add to ${team.name}`
            }
            isSearchable={false}
            closeMenuOnSelect={true}
            isMulti={false}
            options={availableRosterPlayers}
            getOptionLabel={(config: LeagueRosterEntryDto) =>
              config.firstName
                ? config.firstName + " " + config.lastName
                : config.userName
            }
            getOptionValue={(config: LeagueRosterEntryDto) => config}
            onChange={(val: LeagueRosterEntryDto) =>
              handleSelectedOptionChange(val)
            }
            value={selectedOption}
            styles={customStyles}
            isDisabled={
              Object.entries(updatedTeamPlayerList).length >= leagueTeamSize
            }
          />
        </div>
      </div>
      <div className={`flex flex-row w-1/2 ${isEditTeam ? "" : "hidden"}`}>
        <div className="flex w-1/2 mr-2">
          <button
            className={styles.leagueRosterActionButton}
            onClick={() => handleUpdateTeamChange()}
          >
            Update Team
          </button>
        </div>
        <div className="flex w-1/2">
          <button
            className={styles.leagueRosterActionButtonAlt}
            onClick={() => handleCancelEditTeam()}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default Team;
