import { DateTime } from "luxon";
import { useLocation } from "react-router-dom";
import { getCurrentUserToken } from "modules/user";
import {
  AutoConcede,
  AutoContinue,
  AutoGimme,
  GimmeDistance,
  Ground,
  HandicapPercentage,
  LeagueHandicapMethod,
  LeagueHandicapMode,
  LeagueRoundCompetitionType,
  LeagueScoring,
  LeagueTeamSize,
  LeagueType,
  PinType,
  ScoringType,
  Sky,
  TeeType,
  TimeOfDay,
  WindDirection,
  WindSpeed,
} from "types/enums";
import { greenStimpOptions } from "utils/enums/enumHelper";
import { useSelectedLeagueRound } from "./leagueRoundHooks";
import {
  RankedCompetitionResult,
  RoundLeaderboardDto,
  UpdateRoundPointsPayload,
} from "Models";
import { datetimeStringStartOfDay } from "utils/ui/uiHelpers";

export const leagueQueryDefaultOptions = (): {
  headers: {
    authorization: string;
    "Ocp-Apim-Subscription-Key": string;
    "x-api-version": string;
  };
} => ({
  headers: {
    authorization: `Bearer ${getCurrentUserToken()}`,
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_KEY || "",
    "x-api-version": "2.0",
  },
});
export const leagueQueryLockerAPIDefaultOptions = (): {
  headers: {
    authorization: string;
    "Ocp-Apim-Subscription-Key": string;
    "x-api-version": string;
    "API-Key": string;
  };
} => ({
  headers: {
    authorization: `Bearer ${getCurrentUserToken()}`,
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_KEY || "",
    "x-api-version": "2.0",
    "API-Key": `${process.env.REACT_APP_API_KEY_LOCKER}`,
  },
});

export const leagueTypeColorHelper = {
  [LeagueType.TEAM.toString()]: "league-team",
  [LeagueType.INDIVIDUAL.toString()]: "league-individual",
};

export const leagueDefaults = (facilityId: string) => ({
  name: "",
  facilityId: facilityId,
  description: "",
  leagueScoringType: LeagueScoring.STROKE_PLAY,
  leagueTeamSize: LeagueTeamSize.TWO,
  leagueStartDate: datetimeStringStartOfDay(new Date()),
  leagueStartDateTimezone: DateTime.local().zoneName,
  leagueEndDateTimezone: DateTime.local().zoneName,
  settings: {
    leagueType: LeagueType.INDIVIDUAL,
    leagueScoringType: LeagueScoring.STROKE_PLAY,
    handicapMethod: LeagueHandicapMethod.NET,
    handicapMode: LeagueHandicapMode.COURSE,
    handicapPercentage: HandicapPercentage["100%"],
    participantCap: 150,
    leagueTeamSize: LeagueTeamSize.TWO,
    allowAsynchronousMatchPlay: null,
  },
});

export const leagueRoundDefaults = () => {
  return {
    name: "",
    description: "",
    startPlayDate: new Date().toISOString(),
    endPlayDate: new Date().toISOString(),
    startPlayTimezone: DateTime.local().zoneName,
    endPlayTimezone: DateTime.local().zoneName,
    course: "",
    courseShortName: "",
    courseSettings: {
      selectedHoles: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      autoConcede: AutoConcede.OFF,
      autoContinue: AutoContinue.OFF,
      autoDrop: false,
      autoGimmes: AutoGimme["1STROKE"],
      gimmeDistance: GimmeDistance.OFF,
      mulligans: false,
      pinType: PinType.NOVICE,
      scoringType: ScoringType.STROKE,
      windSpeed: WindSpeed.CALM,
      stimpSetting: greenStimpOptions[0].value,
      mensTee: TeeType.BLUE,
      womensTee: TeeType.RED,
      groundConditions: Ground.NORMAL,
      skyConditions: Sky.CLEAR,
      windDirection: WindDirection.N,
      timeConditions: TimeOfDay.AFTERNOON,
    },
    competitionType: [
      {
        competitionType: LeagueRoundCompetitionType.STROKE_PLAY,
      },
    ],
  };
};

export const formatTeamMemberPhone = (input: string) => {
  if (!input) return;
  return input.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};

export const useLeagueRoundStartDatePassed = () => {
  const selectedLeagueRound = useSelectedLeagueRound();
  return DateTime.fromISO(selectedLeagueRound?.startPlayDate) < DateTime.now()
    ? true
    : false;
};

export const useLeagueRoundEndDatePassed = () => {
  const selectedLeagueRound = useSelectedLeagueRound();
  return DateTime.fromISO(selectedLeagueRound?.endPlayDate) < DateTime.now()
    ? true
    : false;
};

export const useIsLeagueLocation = () => {
  let location = useLocation();
  return location.pathname.includes("leagues");
};

export const defaultRoundLeaderboardPointsPayload = (
  leagueRoundLeaderboard: RoundLeaderboardDto
): UpdateRoundPointsPayload => {
  let players: any[] = [];
  const roundPlayers = leagueRoundLeaderboard?.players?.map(
    (leaderboardPlayer: any) => {
      let player = {
        playerRoundLeaderBoardId: leaderboardPlayer?.roundPlayerId,
        points: leaderboardPlayer?.previousPoints,
        place: leaderboardPlayer?.previousRank,
      };
      return players.push(player);
    }
  );

  return {
    roundLeaderboardId: leagueRoundLeaderboard?.roundLeaderboardId,
    scores: [
      {
        competitionId: "",
        players: roundPlayers,
      },
    ],
  };
};

export const getLeagueRoundCompetitionId = (
  scores: RankedCompetitionResult
) => {
  if (!scores || Object.keys(scores).length === 0) return 0;

  let key = Object.keys(scores)[0];
  return (scores[Number(key)] as { competitionId: number }).competitionId;
};
