import {
  League,
  LeagueLeaderboardPlayerDto,
  LeagueRosterEntryDto,
} from "Models";
import { useState } from "react";
import { motion } from "framer-motion";
import { styles } from "./Leagues.styes";
import { MdClose } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { useAppDispatch } from "utils/hooks";
import { IoSearchOutline } from "react-icons/io5";
import { scorecardTrayAction } from "ui-modules/nav/navSlice";
import { leagueModalVariant } from "utils/animations/animationHelper";
import { useLeagueRosterByLeagueId } from "modules/leagues/leagueRosterHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useLeagueLeaderboardByLeagueId } from "modules/leagues/leagueScorecardHooks";
import LeagueScorecard from "./LeagueScorecard";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import LeagueScorecardPlayer from "./LeagueScorecardPlayer";

type LeagueLeaderboardTeamModalProps = {
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
  league: League;
};

const LeagueLeaderboardTeamModal: React.FC<LeagueLeaderboardTeamModalProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const { callback, league } = props;

  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [showNet, setShowNet] = useState(false);

  const [
    leagueLeaderboard,
    leagueLeaderboardQueryState,
  ] = useLeagueLeaderboardByLeagueId(currentFacilityId, league?.id);

  const [leagueRoster, ,] = useLeagueRosterByLeagueId(
    currentFacilityId,
    league?.id
  );

  const setScorecardTray = (value: boolean) =>
    dispatch(scorecardTrayAction({ isOpen: value }));

  const LeagueModalHeader = () => {
    const handleCloseModal = async () => {
      callback && callback(true);
    };

    return (
      <div className={styles.modalHeader} id="modalHeader">
        <div className={styles.modalHeading}>
          <button
            className={styles.modalCloseButton}
            onClick={() => handleCloseModal()}
          >
            <IoMdClose className="size-4" />
          </button>
          {league?.name} Leaderboard
        </div>
        <div className={styles.modalToggleContainerOuter}>
          <div>
            <button
              className={styles.modalSearchButton}
              onClick={() => setScorecardTray(true)}
            >
              <IoSearchOutline className="size-4 font-normal" />
              <span className="inline md:hidden poppins pr-2">Search</span>
            </button>
          </div>
          <div className={styles.modalToggleContainer}>
            <button
              className={`${!showNet ? styles.modalToggleActive : ""} ${
                styles.modalToggleInactive
              }`}
              id="net"
              onClick={() => setShowNet(false)}
            >
              <span className="">Net</span>
            </button>
            <button
              className={` ${showNet ? styles.modalToggleActive : ""} ${
                styles.modalToggleInactive
              }`}
              id="gross"
              onClick={() => setShowNet(true)}
            >
              <span className="">Gross</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const LeagueModalLoading = () => {
    return (
      <div className={styles.modalLoading}>
        <SpinnerAnimation color={"#f2f2e6"} />
      </div>
    );
  };

  const LeagueModalNoResults = () => {
    return (
      <div className={styles.modalNoResults}>
        <MdClose className={styles.noLeaguesIcon} />
        There is no leaderboard yet for {league?.name}.
      </div>
    );
  };

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-full h-dvh relative"
      variants={leagueModalVariant}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <LeagueModalHeader />
      <div className={styles.modalBody} id="modalBody" tabIndex={0}>
        {leagueLeaderboardQueryState?.isFinished ? (
          leagueLeaderboard ? (
            <div className="w-full">
              <div className={styles.modalResultsHeaderRow}>
                <div className="col-span-1">Pos</div>
                <div className="col-span-3">Full Name</div>
                <div className="hidden md:inline-block col-span-2"></div>
                <div className="hidden md:inline-block col-span-2"></div>
                <div className="col-span-2 text-left">Points</div>
                <div className="col-span-1"></div>
              </div>
              {Object.keys(leagueLeaderboard?.scores).length === 0 && (
                <LeagueModalNoResults />
              )}
              {leagueLeaderboard &&
                leagueLeaderboard?.scores?.map(
                  (team: LeagueLeaderboardPlayerDto, index: number) => (
                    <>
                      <LeagueScorecard
                        leaderboardPlayer={team}
                        key={`${team?.playerGuid}-scorecard`}
                        leagueType={leagueLeaderboard.leagueType}
                      />
                      {leagueRoster
                        .filter(
                          (player: LeagueRosterEntryDto) =>
                            player.teamId === team.teamId
                        )
                        .map((player: LeagueRosterEntryDto) => (
                          <LeagueScorecardPlayer
                            leaderboardPlayer={player}
                            key={`${team?.playerGuid}-scorecard`}
                          />
                        ))}
                    </>
                  )
                )}
            </div>
          ) : (
            <LeagueModalNoResults />
          )
        ) : (
          <LeagueModalLoading />
        )}
      </div>
    </motion.div>
  );
};

export default LeagueLeaderboardTeamModal;
