import { SimulatorState } from "Models";
import { SimStatus } from "types/enums";
import { simStatusEnumDisplayNameKeyedByString } from "utils/enums/enumHelper";

type OwnProps = {
  simulatorState: SimulatorState;
};

const SimStatusName: React.FC<OwnProps> = ({ simulatorState }) => {
  return (
    <>
      {simStatusEnumDisplayNameKeyedByString[SimStatus[simulatorState?.status]]}
    </>
  );
};
export default SimStatusName;
