import { BsClipboardData } from "react-icons/bs";
import { scorecardTrayAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { styles } from "../ui/layout/Header.styles";
import { disableBackgroundScroll } from "utils/ui/uiHelpers";

const ScorecardLookupButton = () => {
  const dispatch = useAppDispatch();
  const setScorecardTray = (value: boolean) => {
    dispatch(scorecardTrayAction({ isOpen: value }));
    disableBackgroundScroll();
  };

  return (
    <div className="pl-6 text-center items-center md:border-l md:border-gray-200">
      <button
        type="button"
        onClick={() => setScorecardTray(true)}
        id="headerScorecardLookupButton"
        data-tip="Scorecard Search"
        data-for="headerButtonsTooltip"
      >
        <BsClipboardData
          className={`${styles.buttonIcon} dark:text-gray-200`}
        />
      </button>
      <span className={styles.buttonText}>Score</span>
    </div>
  );
};

export default ScorecardLookupButton;
