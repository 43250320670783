import { QueryState, EntitiesState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import {
  getLeagueRoundMatches,
  makeCreateMultipleMatches,
  makeCreateSingleMatch,
  makeDeleteSingleMatch,
} from "./leagueMatchQueries";
import {
  GetMatchResponseDto,
  MultipleMatchIndividualPayload,
  MultipleMatchTeamPayload,
  SingleMatchIndividualPayload,
  SingleMatchTeamPayload,
} from "MatchPlayModels";
import { UseDataInfo } from "QueryTypes";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";

export const useGetLeagueRoundMatches = (
  facilityId: string,
  leagueId: string,
  roundId: string
): UseDataInfo<GetMatchResponseDto[]> => {
  const [queryState, refreshLeagueRoundMatches] = useRequest(
    getLeagueRoundMatches(facilityId, leagueId, roundId)
  );

  const leagueRoundMatches = useSelector(
    (state: RootState): GetMatchResponseDto[] => {
      if (!state.entities.leagueMatches?.byRoundId?.[roundId]) return [];

      let leagueRoundGroups: GetMatchResponseDto[] = Object.values<GetMatchResponseDto>(
        state.entities?.leagueMatches?.byRoundId?.[roundId]
      );

      return leagueRoundGroups;
    }
  );

  return [leagueRoundMatches, queryState, refreshLeagueRoundMatches];
};

export const useCreateLeagueMatch = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [
  QueryState,
  RunMutation<
    EntitiesState,
    [SingleMatchIndividualPayload | SingleMatchTeamPayload]
  >
] => {
  const [
    createLeagueMatchQueryState,
    createLeagueMatch,
  ] = useMutation(
    (body: SingleMatchIndividualPayload | SingleMatchTeamPayload) =>
      makeCreateSingleMatch(facilityId, leagueId, roundId, body)
  );

  return [createLeagueMatchQueryState, createLeagueMatch];
};

export const useCreateLeagueMultipleMatch = (
  facilityId: string,
  leagueId: string,
  roundId: string
): [
  QueryState,
  RunMutation<
    EntitiesState,
    [MultipleMatchIndividualPayload | MultipleMatchTeamPayload]
  >
] => {
  const [
    createMultipleLeagueMatchQueryState,
    createMultipleLeagueMatch,
  ] = useMutation(
    (body: MultipleMatchIndividualPayload | MultipleMatchTeamPayload) =>
      makeCreateMultipleMatches(facilityId, leagueId, roundId, body)
  );

  return [createMultipleLeagueMatchQueryState, createMultipleLeagueMatch];
};

export const useDeleteLeagueRoundMatch = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  matchId: string
): [QueryState, RunMutation<EntitiesState>] => {
  const [queryState, deleteLeagueRound] = useMutation(() =>
    makeDeleteSingleMatch(facilityId, leagueId, roundId, matchId)
  );
  return [queryState, deleteLeagueRound];
};
