import { useState } from "react";
import { globalStyles } from "components/globalStyles";
import { sendTimerToSimulator } from "modules/simulator";
import Timer from "components/control/dashboard/timers/Timer";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { getSimulatorStatesBySimIdsArray } from "modules/simulator-state/simulatorStateSelectors";
import { RequireAtLeastOne } from "Helpers";
import { TimerAction, TimerStatus } from "types/enums";
import {
  showResponseNotification,
  notificationMessageGroup,
} from "utils/notifications/notificationHelpers";
import { FormRow } from "components/ui/form";
import DatePicker from "react-datepicker";
import { DateTime, Interval } from "luxon";
import DisplayTimerStatus from "components/control/dashboard/DisplayTimerStatus";
import { styles } from "./SimTimerControls.styles";
import { updateEntities } from "redux-query";
import { useAppDispatch } from "utils/hooks";
import { addMinutesToTimerArray, isNegative } from "utils/timer/timerHelpers";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";

type OwnProps = {
  simulatorId?: string;
  simulatorIds?: string[];
};

type Props = RequireAtLeastOne<OwnProps, "simulatorId" | "simulatorIds">;

const SimTimerControls: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const [minutes, storeMinutes] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [datePickerTime, setDatePickerTime] = useState(new Date());

  const simStates = useSelector((state: RootState) => {
    if (props.simulatorIds) {
      return getSimulatorStatesBySimIdsArray(state, props.simulatorIds);
    }
    if (props.simulatorId) {
      return getSimulatorStatesBySimIdsArray(state, [props.simulatorId]);
    }
  });

  const paused = simStates?.some(
    (state) => state.timerStatus === TimerStatus.PAUSED
  );

  const handleMinuteChange = (event: any) => {
    storeMinutes(event.target.value < 0 ? 0 : event.target.value);
  };

  const handlePauseTimer = async () => {
    const response = sendTimerToSimulator(
      TimerAction[TimerAction.PAUSE],
      props.simulatorId,
      undefined,
      props.simulatorIds
    );
    await showResponseNotification(
      response,
      notificationMessageGroup.pauseTimer
    );
    await updateReduxTimerValues(TimerStatus.PAUSED);
    pendoTrackBasicEvent(ClubhouseEvents.PAUSE_TIMER, props.simulatorId);
  };

  const handleUnpauseTimer = async () => {
    const response = sendTimerToSimulator(
      TimerAction[TimerAction.UNPAUSE],
      props.simulatorId,
      undefined,
      props.simulatorIds
    );

    await showResponseNotification(
      response,
      notificationMessageGroup.unpauseTimer
    );
    await updateReduxTimerValues(TimerStatus.RUNNING);
    pendoTrackBasicEvent(ClubhouseEvents.UNPAUSE_TIMER, props.simulatorId);
  };

  const handleClearTimer = async () => {
    const response = sendTimerToSimulator(
      TimerAction[TimerAction.CLEAR_DISABLE],
      props.simulatorId,
      undefined,
      props.simulatorIds
    );
    await showResponseNotification(
      response,
      notificationMessageGroup.clearTimer
    );
    await updateReduxTimerValues(TimerStatus.OFF);
    setErrorMessage("");
    pendoTrackBasicEvent(ClubhouseEvents.CLEAR_TIMER, props.simulatorId);
  };

  const handleAddToTimer = async () => {
    const endTime = calculateEndTime();
    if (isNegative(endTime)) {
      setErrorMessage(
        "The timer is currently set to a time after this. Please clear the timer first and then set a new end time."
      );
      return;
    }

    const response = sendTimerToSimulator(
      TimerAction[TimerAction.ADD_TIME],
      props.simulatorId,
      endTime.toString(),
      props.simulatorIds
    );
    await showResponseNotification(response, notificationMessageGroup.addTime);
    await updateReduxTimerValues(TimerStatus.RUNNING, true);

    storeMinutes(0);
    setErrorMessage("");
    pendoTrackBasicEvent(ClubhouseEvents.ADD_TIME, props.simulatorId);
  };

  const updateReduxTimerValues = async (
    status: TimerStatus,
    updateTimer?: boolean
  ) => {
    const simulatorId = props.simulatorId ? props.simulatorId : "";
    const simulators = props.simulatorIds
      ? props.simulatorIds
      : simulatorId.split(" ");

    simulators.map((simId: string) =>
      dispatch(
        updateEntities({
          simulatorStates: (prev) => ({
            ...prev,
            ...{
              [simId]: {
                ...prev[simId],
                ...{
                  timerStatus: status,
                  timerValue: updateTimer
                    ? addMinutesToTimerArray(
                        prev[simId]?.timerValue,
                        calculateEndTime()
                      )
                    : prev[simId]?.timerValue,
                },
              },
            },
          }),
        })
      )
    );
  };

  const calculateEndTime = () => {
    const currentTimerMinutes = simStates ? simStates[0].timerValue[1] : 0;

    return endTime
      ? Math.ceil(
          Interval.fromDateTimes(DateTime.now(), datePickerTime).length(
            "minutes"
          ) - currentTimerMinutes
        )
      : minutes;
  };

  const handleTimePickerChange = (e: Date) => {
    if (e) {
      const i = Interval.fromDateTimes(DateTime.now(), DateTime.fromJSDate(e));
      storeMinutes(Math.ceil(i.length("minutes")));
      setDatePickerTime(e);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        {props.simulatorId ? (
          <FormRow>
            <span className={styles.heading}>
              Round Timer{" "}
              {simStates ? (
                <DisplayTimerStatus
                  timerStatus={simStates[0]?.timerStatus?.toString()}
                  showStatusName={true}
                  badgeStyle={true}
                />
              ) : null}
            </span>
            <small className={styles.small}>
              {simStates ? <Timer simulatorId={simStates[0]?.id} /> : null}
            </small>
          </FormRow>
        ) : null}
      </div>
      <div>
        <ul className={styles.timerToggle} role="tablist">
          <li className="pr-2">
            <a
              className={
                styles.listItem +
                (endTime === 0 ? styles.activeTab : styles.inactiveTab)
              }
              onClick={(e) => {
                e.preventDefault();
                setEndTime(0);
              }}
              data-toggle="tab"
              href="#link1"
              role="tablist"
            >
              Add Time
            </a>
          </li>
          <li className="">
            <a
              className={
                styles.listItem +
                (endTime === 1 ? styles.activeTab : styles.inactiveTab)
              }
              onClick={(e) => {
                e.preventDefault();
                setEndTime(1);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              Set End Time
            </a>
          </li>
        </ul>
        <div className={styles.timerContainer}>
          {!endTime ? (
            <div id="link1" className={styles.timerInnerContainers}>
              <input
                name="minutes"
                type="number"
                placeholder="Enter Minutes"
                pattern="[0-9]"
                min="0"
                className={styles.input}
                onChange={(e) => handleMinuteChange(e)}
                value={minutes}
              />
              <br />
              <small className={styles.small}>
                Enter a number above to add minutes to the timer
              </small>
            </div>
          ) : (
            <div id="link2" className={styles.timerInnerContainers}>
              <DatePicker
                onChange={(date: Date) => handleTimePickerChange(date)}
                selected={datePickerTime}
                showTimeSelect
                showTimeSelectOnly
                minTime={new Date()}
                maxTime={DateTime.now().endOf("day").toJSDate()}
                timeIntervals={5}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className={styles.datePicker}
                id="addTimeToSimDropdown"
              />
              <br />
              <small className={styles.small}>
                Click, then choose an end time from the dropdown box above
              </small>
            </div>
          )}
        </div>
      </div>
      <div className="text-red-600 p-2 w-full text-sm">{errorMessage}</div>
      <div className={styles.buttonOuterRow}>
        <div className={styles.buttonInnerContainerLeft}>
          <button
            disabled={!minutes}
            onClick={() => handleAddToTimer()}
            className={`${globalStyles.primaryButton} ${globalStyles.buttonLarge} timerSetTime`}
          >
            {!endTime ? "Add time" : "Set end time"}
          </button>
        </div>
        <div className={styles.buttonInnerContainerRight}>
          <button
            onClick={() => handlePauseTimer()}
            className={`${paused ? "hidden" : ""} ${
              globalStyles.tertiaryButton
            } ${globalStyles.buttonLarge} timerPause mr-1`}
          >
            <span className="ml-2">
              Pause <span className="sm:hidden md:inline">Timer</span>
            </span>
          </button>
          <button
            onClick={() => handleUnpauseTimer()}
            className={`${paused ? "" : "hidden"} ${
              globalStyles.tertiaryButton
            } ${globalStyles.buttonLarge} timerUnpause`}
          >
            <span className="ml-2">
              Unpause <span className="sm:hidden md:inline">Timer</span>
            </span>
          </button>
          <button
            onClick={() => handleClearTimer()}
            className={`${globalStyles.tertiaryButton} ${globalStyles.buttonLarge} timerClear`}
          >
            <span className="ml-2">
              Clear <span className="sm:hidden md:inline">Timer</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default SimTimerControls;
