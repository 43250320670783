import { FormRow } from "components/ui/form";

const SupportCaseFormContainer = () => {
  return (
    <div>
      <form
        action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
        method="POST"
      >
        <input type="hidden" name="orgid" value="00D5f000003z7PO" />
        <input
          type="hidden"
          name="retURL"
          value="https://clubhouse.aboutgolf.com"
        />
        <input
          type="hidden"
          name="recordType"
          id="recordType"
          value="0125f000000hncq"
        />
        <FormRow header="Contact Name">
          <input
            className="border border-gray-300 rounded dark:text-black w-full md:w-1/2"
            id="name"
            maxLength={80}
            name="name"
            size={20}
            type="text"
          />
        </FormRow>
        <FormRow header="Email">
          <input
            className="border border-gray-300 rounded dark:text-black w-full md:w-1/2"
            id="email"
            maxLength={80}
            name="email"
            size={20}
            type="text"
          />
        </FormRow>
        <FormRow header="Phone Number">
          <input
            className="border border-gray-300 rounded dark:text-black w-full md:w-1/2"
            id="phone"
            maxLength={40}
            name="phone"
            size={20}
            type="text"
          />
        </FormRow>
        <FormRow header="Subject">
          <input
            className="border border-gray-300 rounded dark:text-black w-full md:w-1/2"
            id="subject"
            maxLength={80}
            name="subject"
            size={20}
            type="text"
          />
        </FormRow>
        <FormRow header="Description of Issue">
          <textarea
            className="border border-gray-300 rounded dark:text-black w-full md:w-1/2"
            name="description"
          ></textarea>
        </FormRow>
        <FormRow header="Category">
          <select
            className="border border-gray-300 rounded dark:text-black w-full md:w-1/2"
            id="00N5f00000dGojf"
            name="00N5f00000dGojf"
            title="Case Category"
          >
            <option value="">--None--</option>
            <option value="aG Computer">aG Computer</option>
            <option value="Projector/Display">Projector/Display</option>
            <option value="3Trak">3Trak</option>
            <option value="aG simulator">aG simulator</option>
            <option value="aG Connect">aG Connect</option>
            <option value="aG Locker">aG Locker</option>
            <option value="aG Games">aG Games</option>
            <option value="Automation">Automation</option>
            <option value="aG Tour">aG Tour</option>
            <option value="Training/Questions">Training/Questions</option>
            <option value="aG Flix">aG Flix</option>
            <option value="aG Balance">aG Balance</option>
            <option value="Other">Other</option>
            <option value="Orders">Orders</option>
            <option value="License Issues">License Issues</option>
            <option value="Courses">Courses</option>
            <option value="Update Center">Update Center</option>
            <option value="Sound issues">Sound issues</option>
            <option value="Network issues">Network issues</option>
            <option value="Sales Request">Sales Request</option>
            <option value="Design Request">Design Request</option>
            <option value="RMA/Warranty">RMA/Warranty</option>
            <option value="General">General</option>
            <option value="Automation/Media">Automation/Media</option>
            <option value="Internal IT Request">Internal IT Request</option>
          </select>
        </FormRow>
        <br />
        <input type="hidden" id="external" name="external" value="1" />
        <br />
        <input
          type="submit"
          name="submit"
          className="w-full bg-green-600 hover:bg-green-600 text-white text-sm tracking-wide py-2 px-4 border border-green-600 rounded shadow-md hover:animate-pulse focus:ring-2 focus:ring-blue-600 active:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
        />
      </form>
    </div>
  );
};

export default SupportCaseFormContainer;
