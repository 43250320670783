import { motion } from "framer-motion";
import { getCurrentFacilityId } from "modules/facility/facilitySelectors";
import { useServiceRequestsByFacilityId } from "modules/service-requests/serviceRequestHooks";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { serviceRequestAlerts } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { styles } from "../../ui/layout/Header.styles";
import ServiceRequestIcon from "components/svg/ServiceRequestIcon";

const ServiceRequestAlert: React.FC<Props> = () => {
  const currentFacilityId = useSelector((state: RootState) =>
    getCurrentFacilityId(state)
  );

  const [currentFacilityServiceReqests] = useServiceRequestsByFacilityId(
    currentFacilityId
  );

  const dispatch = useAppDispatch();

  const setAlertsTray = (value: boolean) => {
    dispatch(serviceRequestAlerts({ isOpen: value }));
  };

  const currentRequests = currentFacilityServiceReqests.length ? true : false;

  return (
    <div className="text-center items-center">
      <motion.button
        onClick={() => setAlertsTray(true)}
        variants={{
          pulse: {
            scale: [1, 1.05, 1, 1.05, 1, 1, 1, 1, 1],
          },
        }}
        animate={currentRequests ? "pulse" : ""}
        transition={{
          repeat: Infinity,
          repeatType: "loop",
          delay: 2,
          duration: 2,
        }}
        type="button"
        id="headerServiceRequestAlertButton"
        data-tip="Service Requests"
        data-for="headerButtonsTooltip"
      >
        <ServiceRequestIcon
          class={`${styles.buttonIcon} ${
            currentRequests
              ? "text-red-500 dark:text-red-500"
              : "brand-dark-gray"
          }`}
        />
      </motion.button>
      <span className={styles.buttonText}>Alerts</span>
    </div>
  );
};

export default ServiceRequestAlert;

type Props = {
  title?: string;
};
