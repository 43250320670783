import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import styles from "./DarkMode.less";
import { LocalStorageItems } from "utils/hooks/localStorage";
import { MdOutlineWbSunny } from "react-icons/md";
import MoonIcon from "components/svg/MoonIcon";

const DarkModeButton: React.FC = () => {
  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [isDarkMode, setDarkMode] = useState(
    localStorage.getItem(LocalStorageItems.DARKMODE)
      ? localStorage.getItem(LocalStorageItems.DARKMODE) === "true"
      : prefersDark
  );

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
  };

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem(
      LocalStorageItems.DARKMODE,
      JSON.stringify(isDarkMode)
    );
  }, [isDarkMode]);

  return (
    <div className="text-center">
      <button
        data-darkmode={isDarkMode}
        onClick={toggleDarkMode}
        data-testid="darkModeButton"
        className="-m-2.5 p-2.5"
        id="headerDarkModeButton"
        data-tip={isDarkMode ? "Light Mode" : "Dark Mode"}
        data-for="headerButtonsTooltip"
      >
        <motion.div layout className={styles.handle}>
          <AnimatePresence exitBeforeEnter initial={false}>
            <motion.div
              key={isDarkMode ? "moon" : "sun"}
              initial={{ y: -30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 30, opacity: 0 }}
              transition={{ duration: 0.2 }}
              data-testid="darkModeIcon"
            >
              {isDarkMode ? (
                <MdOutlineWbSunny className="h-5 w-5 brand-gray dark:text-gray-200" />
              ) : (
                <MoonIcon class="h-5 w-5 brand-gray dark:text-gray-200" />
              )}
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </button>
      <span className="text-xs hidden text-center md:hidden dark:text-gray-200">
        {isDarkMode ? "Light" : "Dark"}
      </span>
    </div>
  );
};

export default DarkModeButton;
