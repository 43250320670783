import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import ServiceRequestAlert from "components/control/dashboard/ServiceRequestAlert";
import { navOverlayAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import FacilitiesDropDown from "./FacilitiesDropDown";
import DarkModeButton from "./DarkModeButton";
import { HiOutlineMenu } from "react-icons/hi";
import RoundSettingsTrayButton from "components/shared/settings/RoundSettingsTrayButton";
import { styles } from "./Header.styles";
import ScorecardLookupButton from "components/scorecard/ScorecardLookupButton";
import ReactTooltip from "react-tooltip";
import SupportIcon from "components/svg/SupportIcon";
import LogoutButton from "./LogoutButton";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();

  const isMobileMenuOpen = useSelector(
    (state: RootState) => state.nav?.navOverlay?.isOpen
  );

  const setMenuOverlay = () =>
    dispatch(navOverlayAction({ isOpen: !isMobileMenuOpen }));

  return (
    <div className={styles.header}>
      <button
        type="button"
        className="lg:hidden"
        onClick={() => setMenuOverlay()}
      >
        <span className="sr-only">Open sidebar</span>
        <HiOutlineMenu className={styles.buttonIcon} aria-hidden="true" />
      </button>

      <div
        className="h-6 w-px bg-gray-900/10 lg:hidden"
        aria-hidden="true"
      ></div>

      <div className={styles.iconMenuContainer}>
        <div className={styles.iconMenuDropdown}>
          <FacilitiesDropDown />
        </div>
        <div className={styles.iconMenu}>
          <ScorecardLookupButton />
          <ServiceRequestAlert />
          <SupportIcon />
          <RoundSettingsTrayButton />
          <DarkModeButton />
          <LogoutButton />
          <div
            className="hidden lg:h-6 lg:w-px lg:bg-white/10"
            aria-hidden="true"
          />
          <ReactTooltip
            id="headerButtonsTooltip"
            place="bottom"
            delayShow={200}
            backgroundColor="#f9fafb"
            textColor="#1f231e"
            border={true}
            borderColor="#1f231e"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
