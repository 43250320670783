import Modal from "components/shared/Modal";
import styles from "./MultiSimControlModal.less";
import MultiSimControls from "components/control/sim-control/MultiSimControls";

const BulkSimControlModal: React.FC<SimControlModalProps> = (props) => {
  return (
    <Modal
      modalName={props.modalName}
      hideHeader={false}
      body={
        <div className={styles.modalContainer}>
          <MultiSimControls />
        </div>
      }
      title="Manage Sims"
      hideFooter={true}
      modalSize="w-full h-full sm:w-1/2 sm:h-3/4"
    />
  );
};

type SimControlModalProps = {
  title?: string;
  modalName: string;
  simulatorIds: string[];
};

BulkSimControlModal.defaultProps = {
  title: "Bulk Sim Control",
};

export default BulkSimControlModal;
