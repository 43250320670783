import { Gender } from "types/enums";
import { FaGolfBall } from "react-icons/fa";
import { useRoundById } from "modules/round/roundHooks";
import { teeTypeIconColorHelper } from "utils/icons/iconHelpers";
import ReactTooltip from "react-tooltip";
import { useContext } from "react";
import { SimCardContext } from "components/control/dashboard/SingleSimCardView";

const PlayerListIcons: React.FC<PlayerListIconsType> = ({
  roundId,
  iconSize,
  showNames,
}) => {
  const [currentRound] = useRoundById(roundId);
  const { simulatorId } = useContext(SimCardContext);

  return (
    <div className="flex items-center z-10 ml-2">
      {currentRound?.players?.map((player) => {
        return (
          <div
            key={`${currentRound.sessionId}-player-icon-${player.username}-${simulatorId}`}
          >
            <div
              data-tip
              data-for={`${currentRound.sessionId}-tooltip-${player.username}-${simulatorId}`}
              className={`${iconSize} rounded-full shadow bg-gray-50  dark:bg-zinc-800 dark:border dark:border-gray-200 flex items-center justify-center text-sm text-gray-400 dark:text-gray-200 font-bold hover:scale-125 border border-gray-200 -ml-2`}
            >
              {player.displayName?.charAt(0) || player.username.charAt(0)}
            </div>
            <ReactTooltip
              backgroundColor="#f5f5f5"
              textColor="#000"
              border={true}
              borderColor="#777777"
              id={`${currentRound.sessionId}-tooltip-${player.username}-${simulatorId}`}
            >
              <div>
                <FaGolfBall
                  className={`fa fas fa-golf-ball mr-2 ${
                    teeTypeIconColorHelper[player.teeType?.toString()]
                  }`}
                />
                {player.displayName || player.username} (
                {Gender[player.gender]?.[0]}) | Hole: {player.currentHole}
                <br />
                <small>
                  Score: {player.score.reduce((acc, val) => acc + val)} -
                  Handicap: {player.roundHandicap}
                </small>
              </div>
            </ReactTooltip>
          </div>
        );
      })}

      <span className={`${showNames} ml-2`}></span>
    </div>
  );
};
export default PlayerListIcons;

type PlayerListIconsType = {
  iconSize?: string;
  showNames?: string;
  roundId: string;
};

PlayerListIcons.defaultProps = {
  iconSize: "w-6 h-6",
  showNames: "block",
};
