import { useState } from "react";
import { styles } from "./Leagues.styes";
import { HandicapMode } from "types/enums";
import { PlayerScorecardDto } from "ScorecardModels";
import { RoundLeaderboardPlayerDto, RoundPar } from "Models";
import { Controller, useFormContext } from "react-hook-form";
import { MdExpandMore, MdOutlineClose } from "react-icons/md";
import { useGetTeamScorecardByRoundTeamId } from "modules/leagues/leagueScorecardHooks";
import ScorecardHolesLayout from "components/scorecard/ScorecardHolesLayout";

type LeaguePlayerScorecardProps = {
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
  leaderboardPlayer: RoundLeaderboardPlayerDto;
  finalizeScorecards?: boolean;
  index?: number;
  showNetScore?: boolean;
  roundPar: RoundPar;
};

const LeaguePlayerScorecard: React.FC<LeaguePlayerScorecardProps> = (props) => {
  const { leaderboardPlayer, finalizeScorecards, index, showNetScore } = props;
  const { control } = useFormContext();

  const [showScorecard, setShowScorecard] = useState(false);

  const teamScorecard = useGetTeamScorecardByRoundTeamId(
    leaderboardPlayer.roundTeamId
  );

  const playerScorecard: PlayerScorecardDto = {
    playerDisplayName: leaderboardPlayer.playerDisplayName,
    playerName: leaderboardPlayer.playerName,
    playerGuid: leaderboardPlayer.playerGuid,
    aGHandicap: leaderboardPlayer.agHandicap,
    userId: "",
    teeUsed: 0,
    partOfLeague: leaderboardPlayer.partOfLeague,
    partOfRound: leaderboardPlayer.partOfRound,
    handicapUsed: showNetScore ? HandicapMode.ABSOLUTE : HandicapMode.OFF,
    gender: leaderboardPlayer.gender,
    holes: leaderboardPlayer.holes.map((hole) => ({
      holeNumber: hole.holeNumber,
      par: hole.par,
      selected: hole.selected,
      handicapIndex: hole.handicapIndex,
      gross: hole.gross,
      net: hole.net,
      conceded: hole.conceded,
      operatorAdjustedScore: hole.operatorAdjustedScore,
    })),
    gross: leaderboardPlayer.gross || {
      total: 0,
      in: 0,
      out: 0,
      strokesOverPar: 0,
      strokesOverParDetail: "",
    },
    net: leaderboardPlayer.net || {
      total: 0,
      in: 0,
      out: 0,
      strokesOverPar: 0,
      strokesOverParDetail: "",
    },
  };

  return (
    <div onClick={(e) => e.stopPropagation()} className="w-full">
      <div
        key={leaderboardPlayer.playerGuid}
        className={`${styles.modalResultsRow} ${
          showScorecard ? "bg-gray-50 rounded-tl-lg rounded-tr-lg" : ""
        }`}
      >
        <div className="col-span-1">{leaderboardPlayer.previousRank}</div>
        <div className="col-span-3">{leaderboardPlayer.playerName}</div>
        <div className="hidden md:inline-block col-span-2">
          {leaderboardPlayer.agHandicap}
        </div>
        <div className="hidden md:inline-block col-span-2">
          {showNetScore
            ? leaderboardPlayer.net.total
            : leaderboardPlayer.gross.total}
        </div>
        {finalizeScorecards ? (
          <div className="col-span-2 text-left">
            <Controller
              name={`players.[${index}].points`}
              control={control}
              rules={{
                required: "Please enter a point value",
              }}
              defaultValue={leaderboardPlayer.previousPoints}
              render={({ field }) => (
                <input
                  {...field}
                  type="number"
                  min={0}
                  max={100}
                  className="border-1 border-gray-200 rounded-lg w-20 text-xs font-normal p-1"
                />
              )}
            />
            <Controller
              name={`players.[${index}].playerRoundLeaderBoardId`}
              control={control}
              defaultValue={leaderboardPlayer.roundPlayerId.toString()}
              render={({ field }) => (
                <input
                  {...field}
                  type="hidden"
                  className="border-1 border-gray-200 rounded-lg w-20 text-xs font-normal p-1"
                />
              )}
            />
          </div>
        ) : (
          <div className="col-span-2 pl-1">
            <div className="inline pt-[2px] pb-[2px]">
              {leaderboardPlayer.previousPoints}
            </div>
          </div>
        )}
        <div className="col-span-1">
          {showNetScore
            ? leaderboardPlayer.net.strokesOverParDetail
            : leaderboardPlayer.gross.strokesOverParDetail}
        </div>
        <div className="col-span-1 items-center text-right pr-2">
          <div
            onClick={() => setShowScorecard(!showScorecard)}
            className={styles.showScorecardButton}
          >
            {showScorecard ? (
              <>
                <MdOutlineClose
                  className={showScorecard ? "font-bold size-4" : "hidden"}
                />
              </>
            ) : (
              <>
                <MdExpandMore className="size-4" />
              </>
            )}
          </div>
        </div>
      </div>
      {showScorecard && (
        <div className="text-xs bg-gray-50 rounded-bl-lg rounded-br-lg p-4">
          <ScorecardHolesLayout
            playerScorecard={Array(playerScorecard)}
            allowEditing={true}
            scorecardId={leaderboardPlayer.scoreCardId}
            teamScorecard={teamScorecard}
            showNetScore={showNetScore}
          />
        </div>
      )}
    </div>
  );
};

export default LeaguePlayerScorecard;
