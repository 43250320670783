export const styles = {
  header:
    "sticky top-0 z-40 flex h-22 shrink-0 items-center gap-x-4 bg-white  dark:bg-zinc-800  px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8",
  headerLeftColumn: [
    "flex",
    "flex-col",
    "lg:flex-row",
    "mx-0",
    "mb-2",
    "my-4",
    "lg:my-0",
    "lg:mx-auto",
    "lg:block",
    "px-8",
    "lg:px-0",
    "border-gray-300",
    "border-b",
    "lg:border-0",
    "hidden",
  ].join(" "),
  headerCenterColumn: ["lg:flex", "lg:flex-grow", "text-left"].join(" "),
  headerRightColumn: [
    "grid grid-cols-6 gap-3 items-center border-l-0 border-t md:border-l md:border-t-0 border-gray-200 px-4 py-2 lg:py-0 justify-center",
  ].join(" "),
  lockerLogin: ["pb-4", "lg:pb-0 hidden"].join(" "),
  menuLink: [
    "poppins filter-none brand-dark-gray dark:text-gray-50 hover:text-white hover:bg-brandGreen hover:filter-none group flex items-center gap-x-4 rounded-lg p-3 text-sm",
  ],
  activeMenuLink: [
    "active bg-brandYellow dark:bg-brandYellow/90 text-gray-50 dark:text-zinc-800",
  ],
  modalBackground: [
    "inset-0",
    "cursor-pointer",
    "fixed",
    "bg-gray-800",
    "bg-opacity-50",
    "z-1",
    "lg:hidden",
  ].join(" "),
  simCardContainer: [
    "shadow",
    "w-full",
    "text-gray-900",
    " dark:bg-zinc-800 ",
    "dark:border",
    "dark:border-white",
    "dark:text-gray-200",
    "rounded-t-lg",
    "sm:rounded-lg",
  ].join(" "),
  checked: ["border-4 border-green-500"].join(" "),
  unchecked: ["border-4 border-white-400"].join(" "),
  menuIcon: "flex flex-none shrink-0 w-6 h-6",
  menuTitle: "flex flex-auto",
  menuList: "-mx-2 space-y-2",
  submenuList: "-mx-2 mt-2 space-y-1",
  submenuLink:
    "brand-light-green hover:text-white hover:bg-brandGreen group flex gap-x-3 rounded-md p-2 text-sm leading-5 font-medium poppins w-full",
  submenuIcon:
    "flex poppins flex-none h-6 w-6 shrink-0 items-center justify-center rounded-md bg-darkCream text-xs font-medium text-brandGreen",
  buttonIcon:
    "dark:text-gray-200 text-xl md:text-base m-auto w-5 h-5 drop-shadow-lg",
  buttonText: "text-xs hidden text-center md:hidden dark:text-gray-200",
  iconMenuContainer: "flex flex-1 gap-x-4 self-stretch lg:gap-x-6",
  iconMenuDropdown: "flex flex-1 flex-row-reverse text-xs",
  iconMenu: "flex items-center gap-x-6",
};
export default styles;
