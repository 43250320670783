import { useState } from "react";
import { DateTime } from "luxon";
import { styles } from "../Leagues.styes";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { datetimeStringWithOffset } from "utils/ui/uiHelpers";
import { useSelectedLeague } from "modules/leagues/leagueHooks";
import {
  useLeagueRoundEndDatePassed,
  useLeagueRoundStartDatePassed,
} from "modules/leagues/leagueHelpers";
import DatePicker from "react-datepicker";

const LeagueRoundDateSelect: React.FC<LeaguesDateSelectProps> = (props) => {
  const { formState, getValues, setValue, clearErrors } = useFormContext();
  const { leagueRoundStart, leagueRoundNumber } = props;

  const selectedLeague = useSelectedLeague();

  const [datePickerTime, setTime] = useState({
    startTime:
      DateTime.fromISO(getValues("startPlayDate")).toJSDate() || new Date(),
    endTime:
      DateTime.fromISO(getValues("endPlayDate")).toJSDate() || new Date(),
  });
  const { startTime, endTime } = datePickerTime;

  const startDatePassed = useLeagueRoundStartDatePassed();
  const endDatePassed = useLeagueRoundEndDatePassed();

  const handleStartTime = (value: Date) => {
    setValue("startPlayDate", datetimeStringWithOffset(value));
    setTime({
      startTime: value,
      endTime: DateTime.fromISO(value.toISOString())
        .plus({
          week: 1,
        })
        .toJSDate(),
    });
    if (value > endTime) {
      setValue(
        "endPlayDate",
        datetimeStringWithOffset(
          DateTime.fromISO(value.toISOString())
            .plus({
              week: 1,
            })
            .toJSDate()
        )
      );
    }
    clearErrors();
  };

  const handleEndTime = (value: Date) => {
    setValue("endPlayDate", datetimeStringWithOffset(value));
    setTime({ startTime, endTime: value });
    clearErrors();
  };

  const filterStartTime = (date: { getTime: () => number }) => {
    const isPastTime =
      new Date(
        DateTime.fromISO(selectedLeague.leagueStartDate).toISO()
      ).getTime() > date.getTime();
    return !isPastTime;
  };

  const filterEndTime = (date: { getTime: () => number }) => {
    const isPastTime =
      new Date(
        DateTime.fromISO(selectedLeague.leagueEndDate).toISO()
      ).getTime() > date.getTime();
    return isPastTime;
  };

  return (
    <div className={styles.dateContainer}>
      <div className="grid col-span-2 w-full text-xs pt-4">
        <ErrorMessage
          errors={formState.errors}
          name="endPlayDate"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="startPlayDate"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </div>
      <div>
        <div className={styles.dateSpan}>Start Date</div>
        <DatePicker
          onChange={(date: Date) => handleStartTime(date)}
          onBlur={(e) => handleStartTime(new Date(e.target.value) as Date)}
          selected={startTime}
          showTimeSelect
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="MMMM d, yyyy h:mm aa"
          className={
            formState.errors.startPlayDate
              ? styles.datePickerError
              : styles.datePicker
          }
          filterTime={filterStartTime}
          minDate={new Date(selectedLeague?.leagueStartDate)}
          maxDate={new Date(selectedLeague?.leagueEndDate)}
          placeholderText="Select Start Date"
          id="startPlayDate"
          disabled={startDatePassed}
        />

        <br />
        {leagueRoundStart ? (
          <span className="text-xs text-gray-400">
            Round {leagueRoundNumber} ends on{" "}
            {DateTime.fromISO(leagueRoundStart).toLocaleString(
              DateTime.DATETIME_MED
            )}
          </span>
        ) : (
          <span className="text-xs text-gray-400">
            League starts on{" "}
            {DateTime.fromISO(selectedLeague?.leagueStartDate).toLocaleString(
              DateTime.DATE_MED
            )}
          </span>
        )}
      </div>
      <div>
        <div className={styles.dateSpan}>End Date</div>
        <DatePicker
          onChange={(date: Date) => handleEndTime(date)}
          onBlur={(e) => handleEndTime(new Date(e.target.value) as Date)}
          selected={endTime}
          showTimeSelect
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="MMMM d, yyyy h:mm aa"
          className={
            formState.errors.endPlayDate
              ? styles.datePickerError
              : styles.datePicker
          }
          minDate={new Date(selectedLeague?.leagueStartDate)}
          maxDate={new Date(selectedLeague?.leagueEndDate)}
          filterTime={filterEndTime}
          placeholderText="Select End Date"
          id="endPlayDate"
          disabled={endDatePassed}
        />
        <br />
        <span className="text-xs text-gray-400">
          League ends on{" "}
          {DateTime.fromISO(selectedLeague?.leagueEndDate).toLocaleString(
            DateTime.DATE_MED
          )}
        </span>
      </div>
    </div>
  );
};
export default LeagueRoundDateSelect;

type LeaguesDateSelectProps = {
  leagueRoundStart?: string;
  leagueRoundNumber?: number;
};
