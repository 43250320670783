import { RootState } from "StoreTypes";
import { LeagueType } from "types/enums";
import { useSelector } from "react-redux";
import { styles } from "../../Leagues.styes";
import { useAppDispatch } from "utils/hooks";
import { useEffect, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { enableBackgroundScroll } from "utils/ui/uiHelpers";
import { useSelectedLeague } from "modules/leagues/leagueHooks";
import { setSelectedLeagueId } from "modules/leagues/leagueSlice";
import { createLeagueRoundGroupsTrayAction } from "ui-modules/nav/navSlice";
import CreateLeagueRosterForm from "./CreateLeagueRosterForm";
import CreateTeamLeagueRoster from "./CreateTeamLeagueRoster";

const CreateLeagueRoundTeamTray: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedLeague = useSelectedLeague();

  const isCreateLeagueRoundGroupTrayOpen = useSelector(
    (state: RootState) => state.nav?.createLeagueRoundGroupsTray?.isOpen
  );

  const isTeamLeague = selectedLeague?.settings.leagueType === LeagueType.TEAM;

  const handleCreateLeagueRoundGroupsTrayClosed = useCallback(() => {
    dispatch(setSelectedLeagueId({ selectedLeagueId: "" }));
    dispatch(createLeagueRoundGroupsTrayAction({ isOpen: false }));
    enableBackgroundScroll();
  }, [dispatch]);

  useEffect(() => {
    if (isCreateLeagueRoundGroupTrayOpen === false) {
      handleCreateLeagueRoundGroupsTrayClosed();
    }
  }, [
    isCreateLeagueRoundGroupTrayOpen,
    handleCreateLeagueRoundGroupsTrayClosed,
  ]);

  return (
    <AnimatePresence>
      {isCreateLeagueRoundGroupTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: "0px",
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0.25, duration: 0.75 }}
            className={`${styles.rosterAndTeamContainer} z-9999 overflow-x-hidden`}
            key="createLeagueRosterTray"
            data-testid="createLeagueRosterTray"
            id="createLeagueRosterTray"
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => handleCreateLeagueRoundGroupsTrayClosed()}
                  className={styles.button}
                >
                  Close
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>{`${
                  selectedLeague
                    ? selectedLeague.name + " Roster"
                    : "League Roster"
                }`}</h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={`dark:bg-zinc-800 ${styles.innerContainer} `}>
              {isTeamLeague ? (
                <CreateTeamLeagueRoster selectedLeague={selectedLeague} />
              ) : (
                <CreateLeagueRosterForm selectedLeague={selectedLeague} />
              )}
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => handleCreateLeagueRoundGroupsTrayClosed()}
            className={styles.overlay}
          />
        </>
      )}
    </AnimatePresence>
  );
};
export default CreateLeagueRoundTeamTray;
