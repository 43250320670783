import { FormRow } from "components/ui/form";
import { PlayerUser } from "Models";
import { SetStateAction, useState } from "react";
import { useFormContext } from "react-hook-form";
import Select from "react-select";
import { Gender, TeeType } from "types/enums";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { customStyles } from "utils/ui/uiHelpers";

type OwnProps = {
  setExpandQuickPlayerMenu: React.Dispatch<SetStateAction<boolean>>;
};

type Props = OwnProps;

const QuickPlayerMenu: React.FC<Props> = (props: Props) => {
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState(Gender.MALE);
  const [handicap, setHandicap] = useState(0.0);
  const [teeType, setTeeType] = useState(TeeType.BLUE);
  const [usernameError, setUsernameError] = useState("");
  const [handicapError, setHandicapError] = useState("");
  const { watch, setValue } = useFormContext();

  const addQuickPlayer = async () => {
    const existingPlayers = watch("roundInfo.players", []);
    if (
      !username ||
      existingPlayers?.find(
        (player: PlayerUser) => player.username === username
      )
    ) {
      setUsernameError("The username must exist and be unique");
    } else if (handicap > parseFloat(handicapMax)) {
      setHandicapError(
        "The handicap must be no greater than the max for the selected gender"
      );
    } else {
      const quickPlayerObject: PlayerUser = {
        id: "0",
        username,
        gender,
        handicap,
        displayName: "",
        email: "",
        teeType,
        agHandicap: 0,
      };
      await setValue(
        "roundInfo.players",
        existingPlayers
          ? existingPlayers.concat(quickPlayerObject)
          : [quickPlayerObject]
      );
      props.setExpandQuickPlayerMenu(false);
    }
  };

  const handicapMax = gender === Gender.FEMALE ? "36.4" : "40.4";

  return (
    <>
      <FormRow header="Username" fontSize="text-black">
        <input
          onChange={(event) => setUsername(event.target.value)}
          value={username}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:text-black"
        />
      </FormRow>
      <FormRow header="Email Addresss" fontSize="text-black">
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:text-black" />
      </FormRow>
      <FormRow header="Gender" fontSize="text-black">
        <Select
          isSearchable={false}
          closeMenuOnSelect={true}
          isMulti={false}
          options={roundConfigEnumsAsReactSelectOptions.gender}
          onChange={(val: { label: string; value: number }) =>
            setGender(val.value)
          }
          value={roundConfigEnumsAsReactSelectOptions.gender.find(
            (c) => c.value === gender
          )}
          styles={customStyles}
        />
      </FormRow>
      <FormRow header="TeeType" fontSize="text-black">
        <Select
          isSearchable={false}
          closeMenuOnSelect={true}
          isMulti={false}
          options={roundConfigEnumsAsReactSelectOptions.teeType}
          onChange={(val: { label: string; value: number }) =>
            setTeeType(val.value)
          }
          value={roundConfigEnumsAsReactSelectOptions.teeType.find(
            (c) => c.value === teeType
          )}
          styles={customStyles}
        />
      </FormRow>
      <FormRow header="Handicap" fontSize="text-black">
        <input
          id="handicap"
          name="handicap"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="number"
          step="0.1"
          min="0.0"
          max={handicapMax}
          value={handicap}
          onChange={(event) => setHandicap(parseFloat(event.target.value))}
        />
      </FormRow>
      <FormRow>
        <div className="flex justify-start mb-2">
          <div
            onClick={addQuickPlayer}
            className="bg-brandGreen dark:bg-brandGreen hover:bg-brandYellow text-white dark:text-black text-sm font-bold rounded shadow px-6 py-2 inline items-center hover:cursor-pointer"
          >
            Add Quick Player
          </div>
        </div>
        <div className="text-sm text-red-500">{usernameError}</div>
        <div className="text-sm text-red-500">{handicapError}</div>
      </FormRow>
    </>
  );
};

export default QuickPlayerMenu;
