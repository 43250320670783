import { Round, StartRoundPayload } from "Models";
import { normalize } from "normalizr";
import { AppQueryConfig, MoveRoundPayload } from "QueryTypes";
import { round } from "./roundSchema";
import { RoundsEntitiesState } from "StoreTypes";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";
const urls = {
  roundById(roundId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/rounds/${roundId}`;
  },
  startRound(facilityId: string, simulatorId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/simulators/${simulatorId}/startround`;
  },
  moveRound(roundId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/rounds/${roundId}/move/`;
  },
};

export const getRoundByIdQuery = (roundId: string): AppQueryConfig => ({
  url: urls.roundById(roundId),
  queryKey: `getRoundById:${roundId}`,
  options: { ...reduxQueryDefaultOptions() },
  transform: (responseJson: Round) => {
    return normalize(responseJson, round).entities;
  },
  update: {
    rounds: (oldValue, newValue): RoundsEntitiesState => ({
      ...oldValue,
      ...newValue,
    }),
  },
});

export const makeStartRoundMutation = (
  facilityId: string,
  simulatorId: string,
  body: StartRoundPayload
): AppQueryConfig => ({
  url: urls.startRound(facilityId, simulatorId),
  queryKey: `startRound:${simulatorId}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
});

export const makeMoveRoundMutation = (
  roundId: string,
  body: MoveRoundPayload
): AppQueryConfig => ({
  url: urls.moveRound(roundId),
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
});
