import { SetStateAction } from "react";
import { styles } from "./Leagues.styes";
import { LeagueType } from "types/enums";
import ReactTooltip from "react-tooltip";

const LeagueFilter: React.FC<LeagueFilterProps> = (props) => {
  const { callback, leagueTypeFilter } = props;

  function filterLeagueType(leagueType: number) {
    if (leagueTypeFilter) {
      if (leagueTypeFilter?.includes(leagueType)) {
        callback(leagueTypeFilter.filter((type) => type !== leagueType));
      } else {
        callback([...leagueTypeFilter, leagueType]);
      }
    }
  }

  return (
    <>
      <button
        type="button"
        onClick={() => filterLeagueType(LeagueType.TEAM)}
        className={`${styles.filterButton} ${
          leagueTypeFilter?.includes(LeagueType.TEAM)
            ? "league-team"
            : "line-through bg-gray-50"
        }`}
        data-tip={
          leagueTypeFilter?.includes(LeagueType.TEAM)
            ? "Hide team leagues"
            : "Show team leagues"
        }
        data-for={`leagueFilter`}
      >
        <small>Team</small>
      </button>
      <button
        type="button"
        onClick={() => filterLeagueType(LeagueType.INDIVIDUAL)}
        className={`${styles.filterButton} ${
          leagueTypeFilter?.includes(LeagueType.INDIVIDUAL)
            ? "league-individual"
            : "line-through bg-gray-50"
        }`}
        data-tip={
          leagueTypeFilter?.includes(LeagueType.INDIVIDUAL)
            ? "Hide individual leagues"
            : "Show individual leagues"
        }
        data-for={`leagueFilter`}
      >
        <small>Individual</small>
      </button>
      <ReactTooltip
        place="bottom"
        delayShow={200}
        backgroundColor="#f9fafb"
        textColor="#4b5563"
        border={true}
        borderColor="#d1d5db"
        id={`leagueFilter`}
        className="!text-xs"
      />
    </>
  );
};
export default LeagueFilter;

type LeagueFilterProps = {
  leagueTypeFilter?: number[];
  callback: React.Dispatch<SetStateAction<number[]>>;
};
