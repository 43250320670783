import { ErrorMessage } from "@hookform/error-message";
import { FormRow } from "components/ui/form";
import Row from "components/ui/layout/Row";
import { motion } from "framer-motion";
import { PlayerUser, Player } from "Models";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { RoundType } from "types/enums";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { customStyles } from "utils/ui/uiHelpers";
import FormattedUserSearchOption from "./FormattedUserSearchOption";
import QuickPlayerMenu from "./QuickPlayerMenu";
import RangeSpecificForm from "./RangeSpecificForm";
import RoundSpecificForm from "./RoundSpecificForm";
import TimerSelection from "./TimerSelection";
import tailwind from "./StartAdHocRoundContainer.styles";
import AsyncSelect from "react-select/async";
import { useEffect, useState } from "react";
import { debouncedLoadOptions } from "utils/userSearch/userSearchHelpers";
import { useFacilityDefaultRoundSettings } from "modules/default-round-settings";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { FaPlus, FaMinus } from "react-icons/fa";
import { SendRoundRangeToSimInfo } from "Messages";

const StartGameForm: React.FC<StartGameFormProps> = (props) => {
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const { control, formState, watch, setValue } = useFormContext();
  const { simulatorReservation, simulatorId, queueItem, callback } = props;

  const [expandQuickPlayerMenu, setExpandQuickPlayerMenu] = useState(false);
  const [defaultRoundSettings] = useFacilityDefaultRoundSettings(
    currentFacilityId
  );

  const watchGameType = watch("roundType");

  const handlePlayerChange = (options: any) => {
    callback && callback(options);
    setValue("roundInfo.players", options);
  };

  useEffect(() => {
    if (simulatorReservation) {
      setValue("roundType", RoundType.ROUND);
    }
  }, [simulatorReservation, setValue]);

  return (
    <div className="mb-4">
      <Row cssClasses="mt-6">
        <span className={`${tailwind.span}`}>Add Players </span>
      </Row>
      <Row cssClasses="mt-2">
        <Controller
          name="roundInfo.players"
          rules={{ required: "At least one player is required" }}
          control={control}
          defaultValue={props.players || []}
          render={({ field }) => (
            <AsyncSelect
              {...field}
              isMulti
              backspaceRemovesValue={false}
              noOptionsMessage={() =>
                "No players found. Try another search or click 'Add Quick Player' to create a new player."
              }
              loadOptions={(query) => debouncedLoadOptions(query)}
              getOptionValue={(player: PlayerUser) => JSON.stringify(player)}
              cacheOptions
              formatOptionLabel={(option, { context }) => {
                return (
                  <FormattedUserSearchOption value={option} context={context} />
                );
              }}
              data-testid="roundInfo.players"
              styles={customStyles}
              placeholder={"Start typing to search..."}
              id="react-search"
              inputId="startGameAddPlayers"
              onChange={(options) => handlePlayerChange(options)}
            />
          )}
        />
        <small
          className={`pt-2 text-gray-400 dark:text-gray-200 text-xs inline-block`}
        >
          Search for players by name, email or Locker username. To create and
          add a new player click 'Add Quick Player'.
        </small>
      </Row>
      <Row cssClasses="mt-2">
        <ErrorMessage
          errors={formState.errors}
          name="roundInfo.players"
          render={({ message }) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </Row>
      <Row cssClasses="flex-row py-4">
        {expandQuickPlayerMenu ? (
          <span
            data-testid="quickPlayerLink"
            onClick={() => setExpandQuickPlayerMenu(false)}
            className="hover:cursor-pointer bg-gray-100 text-black rounded-tl-lg rounded-tr-lg px-4 py-2 poppins"
          >
            <FaMinus className="text-black px-1" />{" "}
            <span className={`${tailwind.quickPlayer} text-black pr-2`}>
              Add Quick Player
            </span>
          </span>
        ) : (
          <span
            onClick={() => setExpandQuickPlayerMenu(true)}
            className="hover:cursor-pointer dark:bg-brandGreen rounded-full border-2 border-gray-200 px-4 py-2 hover:text-black hover:bg-gray-200 poppins"
          >
            <FaPlus className="font-bold" />
            <span className={`${tailwind.quickPlayer} text-black`}>
              Add Quick Player
            </span>
          </span>
        )}
        {expandQuickPlayerMenu ? (
          <motion.div className="px-4 rounded-tr-lg rounded-br-lg rounded-bl-lg border-white pb-2 bg-gray-100 shadow">
            <QuickPlayerMenu
              setExpandQuickPlayerMenu={setExpandQuickPlayerMenu}
            />
          </motion.div>
        ) : null}
      </Row>
      <Row cssClasses="border-b border-gray-200 px-2 mt-2"></Row>
      <FormRow>
        <span className={tailwind.span}>Select Round or Range</span>
        <small className={tailwind.small}>
          Will this round be for a round or range
        </small>
      </FormRow>
      <Row cssClasses="pt-4">
        <Controller
          name="roundType"
          rules={{ required: "Game type is required" }}
          control={control}
          render={({ field }) => (
            <Select
              isSearchable={false}
              inputRef={field.ref}
              closeMenuOnSelect={true}
              isMulti={false}
              options={roundConfigEnumsAsReactSelectOptions.gameType}
              value={roundConfigEnumsAsReactSelectOptions.gameType.find(
                (c) => c.value === field.value
              )}
              onChange={(val: { label: string; value: string }) =>
                field.onChange(val.value)
              }
              styles={customStyles}
            />
          )}
        />
        <ErrorMessage
          errors={formState.errors}
          name="roundType"
          render={({ message }) => (
            <div className={tailwind.errorMsg}>{message}</div>
          )}
        />
      </Row>
      {watchGameType === RoundType.RANGE ? <RangeSpecificForm /> : null}
      {!simulatorReservation &&
      !queueItem &&
      watchGameType !== (null || undefined) ? (
        <>
          <div className="bg-gray-50 dark:bg-zinc-800 -mx-12 px-12 mt-6 pb-6">
            <FormRow>
              <span className={tailwind.span}>Set the Timer</span>
              <small className={tailwind.small}>
                {`How many minutes do you want for the ${RoundType[
                  watchGameType
                ]?.toLowerCase()} timer?`}
              </small>
            </FormRow>
            <FormRow>
              <TimerSelection />
            </FormRow>
          </div>
        </>
      ) : null}
      {watchGameType === RoundType.ROUND ? (
        <>
          <FormRow>
            <span className={tailwind.span}>Select Course Options</span>
          </FormRow>
          <RoundSpecificForm
            simulatorId={simulatorReservation?.simulatorId || simulatorId}
            defaultRoundSettings={defaultRoundSettings}
          />
        </>
      ) : null}
    </div>
  );
};
export default StartGameForm;

type StartGameFormProps = {
  simulatorReservation?: SendRoundRangeToSimInfo;
  simulatorId?: string;
  queueItem?: boolean;
  callback?: React.Dispatch<React.SetStateAction<any>>;
  players?: Player[];
};
