import { FormProvider, useForm } from "react-hook-form";
import { modalAction } from "ui-modules/modals/modalSlice";
import { TimerStatus } from "types/enums";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { Player, RoundConfiguration, StartRoundPayload } from "Models";
import { useEffect, useState } from "react";
import { useAppDispatch } from "utils/hooks";
import { useStartRound } from "modules/round/roundHooks";
import { useSimulatorCourses } from "modules/course/courseHooks";
import cn from "classnames";
import ReactTooltip from "react-tooltip";
import Row from "components/ui/layout/Row";
import tailwind from "./StartAdHocRoundContainer.styles";
import { showCustomNotification } from "utils/notifications/notificationHelpers";
import { updateEntities } from "redux-query";
import { addMinutesToTimerArray } from "utils/timer/timerHelpers";
import StartGameForm from "./StartGameForm";
import { ButtonWithIcon } from "components/ui/form";
import RoundConfigurationSelect from "./RoundConfigurationSelect";
import { FaArrowRight } from "react-icons/fa";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import {
  ClubhouseEvents,
  pendoTrackEventWithProperties,
} from "utils/pendo/pendoHelpers";
import { ArrayHelper } from "utils/arrays/arrayHelpers";

const StartAdHocRoundContainer: React.FC<StartAdHocRoundContainerProps> = ({
  simulatorId,
  modalName,
  buildId,
}) => {
  const [selectedSavedRound, setSavedRound] = useState<RoundConfiguration>();
  const [roundPlayers, setRoundPlayers] = useState<Player[]>([]);

  const methods = useForm();

  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [startRoundQueryState, startRound] = useStartRound(
    currentFacilityId,
    simulatorId
  );

  useSimulatorCourses(simulatorId);

  const onSubmit = (data: StartRoundPayload) => {
    dispatch(
      updateEntities({
        simulatorStates: (prev) => ({
          ...prev,
          ...{
            [simulatorId]: {
              ...prev[simulatorId],
              ...{
                timerValue: addMinutesToTimerArray(
                  prev[simulatorId]?.timerValue,
                  data.time
                ),
                timerStatus: TimerStatus.PAUSED,
              },
            },
          },
        }),
      })
    );
    startRound(data);

    pendoTrackEventWithProperties(ClubhouseEvents.START_ROUND, {
      simulatorId: simulatorId,
      facilityId: currentFacilityId,
      courseName: data.roundInfo?.courseName,
      roundType: roundConfigEnumsAsReactSelectOptions.gameType.find(
        (option) => option.value === data.roundType
      )?.label,
      scoringType: roundConfigEnumsAsReactSelectOptions.scoringType.find(
        (option) => option.value === data.roundInfo?.scoringType
      )?.label,
      timer: data.time,
      players: data.roundInfo?.players?.length,
    });
  };

  useEffect(() => {
    if (selectedSavedRound) {
      methods.setValue("autoContinue", selectedSavedRound?.autoContinue);
      methods.setValue(
        "roundInfo.autoConcede",
        selectedSavedRound?.roundInfo?.autoConcede
      );
      methods.setValue(
        "roundInfo.autoDrop",
        selectedSavedRound?.roundInfo?.autoDrop
      );
      methods.setValue(
        "roundInfo.autoGimmes",
        selectedSavedRound?.roundInfo?.autoGimmes
      );
      methods.setValue("build", buildId);
      methods.setValue(
        "roundInfo.courseName",
        selectedSavedRound.roundInfo?.courseName
      );
      methods.setValue(
        "roundInfo.gimmeDistance",
        selectedSavedRound?.roundInfo?.gimmeDistance
      );
      methods.setValue(
        "roundInfo.groundConditions",
        selectedSavedRound?.roundInfo?.groundConditions
      );
      methods.setValue(
        "roundInfo.handicapMode",
        selectedSavedRound?.roundInfo?.handicapMode
      );
      methods.setValue(
        "roundInfo.handicapPercentage",
        selectedSavedRound?.roundInfo?.handicapPercentage
      );
      methods.setValue(
        "roundInfo.courseRatingSlope",
        selectedSavedRound?.roundInfo?.courseRatingSlope
      );
      methods.setValue(
        "holes",
        ArrayHelper.convertBooleanToNumberArray(
          selectedSavedRound.roundInfo?.holesSelected
        )
      );
      methods.setValue(
        "roundInfo.mensTee",
        selectedSavedRound.roundInfo?.mensTee
      );
      methods.setValue(
        "roundInfo.mulligans",
        selectedSavedRound?.roundInfo?.mulligans
      );
      methods.setValue(
        "roundInfo.pinType",
        selectedSavedRound?.roundInfo?.pinType
      );
      methods.setValue("roundType", selectedSavedRound?.roundType);
      methods.setValue(
        "roundInfo.matchSettings.scotchFoursome",
        selectedSavedRound?.roundInfo?.matchSettings.scotchFoursome
      );
      methods.setValue("rangeTime", selectedSavedRound?.rangeTime);
      methods.setValue(
        "roundInfo.scoringType",
        selectedSavedRound?.roundInfo?.scoringType
      );
      methods.setValue(
        "roundInfo.skyConditions",
        selectedSavedRound?.roundInfo?.skyConditions
      );
      methods.setValue(
        "roundInfo.stimpSetting",
        selectedSavedRound?.roundInfo?.stimpSetting
      );
      methods.setValue(
        "roundInfo.timeConditions",
        selectedSavedRound?.roundInfo?.timeConditions
      );
      methods.setValue(
        "roundInfo.windDirection",
        selectedSavedRound?.roundInfo?.windDirection
      );
      methods.setValue(
        "roundInfo.windSpeed",
        selectedSavedRound?.roundInfo?.windSpeed
      );
      methods.setValue(
        "roundInfo.womensTee",
        selectedSavedRound.roundInfo?.womensTee
      );

      showCustomNotification(`${selectedSavedRound?.name} has been loaded.`);
    } else {
      methods.setValue("build", buildId);
    }
  }, [selectedSavedRound, methods, buildId, roundPlayers]);

  useEffect(() => {
    if (startRoundQueryState.isFinished) {
      dispatch(modalAction({ isOpen: false, modalName }));
    }
  }, [startRoundQueryState.isFinished, dispatch, modalName]);

  return (
    <div className={tailwind.roundConfigurationForm}>
      <div className="flex w-full h-full flex-col relative">
        <div className={cn(tailwind.roundFormContainer)}>
          <Row cssClasses="px-8">
            <Row cssClasses="flex-row px-4 mt-2 border-b border-gray-300 py-6">
              <RoundConfigurationSelect onChange={setSavedRound} />
            </Row>
          </Row>
          <Row cssClasses="px-12 pb-12">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <StartGameForm
                  simulatorId={simulatorId}
                  callback={setRoundPlayers}
                  players={roundPlayers}
                />
                <ButtonWithIcon
                  buttonType="submit"
                  text="Send to Sim"
                  showIcon={false}
                  icon={FaArrowRight}
                  fullWidth={true}
                />
              </form>
            </FormProvider>
          </Row>
          <ReactTooltip backgroundColor="#777" textColor="#fff" />
        </div>
      </div>
    </div>
  );
};

export default StartAdHocRoundContainer;

type StartAdHocRoundContainerProps = {
  simulatorId: string;
  modalName: string;
  buildId?: number;
};
