export const styles = {
  desktopSidebar:
    "hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col h-dvh",
  desktopSidebarMenuPanel:
    "flex grow flex-col overflow-y-auto bg-white dark:bg-zinc-800 h-dvh",
  desktopSidebarLogo: "flex h-22 items-center justify-center dark:bg-zinc-800 ",
  desktopSidebarNav:
    "flex flex-1 flex-col px-8 border-r border-gray-200 dark:border-gray-900",
  desktopSidebarList: "flex flex-1 flex-col gap-y-7 pb-4 pt-6",
  desktopSidebarCollapseButton:
    "hidden lg:block absolute bottom-1/2 -right-3 w-6 h-6 bg-white border rounded-full text-gray-400 items-center text-center align-center border-gray-200  dark:bg-zinc-800  dark:text-gray-200 dark:border-gray-900",
};
