import { Round, StartRoundPayload } from "Models";
import { MoveRoundPayload, UseDataInfo } from "QueryTypes";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import {
  getRoundByIdQuery,
  makeMoveRoundMutation,
  makeStartRoundMutation,
} from "./roundQueries";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { EntitiesState, QueryState } from "redux-query";

export const useRoundById = (roundId: string): UseDataInfo<Round> => {
  const [queryState, refreshRound] = useRequest(getRoundByIdQuery(roundId));
  const roundById = useSelector(
    (state: RootState): Round => {
      return state.entities.rounds?.[roundId];
    }
  );

  return [roundById, queryState, refreshRound];
};

export const useStartRound = (
  facilityId: string,
  simulatorId: string
): [QueryState, RunMutation<EntitiesState, [StartRoundPayload]>] => {
  const [
    startRoundQueryState,
    startRound,
  ] = useMutation((body: StartRoundPayload) =>
    makeStartRoundMutation(facilityId, simulatorId, body)
  );
  return [startRoundQueryState, startRound];
};

export const useMoveRound = (
  roundId: string
): [QueryState, RunMutation<EntitiesState, [MoveRoundPayload]>] => {
  const [
    moveRoundQueryState,
    moveRound,
  ] = useMutation((body: MoveRoundPayload) =>
    makeMoveRoundMutation(roundId, body)
  );

  return [moveRoundQueryState, moveRound];
};
