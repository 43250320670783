import { RootState } from "StoreTypes";
import SimSendMessage from "components/shared/control/SimSendMessage";
import SimTimerControls from "components/shared/control/SimTimerControls";
import { simulatorStateIsActive } from "modules/simulator-state/simulatorStateHelpers";
import { getSimulatorStatesForArrayOfSims } from "modules/simulator-state/simulatorStateSelectors";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";

const MultiSimControls: React.FC = () => {
  const simulators = useSelector(getSimulatorsByCurrentFacility);
  const [selectedSims, setSelectedSims] = useState<string[]>([]);

  const simStates = useSelector((state: RootState) =>
    getSimulatorStatesForArrayOfSims(state, simulators)
  );

  const handleMultiselectFieldChange = (e: { label: string; value: any }[]) => {
    setSelectedSims(e.map((x) => x.value));
  };

  return (
    <>
      <div className="flex flex-col mt-4 p-2 h-full dark:bg-zinc-800">
        <div className="flex flex-col w-full">
          <div className="text-sm poppins pb-2 dark:text-gray-50">
            Select Active Sims
          </div>
          <MultiSelect
            options={simulators
              .filter((simulator) =>
                simStates
                  .filter((state) => simulatorStateIsActive(state))
                  .map((simState) => {
                    return simState.id;
                  })
                  ?.includes(simulator.id)
              )
              .map((simulator) => {
                return {
                  label: simulator.name,
                  value: simulator.id,
                };
              })}
            value={simulators
              .filter(
                (simulator) =>
                  selectedSims?.includes(simulator.id) && selectedSims
              )
              .map((simulator) => {
                return {
                  label: simulator.name.toString(),
                  value: simulator.id,
                };
              })}
            onChange={(e: { label: string; value: number }[]) =>
              handleMultiselectFieldChange(e)
            }
            labelledBy="Sims"
            hasSelectAll={true}
            selectAllLabel="Select/Unselect All Active Sims"
            disableSearch={true}
            className="rounded-lg border-2 border-gray-200 dark:border-white dark:bg-zinc-900"
          />
        </div>
        <div className="text-sm poppins pt-8 dark:text-gray-50">
          Timer Controls
        </div>
        <div className="flex flex-row w-full">
          <SimTimerControls simulatorIds={selectedSims} />
        </div>
        <div className="flex flex-row w-full flex-1">
          <SimSendMessage simulatorIds={selectedSims} />
        </div>
      </div>
    </>
  );
};
export default MultiSimControls;
