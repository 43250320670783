import { FaInfoCircle } from "react-icons/fa";

const Label: React.FC<LabelType> = (props) => {
  return (
    <div className="pb-2">
      <label className={props.cssClasses} htmlFor={props.for}>
        {props.name}
        {props.tooltipText ? (
          <FaInfoCircle
            className={`text-${props.tooltipColor}-600 px-1 inline text-lg`}
            data-tip={props.tooltipText}
            data-testid="tooltipTestId"
          />
        ) : null}
      </label>
    </div>
  );
};
export default Label;

type LabelType = {
  name: string;
  tooltipText?: string;
  tooltipColor?: string;
  cssClasses?: string;
  for?: string;
};
Label.defaultProps = {
  tooltipText: "",
  tooltipColor: "gray",
  cssClasses: "text-xs poppins text-black dark:text-gray-200 mb-2",
};
