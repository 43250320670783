export const styles = {
  roundConfigurationForm: [
    "flex flex-1 overflow-hidden bg-white  dark:bg-zinc-800 ",
  ].join(" "),
  timerStatus: [
    "text-xl md:text-2xl text-gray-500 dark:text-gray-200 float-right",
  ].join(" "),
  hidden: ["hidden"].join(" "),
  absolute: ["absolute"].join(" "),
  span: ["text-md block brand-dark-gray poppins dark:text-gray-200"].join(" "),
  small: ["text-sm text-gray-600 dark:text-gray-200"].join(" "),
  label: [
    "text-sm font-semibold text-gray-500 py-2 heading dark:text-gray-200",
  ].join(" "),
  mobileButton: [
    "text-black bg-white font-semibold w-screen z-100 border-gray-300 border-b",
  ].join(" "),
  buttonContainer: ["flex flex-shrink-0 w-6"].join(" "),
  sidebarToggleTab: [
    "text-gray-400 fixed bottom-8 bg-gray-100 rounded-tr rounded-br z-100 border-gray-300 border-t border-b border-r dark:bg-zinc-800 dark:text-gray-200",
  ].join(" "),
  roundFormContainer: ["w-full flex-row"].join(" "),
  quickPlayer: ["text-sm ml-2"].join(" "),
  submitButton: [
    "w-full bg-green-600 hover:bg-green-600 text-white text-sm tracking-wide py-2 px-4 border border-green-600 rounded shadow-md hover:animate-pulse focus:ring-2 focus:ring-blue-600 active:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed mt-8",
  ].join(" "),
  submitContainer: ["w-full mb-16 pb-4 mt-4"].join(" "),
  stepOff: ["text-gray-400 border-r border-gray-200"].join(" "),
  closeButton: [
    "w-full bg-gray-200 hover:bg-gray-400 hover:text-white text-sm text-gray-500 uppercase tracking-wide py-2 px-4 border border-gray-300 rounded shadow",
  ].join(" "),
  closeContainer: [
    "w-full md:w-1/3 m-0 p-0 hidden md:inline-block py-1 md:pl-1",
  ].join(" "),
  errorMsg: ["text-sm text-red-500"].join(" "),
};
export default styles;
