import { useState } from "react";
import { globalStyles } from "components/globalStyles";
import { useSendMessageToSimulator } from "modules/simulator";
import {
  notificationMessageGroup,
  showResponseNotification,
} from "utils/notifications/notificationHelpers";
import styles from "components/control/dashboard/DashboardComponents.styles";
import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { modalName, ModalType } from "utils/modals/modalHelpers";

type OwnProps = {
  simulatorId?: string;
  simulatorIds?: string[];
};

const SimSendMessage: React.FC<OwnProps> = (props) => {
  const dispatch = useAppDispatch();
  const [input, storeInput] = useState("");

  const sendSimMessageModalName = modalName(
    ModalType.SendSimulatorMessage,
    props.simulatorId
  );

  const handleInput = (event: any) => {
    storeInput(event.target.value);
  };

  const setModalClose = () => {
    dispatch(
      modalAction({ isOpen: false, modalName: sendSimMessageModalName })
    );
  };

  const useHandleMessage = () => {
    const response = useSendMessageToSimulator(
      input,
      props.simulatorId,
      props.simulatorIds
    );
    setModalClose();
    showResponseNotification(response, notificationMessageGroup.message);
    storeInput("");
  };

  return (
    <div className={styles.simSendMessageContainer}>
      <div className="font-semibold text-sm poppins dark:text-gray-200 pb-2">{`Send Message`}</div>
      <textarea
        name="messageArea"
        placeholder="Type message here and click 'Send'"
        className={styles.simSendMessageTextbox}
        id="messageArea"
        onChange={handleInput}
        value={input}
      ></textarea>
      <div className={styles.simSendMessageButtonContainer}>
        <button
          onClick={useHandleMessage}
          disabled={!input}
          className={`${globalStyles.primaryButton} ${globalStyles.buttonLarge}`}
        >
          Send
        </button>
      </div>
    </div>
  );
};
export default SimSendMessage;
