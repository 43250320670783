import { useAppDispatch } from "utils/hooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { modalName, ModalType } from "utils/modals/modalHelpers";
import styles from "../SingleFacilityControlDashboard.styles";
import SimEndRoundButton from "components/shared/control/SimEndRoundButton";
import ReactTooltip from "react-tooltip";
import { MdOutlinePlayArrow } from "react-icons/md";
import { BsChatText } from "react-icons/bs";
import { TbUpload } from "react-icons/tb";

const SingleSimCardViewFooter: React.FC<SingleSimCardViewFooterProps> = (
  props
) => {
  const dispatch = useAppDispatch();

  const { simulatorId, roundInProgress, stateIsActive, roundIsActive } = props;

  const moveRoundModalName = modalName(ModalType.MoveRoundModal, simulatorId);
  const disableMoveRoundButton = !stateIsActive || !roundInProgress;

  const sendSimMessageModalName = modalName(
    ModalType.SendSimulatorMessage,
    simulatorId
  );

  const setModal = (modalName: string) =>
    dispatch(modalAction({ isOpen: true, modalName }));

  return (
    <>
      <div
        className={`${styles.SingleSimCardViewFooterContainer} rounded-bl-xl`}
      >
        {roundInProgress ? (
          <SimEndRoundButton
            simulatorId={simulatorId}
            className={`${roundIsActive ? "disabled" : ""} `}
            showIcon={true}
            showText={false}
          />
        ) : (
          <button
            disabled={!stateIsActive}
            onClick={() => setModal(`roundControlModal-${simulatorId}`)}
            data-tip="Load Round"
            data-for={`singleSimCardViewFooter-${props.simulatorId}`}
            className={`${roundIsActive ? "disabled" : ""} `}
          >
            <MdOutlinePlayArrow
              className={styles.SingleSimCardViewFooterButton}
            />
          </button>
        )}
      </div>
      <div className={styles.SingleSimCardViewFooterContainer}>
        <button
          onClick={() => setModal(sendSimMessageModalName)}
          data-for={`singleSimCardViewFooter-${props.simulatorId}`}
          data-tip="Send Sim Message"
        >
          <BsChatText className={styles.SingleSimCardViewFooterButton} />
        </button>
      </div>
      <div
        className={`${styles.SingleSimCardViewFooterContainer} rounded-br-xl`}
      >
        <button
          disabled={disableMoveRoundButton}
          onClick={() => setModal(moveRoundModalName)}
          data-tip={
            disableMoveRoundButton ? "Move Round Disabled" : "Move Round"
          }
          data-for={`singleSimCardViewFooter-${props.simulatorId}`}
          className="cursor-pointer disabled:cursor-not-allowed"
        >
          <TbUpload className={styles.SingleSimCardViewFooterButton} />
        </button>
      </div>
      <ReactTooltip
        place="bottom"
        delayShow={200}
        backgroundColor="#f9fafb"
        textColor="#4b5563"
        border={true}
        borderColor="#d1d5db"
        id={`singleSimCardViewFooter-${props.simulatorId}`}
        className="!text-xs"
      />
    </>
  );
};
export default SingleSimCardViewFooter;

type SingleSimCardViewFooterProps = {
  simulatorId: string;
  roundInProgress?: boolean;
  stateIsActive?: boolean;
  roundIsActive?: boolean;
};
SingleSimCardViewFooter.defaultProps = {
  simulatorId: "",
  roundInProgress: false,
  stateIsActive: false,
  roundIsActive: false,
};
