import { motion } from "framer-motion";
import { SimulatorState } from "Models";
import { useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import {
  getLocalStorageValue,
  LocalStorageItems,
  localStorageValueToBoolean,
} from "utils/hooks/localStorage";
import {
  calculateRoundPace,
  roundPaceColorHelper,
} from "utils/simulators/simulatorHelpers";
import {
  humanReadableTimerValue,
  timerStringToTimerArray,
} from "utils/timer/timerHelpers";

type RateOfPlayProps = {
  simulatorState: SimulatorState;
};

const RateOfPlay: React.FC<RateOfPlayProps> = (props) => {
  const { simulatorState } = props;

  const roundPace = useMemo(() => {
    return calculateRoundPace(simulatorState);
  }, [simulatorState]);

  const roundPaceClassName = roundPaceColorHelper[roundPace];

  const [isDarkMode] = useState(() =>
    localStorageValueToBoolean(getLocalStorageValue(LocalStorageItems.DARKMODE))
      ? true
      : false
  );

  return (
    <>
      <span
        className="flex cursor-pointer text-xs text-gray-400"
        data-tip="The estimated amount of time it will take to complete <br />the current round based on the current pace"
        data-for={`${simulatorState?.roundId}-RateOfPlay`}
      >
        Rate of play
      </span>
      <motion.div
        key={simulatorState?.estimatedTimeRemaining}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.75,
        }}
        className="flex text-xs"
      >
        <span
          className={`py-1 px-3 rounded-lg font-bold ${
            isDarkMode
              ? roundPaceClassName.primaryBg
              : roundPaceClassName.secondaryBg
          }`}
        >
          +{" "}
          {humanReadableTimerValue(
            timerStringToTimerArray(simulatorState?.estimatedTimeRemaining)
          )}{" "}
          <span className="font-light text-gray-600 dark:text-gray-200">
            remaining
          </span>
        </span>
      </motion.div>
      <ReactTooltip
        id={`${simulatorState?.roundId}-RateOfPlay`}
        place="bottom"
        delayShow={200}
        backgroundColor="#f9fafb"
        textColor="#4b5563"
        border={true}
        borderColor="#d1d5db"
        multiline={true}
      />
    </>
  );
};
export default RateOfPlay;
