import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { multiSimManagementTrayAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { styles } from "./MultiSimManagement.styles";
import { AnimatePresence, motion } from "framer-motion";
import { CgCloseR } from "react-icons/cg";
import React from "react";
import MultiSimControls from "./MultiSimControls";

const MultiSimManagementTray: React.FC<MultiSimManagementTrayProps> = (
  props
) => {
  const dispatch = useAppDispatch();

  const setMultiSimManagementTray = () =>
    dispatch(
      multiSimManagementTrayAction({ isOpen: !isMultiSimManagementTrayOpen })
    );

  const isMultiSimManagementTrayOpen = useSelector(
    (state: RootState) => state.nav?.multiSimManagementTray?.isOpen
  );

  return (
    <AnimatePresence>
      {isMultiSimManagementTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: 0,
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.4 }}
            className={styles.trayContainer}
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => setMultiSimManagementTray()}
                  className={styles.button}
                >
                  <CgCloseR className="h-5 w-5" /> Close
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>Sim Management</h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <MultiSimControls />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => setMultiSimManagementTray()}
            className={styles.overlay}
          />
        </>
      )}
    </AnimatePresence>
  );
};

export default MultiSimManagementTray;

type MultiSimManagementTrayProps = {
  title?: string;
  body?: React.ReactNode;
};

MultiSimManagementTray.defaultProps = {
  title: "Help Docs",
};
