import { RootState } from "StoreTypes";
import { styles } from "./Leagues.styes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { useEffect, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { enableBackgroundScroll } from "utils/ui/uiHelpers";
import { setSelectedRoundId } from "modules/leagues/leagueSlice";
import { createLeagueRoundTrayAction } from "ui-modules/nav/navSlice";
import { useSelectedLeagueRoundId } from "modules/leagues/leagueRoundHooks";
import CreateLeagueRoundForm from "./rounds/CreateLeagueRoundForm";

const CreateLeagueRoundTray: React.FC = () => {
  const dispatch = useAppDispatch();

  const selectedLeagueRoundId = useSelectedLeagueRoundId();

  const editLeagueRound = selectedLeagueRoundId ? true : false;

  const isCreateLeagueRoundTrayOpen = useSelector(
    (state: RootState) => state.nav?.createLeagueRoundTray?.isOpen
  );

  const handleCreateLeagueRoundTrayClosed = useCallback(() => {
    enableBackgroundScroll();
    dispatch(
      setSelectedRoundId({
        selectedLeagueRoundId: "",
      })
    );
    dispatch(createLeagueRoundTrayAction({ isOpen: false }));
  }, [dispatch]);

  useEffect(() => {
    if (isCreateLeagueRoundTrayOpen === false) {
      handleCreateLeagueRoundTrayClosed();
    }
  }, [isCreateLeagueRoundTrayOpen, handleCreateLeagueRoundTrayClosed]);

  return (
    <AnimatePresence>
      {isCreateLeagueRoundTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: "0px",
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.75 }}
            className={`${styles.trayContainer} z-9999`}
            key="createLeagueTray"
            data-testid="createLeagueTray"
            id="createLeagueTray"
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => handleCreateLeagueRoundTrayClosed()}
                  className={styles.button}
                >
                  Close
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>
                  {editLeagueRound ? "Edit" : "Create"} League Round
                </h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <CreateLeagueRoundForm />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => handleCreateLeagueRoundTrayClosed()}
            className={styles.overlay}
          />
        </>
      )}
    </AnimatePresence>
  );
};
export default CreateLeagueRoundTray;
