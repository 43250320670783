import { AnimatePresence, motion } from "framer-motion";
import { Simulator } from "Models";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import {
  BreakPoints,
  containerMotionVariants,
  useMediaQuery,
} from "utils/animations/animationHelper";
import ListViewContainer from "./ListViewContainer";
import SingleSimCardView from "./SingleSimCardView";

type OwnProps = {
  allSelected: boolean;
  listView: boolean;
  simulators: Simulator[];
  selectSim: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const DashboardSimulatorsContainer: React.FC<OwnProps> = ({
  allSelected,
  listView,
  simulators,
  selectSim,
}) => {
  const isDesktop = useMediaQuery(BreakPoints.MD);
  const showQueueOnDashboard = useSelector(
    (state: RootState) => state.nav?.queue?.showOnDashboard
  );
  return (
    <AnimatePresence>
      {simulators && (
        <motion.div
          className={
            !listView || !isDesktop
              ? `grid gap-6 grid-cols-1 ${
                  showQueueOnDashboard
                    ? "md:grid-cols-2"
                    : "md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5"
                } px-2`
              : ""
          }
          variants={containerMotionVariants}
          initial="hidden"
          animate="show"
        >
          {!listView || !isDesktop ? (
            simulators?.map((sim) => (
              <SingleSimCardView
                simId={sim.id}
                key={`${sim.id}-${allSelected ? "on" : "off"}`}
                checkboxFunction={selectSim}
                checked={allSelected}
              />
            ))
          ) : (
            <ListViewContainer
              simulators={simulators}
              allSelected={allSelected}
              callback={selectSim}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DashboardSimulatorsContainer;
