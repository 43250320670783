import { motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import { MotionVariantType } from "types/enums";
import { styles } from "../teams/RosterAndGroups.styles";
import { useGetSelectedLeague } from "modules/leagues/leagueHooks";
import { useGetLeagueRoundMatches } from "modules/leagues/leagueMatchHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useSelectedLeagueRoundId } from "modules/leagues/leagueRoundHooks";
import {
  containerMotionVariants,
  dailyQueueListItemVariant,
} from "utils/animations/animationHelper";
import Match from "./Match";

const Matches = () => {
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeague = useGetSelectedLeague();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();
  const [leagueMatches] = useGetLeagueRoundMatches(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  return (
    <div className="">
      <div></div>
      {leagueMatches.length === 0 ? (
        <div className={styles.noPlayersRosterMessage}>
          <MdClose className={styles.closeIcon} />
          Matches will appear here after they are created. Click the "Create
          Match" button above to start creating matches for this round.
        </div>
      ) : (
        <motion.div
          variants={containerMotionVariants}
          initial={MotionVariantType.HIDDEN}
          animate={MotionVariantType.SHOW}
          key={`leagueMatchesList-${leagueMatches.length}`}
          className="h-auto"
        >
          <motion.div
            layout
            variants={dailyQueueListItemVariant}
            className="grid grid-cols-1 sm:grid-cols-2 3xl:grid-cols-3 gap-4 pb-8 h-full"
          >
            {leagueMatches?.map((match) => (
              <Match match={match} />
            ))}
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};
export default Matches;
