import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import {
  aGHandicapAction,
  onlineEventsTrayAction,
  roundSettingsAction,
  queueTrayAction,
} from "ui-modules/nav/navSlice";
import {
  BreakPoints,
  buttonMotionVariants,
  useMediaQuery,
} from "utils/animations/animationHelper";
import { useAppDispatch } from "utils/hooks";
import EventCard from "./EventCard";
import ReactTooltip from "react-tooltip";
import { FaGolfBall, FaGlobe, FaCalendarAlt } from "react-icons/fa";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import { VscSettings } from "react-icons/vsc";

const Events = () => {
  const { events, queue } = useFlags();

  const dispatch = useAppDispatch();

  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);

  const isLockerUserHandicapTrayOpen = useSelector(
    (state: RootState) => state.nav?.lockerUserEditTray?.isOpen
  );

  const isOnlineEventsTrayOpen = useSelector(
    (state: RootState) => state.nav?.onlineEventsTray?.isOpen
  );

  const isSimulatorReservationTrayOpen = useSelector(
    (state: RootState) => state.nav?.simulatorReservationTray?.isOpen
  );

  const isRoundSettingsTrayOpen = useSelector(
    (state: RootState) => state.nav?.roundSettings?.isOpen
  );

  const setLockerUserHandicapTray = () =>
    dispatch(aGHandicapAction({ isOpen: !isLockerUserHandicapTrayOpen }));

  const setQueueTray = () =>
    dispatch(
      queueTrayAction({
        isOpen: !isSimulatorReservationTrayOpen,
      })
    );

  const setOnlineEventsTray = () =>
    dispatch(onlineEventsTrayAction({ isOpen: !isOnlineEventsTrayOpen }));

  const setRoundSettingsTray = () =>
    dispatch(roundSettingsAction({ isOpen: !isRoundSettingsTrayOpen }));

  const listItemClassName =
    "col-span-1 flex flex-col text-center bg-white  dark:bg-zinc-800  dark:text-gray-200 rounded-lg hover:shadow-lg shadow divide-y divide-gray-200";

  return (
    <div
      className={`Events w-full ${
        isDesktop ? (isSidebarCollapsed ? "pl-24" : "pl-64") : "pl-0"
      }`}
    >
      <div className="flex flex-col md:flex-row min-h-screen w-full max-w-screen-xl mx-auto mt-8 md:mt-12">
        <div className="mt-8 sm:mx-auto sm:max-w-3xl px-4">
          <motion.ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3"
            initial={{ x: 0 }}
            animate={{
              transition: {
                staggerChildren: 0.1,
                delayChildren: 0.3,
              },
            }}
          >
            <motion.li
              whileTap="tap"
              whileHover="hover"
              variants={buttonMotionVariants}
              className={listItemClassName}
            >
              <button
                onClick={() => setLockerUserHandicapTray()}
                className="hover:bg-shadow-sm"
                data-testid="btnOpenLockerUserHandicapTray"
                id="btnOpenLockerUserHandicapTray"
              >
                <EventCard
                  title="Locker aG Handicap Index"
                  description="Update a user's aG Handicap index for use in online events"
                  icon={FaGolfBall}
                  enabled={true}
                  testId="updateAgHandicapCard"
                />
              </button>
            </motion.li>
            {events && (
              <motion.li
                whileTap="tap"
                whileHover="hover"
                variants={buttonMotionVariants}
                className={listItemClassName}
              >
                <button
                  onClick={() => setOnlineEventsTray()}
                  className="hover:bg-shadow-sm "
                  data-testid="btnOpenOnlineEventsTray"
                  id="btnOpenOnlineEventsTray"
                >
                  <EventCard
                    title="Online Events"
                    description="Create and manage online events. Coming soon."
                    icon={FaGlobe}
                    enabled={true}
                  />
                </button>
              </motion.li>
            )}
            {queue && (
              <motion.li
                whileTap="tap"
                whileHover="hover"
                variants={buttonMotionVariants}
                className={listItemClassName}
              >
                <button
                  onClick={() => setQueueTray()}
                  className="hover:bg-shadow-sm"
                  data-testid="btnOpenLockerUserHandicapTray"
                  id="btnOpenLockerUserHandicapTray"
                >
                  <EventCard
                    title="Queue"
                    description="Plan future rounds and set players and settings before customers arrive."
                    icon={FaCalendarAlt}
                    enabled={true}
                  />
                </button>
              </motion.li>
            )}
            <motion.li
              whileTap="tap"
              whileHover="hover"
              variants={buttonMotionVariants}
              className={listItemClassName}
            >
              <button
                onClick={() => setRoundSettingsTray()}
                className="hover:bg-shadow-sm"
                data-testid="btnOpenRoundConfigTray"
                id="btnOpenRoundConfigTray"
              >
                <EventCard
                  title="Round Config"
                  description="Create default round configurations for online events."
                  icon={VscSettings}
                  enabled={true}
                />
              </button>
            </motion.li>
          </motion.ul>
          <ReactTooltip backgroundColor="#777" textColor="#fff" />
        </div>
      </div>
    </div>
  );
};
export default Events;
