export const styles = {
  button: "brand-dark-gray dark:text-gray-200",
  headerContainer:
    "grid grid-cols-5 items-center w-full bg-white  dark:bg-zinc-800  h-16 border-b border-gray-300 dark:border-gray-800 px-4 md:px-8",
  headerCloseButton: "text-left pl-2 md:pl-0 align-baseline",
  headerTitle: "col-span-3 text-center",
  headerRightButton: "text-right align-baseline",
  innerContainer: "px-4 md:px-8 bg-gray-100 dark:bg-zinc-800",
  overlay:
    "bg-gray-600 bg-opacity-30 px-5 fixed h-full w-full flex items-center justify-center top-0 left-0 z-9998",
  trayContainer:
    "fixed bg-gray-50 dark:bg-zinc-800 text-gray-500 dark:text-gray-200 shadow-lg top-0 right-0 w-full max-w-xl h-screen z-9999 overflow-y-scroll text-sm",
  trayHeader: "text-lg font-semibold brand-dark-gray dark:text-gray-200",
};
