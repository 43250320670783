import Select from "react-select";
import styles from "./SimulatorReservationContainer.styles.";
import { Simulator } from "Models";
import { useSelector } from "react-redux";
import { customStyles } from "utils/ui/uiHelpers";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useFormContext } from "react-hook-form";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";

const SimulatorSelect: React.FC<SimulatorSelectProps> = (props) => {
  const {
    control,
    formState,
    setValue,
    clearErrors,
    getValues,
  } = useFormContext();
  const simulators = useSelector(getSimulatorsByCurrentFacility);
  const { simulatorId } = props;

  const handleChange = (sim: Simulator) => {
    setValue("simulatorId", sim.id);
    setValue("roundInfo.build", sim.caddyVersion);
    clearErrors("simulatorId");
  };
  return (
    <>
      <span className={styles.dateSpan}>Select a Sim</span>
      <Controller
        name="simulatorId"
        control={control}
        rules={{ required: "Simulator is required" }}
        defaultValue={
          getValues("simulatorId") || simulatorId || simulators[0]?.id
        }
        render={({ field }) => (
          <Select
            {...field}
            closeMenuOnSelect={true}
            isMulti={false}
            options={simulators}
            getOptionLabel={(sim: Simulator) => sim.name}
            getOptionValue={(sim: Simulator) => sim.id}
            value={simulators.find((sim) => sim.id === field.value)}
            styles={customStyles}
            onChange={handleChange}
          />
        )}
      />
      <ErrorMessage
        errors={formState.errors}
        name="simulatorId"
        render={({ message }) => (
          <div className="text-sm text-red-500">{message}</div>
        )}
      />
    </>
  );
};
export default SimulatorSelect;

type SimulatorSelectProps = {
  simulatorId?: string;
};
