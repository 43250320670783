import Select from "react-select";
import React from "react";
import {
  enumNameToLabel,
  roundConfigEnumsAsReactSelectOptions,
} from "utils/enums/enumHelper";
import Row from "components/ui/layout/Row";
import cn from "classnames";
import styles from "./Settings.less";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useFacilityDefaultRoundSettings } from "modules/default-round-settings";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import { useUpdateDefaultRoundSettings } from "modules/default-round-settings/defaultRoundSettingsHooks";
import { customStyles } from "utils/ui/uiHelpers";
import { isApiStatusSuccess } from "utils/api/apiHelper";

const DefaultRoundSettings: React.FC = () => {
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const booleanList = {
    options: [
      { value: false, label: "Off" },
      { value: true, label: "On" },
    ],
  };

  const [, updateDefaultRoundSettings] = useUpdateDefaultRoundSettings(
    currentFacilityId
  );

  const [currentFacilityDefaultRoundSettings] = useFacilityDefaultRoundSettings(
    currentFacilityId
  );

  const onInputChange = async (
    inputValue: InputProps,
    inputName: OptionProps
  ) => {
    let response = await updateDefaultRoundSettings({
      [inputName.name]: inputValue.value,
    });

    if (isApiStatusSuccess(response?.status)) {
      await showCustomNotification(
        enumNameToLabel(inputName.name) + " saved successfully"
      );
    } else {
      await showCustomNotification(
        "An error occurred while saving " +
          enumNameToLabel(inputName.name) +
          ". Please try again.",
        GenericNotificationType.ERROR
      );
    }
  };

  return (
    <Row
      cssClasses={cn(
        styles.Login,
        "flex flex-col min-h-screen w-full max-w-screen-xl mx-auto"
      )}
      id="settings"
    >
      <Row cssClasses="flex flex-col w-full px-8 md:px-0">
        <Row cssClasses="text-lg border-b pb-4 mb-4 p-2">
          Default Round Settings
          <br />
          <small className="text-sm">
            Update the default values used for creating new saved round setups.
          </small>
        </Row>
        <Row cssClasses="flex flex-col md:flex-row border-b pb-4 mb-4">
          <Row cssClasses="block w-full md:w-1/3 md:flex md:flex-col p-2">
            <Row cssClasses="text-md font-semibold">Player Settings</Row>
            <Row cssClasses="text-sm pt-2 text-gray-500 dark:text-gray-200 font-normal">
              Set the default player settings here.
            </Row>
          </Row>
          <Row cssClasses="block w-full md:w-2/3 md:flex md:flex-col">
            <Row cssClasses="w-full p-2">
              <h4>Auto Concede</h4>
              <Select
                name="autoConcede"
                closeMenuOnSelect={true}
                value={roundConfigEnumsAsReactSelectOptions.autoConcede.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.autoConcede
                )}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.autoConcede}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>{" "}
            <Row cssClasses="w-full p-2">
              <label>Auto Continue</label>
              <Select
                name="autoContinue"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.autoContinue}
                value={roundConfigEnumsAsReactSelectOptions.autoContinue.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.autoContinue
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Auto Drop</label>
              <Select
                name="autoDrop"
                closeMenuOnSelect={true}
                isMulti={false}
                options={booleanList.options}
                value={booleanList.options.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.autoDrop
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Auto Gimme</label>
              <Select
                name="autoGimmes"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.autoGimme}
                value={roundConfigEnumsAsReactSelectOptions.autoGimme.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.autoGimmes
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Gimme Distance</label>
              <Select
                name="gimmeDistance"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.gimmeDistance}
                value={roundConfigEnumsAsReactSelectOptions.gimmeDistance.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.gimmeDistance
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Mens Tee</label>
              <Select
                name="mensTee"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.teeType}
                value={roundConfigEnumsAsReactSelectOptions.teeType.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.mensTee
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Womens Tee</label>
              <Select
                name="womensTee"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.teeType}
                value={roundConfigEnumsAsReactSelectOptions.teeType.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.womensTee
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
          </Row>
        </Row>
        <Row cssClasses="flex flex-col md:flex-row pb-4 mb-4">
          <Row cssClasses="block w-full md:w-1/3 md:flex md:flex-col p-2">
            <Row cssClasses="text-lg">Course Settings</Row>
            <Row cssClasses="text-sm pt-2 text-gray-500">
              Set the default course settings here.
            </Row>
          </Row>
          <Row cssClasses="block w-full md:w-2/3 md:flex md:flex-col">
            <Row cssClasses="w-full p-2">
              <label>Green Stimp</label>
              <Select
                name="stimpSetting"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.stimpSetting}
                value={roundConfigEnumsAsReactSelectOptions.stimpSetting.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.stimpSetting
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Ground Conditions</label>
              <Select
                name="groundConditions"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.groundConditions}
                value={roundConfigEnumsAsReactSelectOptions.groundConditions.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.groundConditions
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Handicap</label>
              <Select
                name="handicapMode"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.handicapMode}
                value={roundConfigEnumsAsReactSelectOptions.handicapMode.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.handicapMode
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Mulligans</label>
              <Select
                name="mulligans"
                closeMenuOnSelect={true}
                isMulti={false}
                onChange={onInputChange}
                options={booleanList.options}
                value={booleanList.options.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.mulligans
                )}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Pin Type</label>
              <Select
                name="pinType"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.pinType}
                value={roundConfigEnumsAsReactSelectOptions.pinType.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.pinType
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Scoring Type</label>
              <Select
                name="scoringType"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.scoringType}
                value={roundConfigEnumsAsReactSelectOptions.scoringType.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.scoringType
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Sky Conditions</label>
              <Select
                name="skyConditions"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.skyConditions}
                value={roundConfigEnumsAsReactSelectOptions.skyConditions.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.skyConditions
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Time of Day</label>
              <Select
                name="timeConditions"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.timeOfDay}
                value={roundConfigEnumsAsReactSelectOptions.timeOfDay.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.timeConditions
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Wind Direction</label>
              <Select
                name="windDirection"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.windDirection}
                value={roundConfigEnumsAsReactSelectOptions.windDirection.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.windDirection
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <label>Wind Speed</label>
              <Select
                name="windSpeed"
                closeMenuOnSelect={true}
                isMulti={false}
                options={roundConfigEnumsAsReactSelectOptions.windSpeed}
                value={roundConfigEnumsAsReactSelectOptions.windSpeed.find(
                  (option) =>
                    option.value ===
                    currentFacilityDefaultRoundSettings?.windSpeed
                )}
                onChange={onInputChange}
                styles={customStyles}
              />
            </Row>
          </Row>
        </Row>
        <Row cssClasses="flex flex-col md:flex-row hidden">
          <Row cssClasses="block w-full md:w-1/3 md:flex md:flex-col">
            <Row cssClasses="text-lg">Save Settings</Row>
            <Row cssClasses="text-sm pt-2 text-gray-500">
              Enter a name and save the settings.
            </Row>
          </Row>
          <Row cssClasses="block w-full md:w-2/3 md:flex md:flex-col p-2">
            <Row cssClasses="w-full p-2">
              <input
                className="border border-gray-300 rounded h-10 p-2 w-full dark:text-black"
                placeholder="Type a name and click save"
              />
            </Row>
            <Row cssClasses="w-full p-2">
              <button
                type="submit"
                className="w-full block bg-green-600 hover:bg-green-500 text-white py-2 px-4 mt-2 border border-gray-200 rounded min-w-min hover:animate-pulse focus:ring-2 focus:ring-blue-600 active:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
                tabIndex={4}
              >
                <span className="uppercase inline">Save Settings</span>
              </button>
            </Row>
          </Row>
        </Row>
      </Row>
    </Row>
  );
};
export default DefaultRoundSettings;

type InputProps = {
  label: string;
  value: string;
};

type OptionProps = {
  action: string;
  name: string;
  option: string;
};
