import { PlayerUser } from "Models";
import { FormatOptionLabelContext } from "react-select/src/Select";

const ScorecardSearchFormattedOptions = ({
  value,
  context,
}: {
  value: PlayerUser;
  context: FormatOptionLabelContext;
}) => {
  return context === "menu" ? (
    <div>
      {value.displayName ? <strong>{value.displayName}</strong> : null}{" "}
      {value.username ? (
        <span className="text-gray-500">- {value.username}</span>
      ) : null}
      <br />
      {value.email ? (
        <span className="text-gray-300">{value.email}</span>
      ) : null}
    </div>
  ) : (
    <div>{value.username || value.displayName || value.email}</div>
  );
};
export default ScorecardSearchFormattedOptions;
