import {
  BreakPoints,
  containerMotionVariants,
  useMediaQuery,
} from "utils/animations/animationHelper";
import { useCallback } from "react";
import { RootState } from "StoreTypes";
import { FiPlus } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { styles } from "../Leagues.styes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import {
  disableBackgroundScroll,
  useSidebarCollapsed,
} from "utils/ui/uiHelpers";
import { ModalType, isModalOpen } from "utils/modals/modalHelpers";
import { createLeagueRoundTrayAction } from "ui-modules/nav/navSlice";
import { useLeagueRoundsByLeagueId } from "modules/leagues/leagueRoundHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  useSelectedLeague,
  useSelectedLeagueId,
} from "modules/leagues/leagueHooks";
import LeagueRoundCard from "./LeagueRoundCard";
import LeagueBreadcrumbs from "../LeagueBreadcrumbs";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import LeagueRoundGroupModal from "./groups/LeagueRoundGroupModal";

const LeagueRounds = () => {
  const dispatch = useAppDispatch();
  const leagueId = useSelectedLeagueId();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);
  const selectedLeague = useSelectedLeague();

  const [leagueRounds, leagueRoundsQueryState] = useLeagueRoundsByLeagueId(
    currentFacilityId,
    leagueId
  );

  const isCreateLeagueRoundTrayOpen = useSelector(
    (state: RootState) => state.nav?.createLeagueRoundTray?.isOpen
  );

  const handleCreateLeagueRoundTray = useCallback(() => {
    disableBackgroundScroll();
    dispatch(
      createLeagueRoundTrayAction({ isOpen: !isCreateLeagueRoundTrayOpen })
    );
  }, [dispatch, isCreateLeagueRoundTrayOpen]);

  return (
    <div
      className={`leagueRounds w-full ${
        isDesktop ? (isSidebarCollapsed ? "pl-24" : "pl-64") : "pl-0"
      }`}
    >
      {isModalOpen(ModalType.LeagueRoundGroups) && (
        <LeagueRoundGroupModal modalName={ModalType.LeagueRoundGroups} />
      )}
      <div className={`${styles.innerContainer}`}>
        <AnimatePresence>
          <motion.div
            className={styles.motionContainer}
            key="leagueCards"
            variants={containerMotionVariants}
            initial="hidden"
            animate="show"
          >
            <div className={styles.filterContainer}>
              <div className={styles.filterInnerContainerLeft}>
                <LeagueBreadcrumbs />
              </div>
              <div className={styles.filterInnerContainerRight}>
                <button
                  type="button"
                  className={styles.createButton}
                  onClick={() => handleCreateLeagueRoundTray()}
                >
                  Create Round <FiPlus className="w-4 h-4 font-bold" />
                </button>
              </div>
            </div>
            {leagueRoundsQueryState.isPending ? (
              <div className="flex items-center h-3/4 w-full">
                <SpinnerAnimation color={"#f2f2e6"} />
              </div>
            ) : (
              <>
                {leagueRounds.length === 0 && (
                  <div className={styles.noLeaguesAvailable}>
                    <MdClose className={styles.noLeaguesIcon} />
                    There are no current Rounds available. Click "Create Round
                    +" to create your first round.
                  </div>
                )}
                <div className={styles.leagueCardContainer}>
                  {selectedLeague &&
                    leagueRounds.map((leagueRound) => (
                      <LeagueRoundCard
                        league={selectedLeague}
                        leagueRound={leagueRound}
                        key={`leagueRound-${leagueRound.id}`}
                      />
                    ))}
                </div>
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};
export default LeagueRounds;
