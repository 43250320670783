import { RootState } from "StoreTypes";
import { styles } from "./Leagues.styes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useCallback } from "react";
import { enableBackgroundScroll } from "utils/ui/uiHelpers";
import { createLeagueTrayAction } from "ui-modules/nav/navSlice";
import { setSelectedLeagueId } from "modules/leagues/leagueSlice";
import { useSelectedLeagueId } from "modules/leagues/leagueHooks";
import CreateLeagueForm from "./CreateLeagueForm";

const CreateLeagueTray: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedLeagueId = useSelectedLeagueId();

  const editLeague = selectedLeagueId ? true : false;

  const isCreateLeagueTrayOpen = useSelector(
    (state: RootState) => state.nav?.createLeagueTray?.isOpen
  );

  const handleCreateLeagueTrayClosed = useCallback(() => {
    enableBackgroundScroll();
    dispatch(
      setSelectedLeagueId({
        selectedLeagueId: "",
      })
    );
    dispatch(createLeagueTrayAction({ isOpen: false }));
  }, [dispatch]);

  useEffect(() => {
    if (isCreateLeagueTrayOpen === false) {
      handleCreateLeagueTrayClosed();
    }
  }, [isCreateLeagueTrayOpen, handleCreateLeagueTrayClosed]);

  return (
    <AnimatePresence>
      {isCreateLeagueTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: "0px",
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.75 }}
            className={`${styles.trayContainer} z-9999`}
            key="createLeagueTray"
            data-testid="createLeagueTray"
            id="createLeagueTray"
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => handleCreateLeagueTrayClosed()}
                  className={styles.button}
                >
                  Close
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>
                  {editLeague ? "Edit" : "Create"} League
                </h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <CreateLeagueForm />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => handleCreateLeagueTrayClosed()}
            className={styles.overlay}
          />
        </>
      )}
    </AnimatePresence>
  );
};
export default CreateLeagueTray;
