import { FaPlus } from "react-icons/fa";
import { useAppDispatch } from "utils/hooks";
import { styles } from "./SimulatorReservation.styles";
import { queueItemTrayAction, queueTrayAction } from "ui-modules/nav/navSlice";

const CreateQueueItemButton = () => {
  const dispatch = useAppDispatch();

  const setCreateNewQueueItemTray = () => {
    dispatch(
      queueTrayAction({
        isOpen: true,
      })
    );
    dispatch(queueItemTrayAction({ isOpen: true }));
  };

  return (
    <>
      <button
        className={styles.createButton}
        onClick={() => setCreateNewQueueItemTray()}
        id="btnOpenCreateQueueItemTray"
        data-testid="btnOpenCreateQueueItemTray"
      >
        <span className="sr-only">Create New Reservation Button</span>
        <FaPlus />
      </button>
    </>
  );
};
export default CreateQueueItemButton;
