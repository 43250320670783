import React from "react";
import styles from "./Header.styles";
import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { useAppDispatch } from "utils/hooks";
import { HiOutlineExternalLink } from "react-icons/hi";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { marketingHubTrayAction } from "ui-modules/nav/navSlice";

const SubMenu: React.FC = () => {
  const { marketingHub } = useFlags();
  const isSidebarCollapsed = useSidebarCollapsed();

  const dispatch = useAppDispatch();

  const isMarketingHubTrayOpen = useSelector(
    (state: RootState) => state.nav?.marketingHubTray?.isOpen
  );

  const setMarketingHubTray = () =>
    dispatch(
      marketingHubTrayAction({
        isOpen: !isMarketingHubTrayOpen,
      })
    );
  return (
    <>
      <div
        className={`${
          isSidebarCollapsed ? "hidden" : ""
        } text-xs font-semibold leading-6 brand-light-green`}
      >
        Resources
      </div>
      <ul className={styles.submenuList}>
        <li key="teamStore">
          <a
            href="https://store.aboutgolf.com"
            className={`${styles.submenuLink} ${
              isSidebarCollapsed ? "items-center justify-center" : null
            }`}
            target="_blank"
            rel="noreferrer"
          >
            <span className={styles.submenuIcon}>S</span>
            <span className={isSidebarCollapsed ? "hidden" : "truncate"}>
              aG Store{" "}
              <HiOutlineExternalLink className="text-gray-400 ml-1 w-4 h-4" />
            </span>
          </a>
        </li>
        {marketingHub && (
          <li key="teamMarketing">
            <button
              type="button"
              className={`${styles.submenuLink} ${
                isSidebarCollapsed ? "items-center justify-center" : null
              }`}
              onClick={() => setMarketingHubTray()}
            >
              <span className={styles.submenuIcon}>M</span>
              <span className={isSidebarCollapsed ? "hidden" : "truncate"}>
                aG Marketing Hub
              </span>
            </button>
          </li>
        )}
      </ul>
    </>
  );
};

export default SubMenu;
