import { HoleType, MatchHoleParticipantResult, ScoringType } from "types/enums";
import { ScorecardHoleElementDto } from "ScorecardModels";

export const scorecardHoleStylesHelper = (
  score: number,
  par: number
): string => {
  if (score === null || score === 0) return "";

  const holeScore = scoreDifference(score, par);

  return scorecardHoleColorHelper[holeScore];
};

function scoreDifference(score: number, par: number) {
  let diff = score - par;

  if (diff > 2) diff = 2;
  if (diff < -3) diff = -3;

  return diff;
}

export function scorecardFrontAndBackHoles(holes: ScorecardHoleElementDto[]) {
  const frontNineHoles = holes.filter(
    (hole) => hole.holeNumber >= 1 && hole.holeNumber <= 9
  );
  const backNineHoles = holes.filter(
    (hole) => hole.holeNumber >= 10 && hole.holeNumber <= 18
  );
  return [frontNineHoles, backNineHoles];
}

export const scorecardHoleColorHelper = {
  [HoleType.ALBATROSS.toString()]: "albatross w-6 h-6 rounded-full",
  [HoleType.EAGLE.toString()]: "eagle w-6 h-6 rounded-full",
  [HoleType.BIRDIE.toString()]: "birdie w-6 h-6 rounded-full",
  [HoleType.PAR.toString()]: "w-6 h-6 ",
  [HoleType.BOGEY.toString()]: "bogey w-6 h-6",
  [HoleType.DOUBLE_BOGEY.toString()]: "doubleBogey w-6 h-6",
};

export function eighteenInScore(totalScore: number, frontNineScore: number) {
  return frontNineScore && frontNineScore > 0
    ? frontNineScore - totalScore
    : totalScore;
}

export const isRoundMatchPlay = (scoringType: ScoringType) => {
  return scoringType === ScoringType.MATCH;
};

export const holeNumberMatchPlayHelper = {
  [MatchHoleParticipantResult.TIED.toString()]: "",
  [MatchHoleParticipantResult.PARTICIPANT_1.toString()]: "size-4 rounded-full bg-brandGreen text-white",
  [MatchHoleParticipantResult.PARTICIPANT_2.toString()]: "size-4 bg-brandYellow text-black",
};

export const runningResultWinnerHelper = {
  [MatchHoleParticipantResult.TIED.toString()]: "text-brandDarkGreen text-[10px] font-normal",
  [MatchHoleParticipantResult.PARTICIPANT_1.toString()]: "text-brandGreen text-[10px] font-normal",
  [MatchHoleParticipantResult.PARTICIPANT_2.toString()]: "text-brandYellow text-[10px] font-normal",
};

export const teamColorHelper = {
  [MatchHoleParticipantResult.PARTICIPANT_1.toString()]: "rounded-full bg-brandGreen",
  [MatchHoleParticipantResult.PARTICIPANT_2.toString()]: "bg-brandYellow",
};
