import {
  timerArrayToMinuteValue,
  timerIsOffOrZero,
  timerStringToTimerArray,
} from "utils/timer/timerHelpers";
import { SimulatorState } from "Models";
import { RoundPaceStatus } from "types/enums";

export function calculateRoundPace(simulatorState: SimulatorState) {
  if (
    !timerIsOffOrZero(
      simulatorState?.timerValue,
      simulatorState?.timerStatus
    ) &&
    simulatorState?.timerValue &&
    simulatorState?.estimatedTimeRemaining
  ) {
    const timeDifference =
      timerArrayToMinuteValue(simulatorState?.timerValue) -
      timerArrayToMinuteValue(
        timerStringToTimerArray(simulatorState?.estimatedTimeRemaining)
      );

    if (timeDifference < 0) {
      return RoundPaceStatus.BEHIND_SCHEDULE;
    } else if (timeDifference > 0 && timeDifference < 2) {
      return RoundPaceStatus.ON_TRACK;
    } else {
      return RoundPaceStatus.AHEAD_OF_SCHEDULE;
    }
  }

  return RoundPaceStatus.OFF;
}

export const roundPaceColorHelper = {
  [RoundPaceStatus.BEHIND_SCHEDULE.toString()]: {
    primaryBg: "bg-red-500",
    secondaryBg: "bg-red-50",
    text: "text-red-500",
  },
  [RoundPaceStatus.OFF.toString()]: {
    primaryBg: "bg-green-500 dark:bg-brandGreen/90",
    secondaryBg: "bg-white",
    text: "text-gray-500",
  },
  [RoundPaceStatus.AHEAD_OF_SCHEDULE.toString()]: {
    primaryBg: "bg-green-500 dark:bg-brandGreen/90",
    secondaryBg: "bg-green-50",
    text: "text-gray-500",
  },
  [RoundPaceStatus.ON_TRACK.toString()]: {
    primaryBg: "bg-yellow-500",
    secondaryBg: "bg-yellow-50",
    text: "text-gray-500",
  },
};

export const simPaceStatusEnumDisplayNameKeyedByString = {
  [RoundPaceStatus[0]]: "Behind Schedule",
  [RoundPaceStatus[1]]: "On Track",
  [RoundPaceStatus[2]]: "Ahead",
  [RoundPaceStatus[3]]: "N/A",
};
