import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedRoundId } from "modules/leagues/leagueSlice";
import { useGetSelectedLeague } from "modules/leagues/leagueHooks";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import CreateGroupForm from "./CreateGroupForm";

const LeagueRoundGroupModal: React.FC<LeagueRoundGroupModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const selectedLeague = useGetSelectedLeague();

  const handleCloseModal = async () => {
    dispatch(
      setSelectedRoundId({
        selectedLeagueRoundId: "",
      })
    );
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.LeagueRoundGroups,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={`${selectedLeague?.name} round groups` || props.title}
      hideFooter={true}
      modalSize="w-full h-full md:w-3/4 2xl:w-1/2 md:h-3/4 2xl:h-1/2"
      body={
        <Row cssClasses="px-8 py-4">
          <CreateGroupForm />
        </Row>
      }
    />
  );
};

type LeagueRoundGroupModalProps = {
  title?: string;
  modalName: string;
};

LeagueRoundGroupModal.defaultProps = {
  title: "Leauge Round Groups",
};

export default LeagueRoundGroupModal;
