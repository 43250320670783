import React, { useCallback, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAuthenticateToken } from "modules/user";
import Login from "./components/Login";
import Header from "components/ui/layout/Header";
import Row from "components/ui/layout/Row";
import Footer from "components/ui/layout/Footer";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import ServiceRequestTray from "components/ui/layout/ServiceRequestTray";
import SettingsContainer from "components/shared/settings/SettingsContainer";
import { Toaster } from "react-hot-toast";
import { connection } from "utils/websockets/websocketMiddleware";
import { HubConnectionState } from "updated-redux-signalr";
import SingleFacilityControlDashboard from "components/control/SingleFacilityControlDashboard";
import HelpDocTray from "components/shared/helpdocs/HelpDocTray";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";
import CountdownTimer from "components/control/dashboard/timers/CountdownTimer";
import {
  LocalStorageItems,
  setLocalStorageValue,
} from "utils/hooks/localStorage";
import Events from "./components/control/events/Events";
import OnlineEventsTray from "components/control/events/OnlineEventsTray";
import LockerUserEditTray from "components/control/events/LockerUserEditTray";
import RoundSettingsTray from "components/shared/settings/RoundSettingsTray";
import SupportCaseTray from "components/shared/support/SupportCaseTray";
import { getJWTUserProperties } from "utils/tokens/tokenHelpers";
import { getCurrentFacility } from "modules/facility/facilitySelectors";
import ScorecardTray from "components/scorecard/ScorecardTray";
import LeftSidebar from "components/ui/layout/LeftSidebar";
import { Redirect } from "react-router";
import MarketingHubTray from "components/control/marketing-hub/MarketingHubTray";
import Reservations from "components/control/simulator-reservations/Reservations";
import Leagues from "components/leagues/Leagues";
import CreateLeagueTray from "components/leagues/CreateLeagueTray";
import LeagueRounds from "components/leagues/rounds/LeagueRounds";
import CreateLeagueRoundTray from "components/leagues/CreateLeagueRoundTray";
import MultiSimManagementTray from "components/control/sim-control/MultiSimManagementTray";
import CreateLeagueRoundGroupTray from "components/leagues/rounds/teams/CreateLeagueRoundTeamTray";
import RexReservationTray from "components/rex-reservations/RexReservationTray";
import QueueTray from "components/control/simulator-reservations/QueueTray";
import { useLDClient } from "launchdarkly-react-client-sdk";

import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import LeagueScorecardSearchTray from "components/leagues/LeagueScorecardSearchTray";

const App: React.FC = () => {
  useAuthenticateToken(true);
  const ldClient = useLDClient();
  const user = useSelector((state: RootState) => state.user);
  const isAuthenticated = user?.isAuthenticated;
  const currentFacility = useSelector((state: RootState) =>
    getCurrentFacility(state)
  );
  const browserId = localStorage.getItem(LocalStorageItems.BROWSERID);

  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const isDarkMode = localStorage.getItem(LocalStorageItems.DARKMODE)
    ? localStorage.getItem(LocalStorageItems.DARKMODE) === "true"
    : prefersDark;

  const startTimedConnection = useCallback(
    async (currentFacilityId) => {
      if (connection) {
        await connection.start();
        await connection.invoke(
          "LinkFacilityBrowserToConnectionId",
          currentFacilityId,
          browserId
        );
      }
      setTimeout(async () => {
        await connection.stop();
        startTimedConnection(currentFacilityId);
      }, 300000);
    },
    [browserId]
  );

  useEffect(() => {
    if (
      isAuthenticated &&
      currentFacility &&
      (connection.state === HubConnectionState.Disconnected ||
        connection.state === HubConnectionState.Disconnecting)
    ) {
      startTimedConnection(currentFacility.id);
    }
  }, [isAuthenticated, startTimedConnection, currentFacility]);

  const simulators = useSelector(getSimulatorsByCurrentFacility);

  useEffect(() => {
    window.document.documentElement.classList.toggle("dark", isDarkMode);
    setLocalStorageValue(
      LocalStorageItems.DARKMODE,
      JSON.stringify(isDarkMode)
    );
  }, [isDarkMode]);

  useEffect(() => {
    const userProperties = getJWTUserProperties();

    if (userProperties && window.pendo && currentFacility) {
      window.pendo.initialize({
        visitor: {
          id:
            userProperties[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
            ],
          email:
            userProperties[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
            ],
          full_name:
            userProperties[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
            ] || "",
        },

        account: {
          id: currentFacility?.id,
        },
      });
    }
  }, [user, currentFacility]);

  useEffect(() => {
    const userProperties = getJWTUserProperties();

    if (userProperties && currentFacility) {
      const context = {
        kind: "user",
        anonymous: false,
        key: userProperties["https://id.about.golf/claims/v2/userId"],
        name:
          userProperties[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
          ],
        email:
          userProperties[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
          ],
        facility: currentFacility.id,
      };

      ldClient?.identify(context, undefined, () => {
        showCustomNotification(
          "Welcome to Clubhouse!",
          GenericNotificationType.SUCCESS,
          "👋"
        );
      });
    }
  }, [user, currentFacility, ldClient]);

  return (
    <Router>
      <div className="w-full modal-bg min-h-screen">
        <>
          <Toaster
            position="bottom-left"
            containerClassName="z-9999"
            toastOptions={{ style: { zIndex: 9999 } }}
          />
        </>
        {isAuthenticated && <LeftSidebar />}
        <Row cssClasses="background-gray dark:bg-zinc-900" id="app">
          {isAuthenticated ? (
            <Row cssClasses="">
              <Header />
              <Row
                cssClasses={` justify-center items-center dark:bg-zinc-900/90 rounded-tl-lg`}
              >
                <Switch>
                  <Route
                    path="/dashboard"
                    component={SingleFacilityControlDashboard}
                  />
                  <Route
                    path="/settings/:active_tab?"
                    component={SettingsContainer}
                  />
                  <Route path="/events" component={Events} />
                  <Route path="/leagues" component={Leagues} exact />
                  <Route path="/leagues/rounds" component={LeagueRounds} />
                  <Route path="/reservations" component={Reservations} />
                  <Route path="/" exact>
                    <Redirect to="/dashboard" />
                  </Route>
                </Switch>
                <ScorecardTray />
                <LeagueScorecardSearchTray />
                <ServiceRequestTray />
                <RoundSettingsTray />
                <LockerUserEditTray />
                <OnlineEventsTray />
                <HelpDocTray />
                <RexReservationTray />
                <QueueTray />
                <SupportCaseTray />
                <MarketingHubTray />
                <CreateLeagueTray />
                <CreateLeagueRoundTray />
                <MultiSimManagementTray />
                <CreateLeagueRoundGroupTray />
                <Footer />
                {simulators?.map((sim) => (
                  <CountdownTimer simulatorId={sim.id} key={sim.id} />
                ))}
              </Row>
            </Row>
          ) : (
            <Row cssClasses="w-full h-100">
              <Login />
            </Row>
          )}
        </Row>
      </div>
    </Router>
  );
};
export default App;
