import Row from "components/ui/layout/Row";
import MultiSimControlModal from "components/control/dashboard/MultiSimControlModal";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useAppDispatch } from "utils/hooks";
import { getSimulatorStatesForArrayOfSims } from "modules/simulator-state/simulatorStateSelectors";
import { RootState } from "StoreTypes";
import { isModalOpen, ModalType } from "utils/modals/modalHelpers";
import DashboardSimulatorsContainer from "components/control/dashboard/DashboardSimulatorsContainer";
import GridListViewToggle from "components/control/dashboard/GridListViewToggle";
import {
  LocalStorageItems,
  localStorageValueToBoolean,
} from "utils/hooks/localStorage";
import { AnimatePresence, motion } from "framer-motion";
import { useSidebarCollapsed } from "utils/ui/uiHelpers";
import {
  BreakPoints,
  desktopReservationSidebarVariants,
  useMediaQuery,
} from "utils/animations/animationHelper";
import QueueList from "./simulator-reservations/QueueList";
import QueueTrayButton from "components/ui/layout/QueueTrayButton";
import { modalAction } from "ui-modules/modals/modalSlice";
import { BiSelectMultiple } from "react-icons/bi";

const SingleFacilityControlDashboard: React.FC<SingleDashboardViewProps> = () => {
  const dispatch = useAppDispatch();
  const simulators = useSelector(getSimulatorsByCurrentFacility);
  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);
  const [selectedSims, setSelectedSims] = useState<string[]>([]);
  const [allSelected] = useState(false);
  const [listView, setListView] = useState(
    localStorage.getItem(LocalStorageItems.DASHBOARDGRIDORLISTVIEW)
      ? localStorageValueToBoolean(
          localStorage.getItem(LocalStorageItems.DASHBOARDGRIDORLISTVIEW)
        )
      : false
  );

  const showQueueOnDashboard = useSelector(
    (state: RootState) => state.nav?.queue?.showOnDashboard
  );

  const simStates = useSelector((state: RootState) =>
    getSimulatorStatesForArrayOfSims(state, simulators)
  );

  const setMultiSimManagementTray = () => {
    dispatch(
      modalAction({
        isOpen: true,
        modalName: ModalType.MultiSimControlModal,
      })
    );
  };

  const selectSim = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    const items = selectedSims;

    if (selectedSims?.includes(selectedId)) {
      const index = items.indexOf(selectedId);
      if (index !== -1) {
        items.splice(index, 1);
        setSelectedSims([...items]);
      }
    } else {
      setSelectedSims([...items, selectedId]);
    }
  };

  useEffect(() => {
    localStorage.setItem(
      LocalStorageItems.DASHBOARDGRIDORLISTVIEW,
      JSON.stringify(listView)
    );
  }, [listView]);

  return (
    <Row
      cssClasses={`Dashboard ${simStates.length} w-full ${
        isDesktop ? (isSidebarCollapsed ? "pl-24" : "pl-64") : "pl-0"
      }`}
    >
      <div className="w-full bg-white dark:bg-zinc-800">
        <div className="background-gray dark:bg-zinc-900 h-3 rounded-tl-2xl border-t border-l border-gray-200 dark:border-zinc-900 "></div>
      </div>
      <Row>
        {isModalOpen(ModalType.MultiSimControlModal) && (
          <MultiSimControlModal
            title="Sim Control"
            modalName={`multiSimControlModal`}
            simulatorIds={selectedSims}
          />
        )}
        <Row cssClasses={`flex flex-col md:flex-row min-h-screen w-full `}>
          <AnimatePresence>
            <motion.div
              className={
                showQueueOnDashboard
                  ? "w-full md:w-2/3 sm:mx-6 my-6"
                  : "w-full m-1 sm:m-4"
              }
              key="singleFacilityControlDashboard"
            >
              <div className="md:flex md:items-center md:justify-between px-2 pb-4">
                <div className="flex-1 min-w-0">
                  <div className="flex align-left flex-row">
                    <div>
                      <GridListViewToggle
                        listView={listView}
                        setListView={setListView}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex md:mt-0 md:ml-4">
                  <button
                    onClick={() => setMultiSimManagementTray()}
                    className="text-brandGreen hover:text-brandGray py-1 mt-1 poppins p-2 text-xs dark:text-gray-200 disabled:cursor-default disabled:bg-gray-200"
                  >
                    <BiSelectMultiple className="size-5 mr-1" /> Manage Sims
                  </button>
                  <QueueTrayButton />
                </div>
              </div>
              <Row cssClasses="">
                <DashboardSimulatorsContainer
                  listView={listView}
                  simulators={simulators}
                  allSelected={allSelected}
                  selectSim={selectSim}
                />
              </Row>
            </motion.div>
            <motion.div
              variants={desktopReservationSidebarVariants}
              animate={showQueueOnDashboard ? "open" : "closed"}
              className={showQueueOnDashboard ? "w-full md:w-1/3" : "hidden"}
            >
              {showQueueOnDashboard && <QueueList dashboardView={true} />}
            </motion.div>
          </AnimatePresence>
        </Row>
      </Row>
    </Row>
  );
};

export default SingleFacilityControlDashboard;

type SingleDashboardViewProps = {
  facilityId?: string;
  facilityName?: string;
};

SingleFacilityControlDashboard.defaultProps = {};
