import { useState } from "react";
import { motion } from "framer-motion";
import { customStyles } from "utils/ui/uiHelpers";
import DefaultRoundSettings from "./DefaultRoundSettings";
import CreateRoundConfig from "components/control/dashboard/round-configuration-manage/CreateRoundConfiguration";
import DeleteRoundConfig from "components/control/dashboard/round-configuration-manage/DeleteRoundConfiguration";
import Select from "react-select";

const SettingsContainer: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState("");

  const options = [
    { value: "round-defaults", label: "Round Setup Defaults" },
    { value: "saved-rounds", label: "Create Round Setup" },
    { value: "delete-rounds", label: "Delete Saved Setup" },
  ];

  const handleChange = (e: { label: string; value: string }) => {
    setSelectedValue(e.value);
  };

  return (
    <>
      <div className="my-4 px-8 md:px-0">
        <Select
          name="autoContinue"
          closeMenuOnSelect={true}
          isMulti={false}
          styles={customStyles}
          options={options}
          onChange={handleChange}
          placeholder="Select settings"
        />
      </div>
      <div className="w-full">
        <motion.div>
          {selectedValue === "round-defaults" ? <DefaultRoundSettings /> : null}
          {selectedValue === "saved-rounds" ? <CreateRoundConfig /> : null}
          {selectedValue === "delete-rounds" ? <DeleteRoundConfig /> : null}
        </motion.div>
      </div>
    </>
  );
};

export default SettingsContainer;
