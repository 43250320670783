import { RootState } from "StoreTypes";
import { useSelector } from "react-redux";
import { RoundConfiguration } from "Models";
import { FacilityNormalized } from "EntityTypes";
import { styles } from "./RoundConfigurationManagement.styles";
import { getCurrentFacilityId } from "modules/facility/facilitySelectors";
import { useFacilityRoundConfigurations } from "modules/round-configuration/roundConfigurationHooks";
import Row from "components/ui/layout/Row";
import RoundConfigDeleteRow from "./RoundConfigurationDeleteRow";

const DeleteRoundConfig: React.FC = () => {
  const currentFacilityId = useSelector((state: RootState) =>
    getCurrentFacilityId(state)
  );
  const [facilityRoundConfigs] = useFacilityRoundConfigurations(
    currentFacilityId
  );
  const currentFacility: FacilityNormalized = useSelector(
    (state: RootState) => state.entities.facilities?.[currentFacilityId]
  );
  const roundConfigs = facilityRoundConfigs as Array<RoundConfiguration>;

  return (
    <Row cssClasses={styles.innerContainer}>
      <Row cssClasses={styles.row}>
        <Row>
          <Row>Saved Round Configurations</Row>
          <Row cssClasses={`${styles.text} mb-4`}>
            Here are the current configs for {currentFacility?.name}
          </Row>
        </Row>
      </Row>
      <Row cssClasses={styles.fieldColumn}>
        {roundConfigs.map((config) => {
          return <RoundConfigDeleteRow config={config} key={config.id} />;
        })}
      </Row>
    </Row>
  );
};
export default DeleteRoundConfig;
