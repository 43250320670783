import { styles } from "./Leagues.styes";
import { LeagueRosterEntryDto } from "Models";

type LeagueScorecardProps = {
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
  leaderboardPlayer: LeagueRosterEntryDto;
};

const LeagueScorecardPlayer: React.FC<LeagueScorecardProps> = (props) => {
  const { leaderboardPlayer } = props;

  return (
    <div onClick={(e) => e.stopPropagation()} className={`w-full`}>
      <div
        key={leaderboardPlayer.userGuid}
        className={`${styles.modalResultsRow}dark:text-gray-200
        `}
      >
        <div className="col-span-1"></div>
        <div className="col-span-3">
          {leaderboardPlayer.firstName} {leaderboardPlayer.lastName}
        </div>
        <div className="hidden md:inline-block col-span-2"></div>
        <div className="hidden md:inline-block col-span-2"></div>

        <div className="col-span-2 pl-2">
          <span className="border-1 border-transparent rounded-lg w-20 text-xs font-normal p-1"></span>
        </div>

        <div className="col-span-1"></div>
        <div className="col-span-1 items-center text-right pr-2">
          <div className={styles.showScorecardButton}></div>
        </div>
      </div>
    </div>
  );
};

export default LeagueScorecardPlayer;
