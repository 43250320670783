import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { roundSettingsAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { styles } from "../../ui/layout/Header.styles";
import { disableBackgroundScroll } from "utils/ui/uiHelpers";
import GearIcon from "components/svg/GearIcon";

const RoundSettingsTrayButton = () => {
  const dispatch = useAppDispatch();

  const isRoundSettingsTrayOpen = useSelector(
    (state: RootState) => state.nav?.roundSettings?.isOpen
  );

  const setRoundSettingsTray = () => {
    disableBackgroundScroll();
    dispatch(roundSettingsAction({ isOpen: !isRoundSettingsTrayOpen }));
  };

  return (
    <div className="text-center">
      <button
        type="button"
        onClick={() => setRoundSettingsTray()}
        id="headerRoundSettingsButton"
        data-tip="Round Settings"
        data-for="headerButtonsTooltip"
      >
        <GearIcon class={styles.buttonIcon} />
      </button>
      <span className={styles.buttonText}>Rounds</span>
    </div>
  );
};
export default RoundSettingsTrayButton;
