import { AppQueryConfig } from "QueryTypes";
import { GameScorecard, ScorecardPayload } from "ScorecardModels";
import { reduxQueryDefaultOptions } from "utils/queries/queryHelpers";

const urls = {
  scorecardLookup(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/scorecards`;
  },
};

export const getScorecardsBySearch = (
  facilityId: string,
  body: ScorecardPayload
): AppQueryConfig => ({
  url: urls.scorecardLookup(),
  queryKey: `getScorecardsByFacilityId:${body.userIds}-${body.startDate}-${body.endDate}`,
  options: {
    ...reduxQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: GameScorecard[]) => {
    let obj: { [id: string]: GameScorecard } = {};

    responseJson?.map((scorecard) => {
      return (obj[scorecard?.saveGameId] = scorecard);
    });

    return {
      scorecards: {
        byFacilityId: {
          [facilityId]: obj,
        },
      },
    };
  },
  update: {
    scorecards: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const makeDismissAllScorecardsMutation = (
  facilityId: string
): AppQueryConfig => ({
  url: urls.scorecardLookup(),
  options: { ...reduxQueryDefaultOptions(), method: "DELETE" },
  optimisticUpdate: {
    scorecards: (oldValue) => {
      if (facilityId) delete oldValue?.byFacilityId[facilityId];
      return oldValue;
    },
  },
});
