import {
  EventHandicapMode,
  EventMensTeeType,
  EventPinType,
  EventsWindSpeed,
  EventWomensTeeType,
  Weather,
  WindDirection,
  AutoConcede,
  AutoContinue,
  AutoGimme,
  RoundType,
  Gender,
  GimmeDistance,
  HandicapMode,
  HandicapPercentage,
  PinType,
  Ranges,
  ScoringType,
  SimStatus,
  TeeType,
  WindSpeed,
  Rounds,
  EventCompetitionType,
  Ground,
  EventGimmeDistance,
  Sky,
  TimeOfDay,
  LeagueType,
  LeagueScoring,
  LeagueHandicapMethod,
  LeagueTeamSize,
  LeagueRoundCompetitionType,
  TeamPlayFormat,
  MatchPlayAssignment,
  MatchPlayStyle,
  MatchPlayTeamStyle,
  LeagueHandicapMode,
} from "types/enums";

const stringIsNumber = (value: any) => isNaN(Number(value)) === false;

export function titleCase(str: string) {
  return str
    ? str
        .toString()
        .replaceAll("_", " ")
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(" ")
    : "";
}

export function enumNameToLabel(str: string) {
  return (
    str.toString().charAt(0).toUpperCase() +
    str
      .slice(1)
      .replace(/([A-Z])/g, " $1")
      .trim()
  );
}

export function enumToArray(enumme: any) {
  return Object.keys(enumme)
    .filter(stringIsNumber)
    .map((key) => enumme[key]);
}

export function roundConfigEnumToReactSelectOptions(
  enumme: any,
  format: boolean = true
) {
  const arrayObjects = [];

  for (const [propertyKey, propertyValue] of Object.entries(enumme)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    arrayObjects.push({
      value: propertyValue,
      label: format
        ? titleCase(propertyKey as string)
        : (propertyKey as string),
    });
  }

  return arrayObjects;
}

export const greenStimpOptions = [
  { label: "8", value: 8 },
  { label: "8.5*", value: 8.5 },
  { label: "9", value: 9 },
  { label: "9.5", value: 9.5 },
  { label: "10", value: 10 },
  { label: "10.5", value: 10.5 },
  { label: "11", value: 11 },
  { label: "11.5", value: 11.5 },
  { label: "12", value: 12 },
  { label: "12.5", value: 12.5 },
  { label: "13", value: 13 },
  { label: "13.5", value: 13.5 },
  { label: "14", value: 14 },
];

export const trueFalseOptions = [
  { label: "On", value: true },
  { label: "Off", value: false },
];

export const roundConfigEnumsAsReactSelectOptions = {
  autoConcede: roundConfigEnumToReactSelectOptions(AutoConcede),
  autoContinue: roundConfigEnumToReactSelectOptions(AutoContinue),
  autoGimme: roundConfigEnumToReactSelectOptions(AutoGimme),
  gameType: roundConfigEnumToReactSelectOptions(RoundType),
  gender: roundConfigEnumToReactSelectOptions(Gender),
  gimmeDistance: roundConfigEnumToReactSelectOptions(GimmeDistance),
  eventGimmeDistance: roundConfigEnumToReactSelectOptions(EventGimmeDistance),
  handicapMode: roundConfigEnumToReactSelectOptions(HandicapMode),
  leagueHandicapMode: roundConfigEnumToReactSelectOptions(LeagueHandicapMode),
  handicapPercentage: roundConfigEnumToReactSelectOptions(HandicapPercentage),
  pinType: roundConfigEnumToReactSelectOptions(PinType),
  ranges: roundConfigEnumToReactSelectOptions(Ranges),
  scoringType: roundConfigEnumToReactSelectOptions(ScoringType),
  teeType: roundConfigEnumToReactSelectOptions(TeeType),
  windSpeed: roundConfigEnumToReactSelectOptions(WindSpeed),
  rounds: roundConfigEnumToReactSelectOptions(Rounds),
  eventCompetitionType: roundConfigEnumToReactSelectOptions(
    EventCompetitionType
  ),
  eventMensTee: roundConfigEnumToReactSelectOptions(EventMensTeeType),
  eventWomensTee: roundConfigEnumToReactSelectOptions(EventWomensTeeType),
  eventPinType: roundConfigEnumToReactSelectOptions(EventPinType),
  groundConditions: roundConfigEnumToReactSelectOptions(Ground),
  windDirection: roundConfigEnumToReactSelectOptions(WindDirection, false),
  eventsWindSpeed: roundConfigEnumToReactSelectOptions(EventsWindSpeed),
  eventsHandicapMode: roundConfigEnumToReactSelectOptions(EventHandicapMode),
  weather: roundConfigEnumToReactSelectOptions(Weather),
  skyConditions: roundConfigEnumToReactSelectOptions(Sky),
  timeOfDay: roundConfigEnumToReactSelectOptions(TimeOfDay),
  stimpSetting: greenStimpOptions,
  mulligan: trueFalseOptions,
  autoDrop: trueFalseOptions,
  leagueType: roundConfigEnumToReactSelectOptions(LeagueType),
  leagueRoundCompetitionType: roundConfigEnumToReactSelectOptions(
    LeagueRoundCompetitionType
  ),
  leagueScoring: roundConfigEnumToReactSelectOptions(LeagueScoring),
  leagueHandicap: roundConfigEnumToReactSelectOptions(LeagueHandicapMethod),
  leagueTeamSize: roundConfigEnumToReactSelectOptions(LeagueTeamSize),
  teamPlayFormat: roundConfigEnumToReactSelectOptions(TeamPlayFormat),
  matchPlayAssignment: roundConfigEnumToReactSelectOptions(MatchPlayAssignment),
  matchPlayStyle: roundConfigEnumToReactSelectOptions(MatchPlayStyle),
  matchPlayTeamStyle: roundConfigEnumToReactSelectOptions(MatchPlayTeamStyle),
};

export const serviceRequestEnumDisplayName = (str: string) => {
  const name = str.slice(11).toLowerCase();
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const simStatusEnumDisplayNameKeyedByString = {
  [SimStatus[0]]: "Off",
  [SimStatus[1]]: "Starting",
  [SimStatus[2]]: "Entering Setup",
  [SimStatus[3]]: "Setup Idle",
  [SimStatus[4]]: "Exiting Setup",
  [SimStatus[5]]: "Entering Range",
  [SimStatus[6]]: "Range Taking Shot",
  [SimStatus[7]]: "Range Idle",
  [SimStatus[8]]: "Range Exit",
  [SimStatus[9]]: "Round Starting",
  [SimStatus[10]]: "Round Taking Shot",
  [SimStatus[11]]: "Round Idle",
  [SimStatus[12]]: "Hole Ending",
  [SimStatus[13]]: "Round Ending",
  [SimStatus[14]]: "Sim Exiting",
  [SimStatus[15]]: "Timer Pausing",
  [SimStatus[16]]: "Timer Unpausing",
};

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string
): keyof T | null {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}
