import { AppQueryConfig } from "QueryTypes";
import { leagueQueryDefaultOptions } from "./leagueHelpers";
import {
  LeagueLeaderboardDto,
  PointScorecardsDto,
  RankedCompetitionResult,
  RankedScorecardsDto,
  RoundLeaderboardDto,
  RoundLeaderboardPlayerDto,
  RoundPar,
  ScorecardToRoundPayload,
  ScorecardValues,
} from "Models";
import axios from "axios";
import {
  AssignAndRankScorecardsRequestDto,
  GameScorecard,
} from "ScorecardModels";
import { HandicapMode } from "types/enums";

const urls = {
  leagueRoundLeaderboardByRound(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/scorecards`;
  },
  leagueRankedScorecards(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/score`;
  },
  leagueRoundPlayerScorecards(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/scorecards/search`;
  },
  leagueAutoAssignRoundPlayerScorecards(
    facilityId: string,
    leagueId: string,
    roundId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/scorecards/scores`;
  },
  leagueUpdateRoundScorecard(
    facilityId: string,
    leagueId: string,
    roundId: string,
    scorecardId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/round/${roundId}/scorecard/${scorecardId}`;
  },
  leagueLeaderboardByRound(facilityId: string, leagueId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/leaderboard`;
  },
};

export const getRoundLeaderboardByRoundId = (
  facilityId: string,
  leagueId: string,
  roundId: string
): AppQueryConfig => ({
  url: urls.leagueRoundLeaderboardByRound(facilityId, leagueId, roundId),
  queryKey: `getRoundLeaderboardByRoundId:${facilityId}-${leagueId}-${roundId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: RoundLeaderboardDto) => {
    return {
      leagueRoundLeaderboard: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueRoundLeaderboard: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const putAssignScorecardToRound = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: ScorecardToRoundPayload
): AppQueryConfig => ({
  url: urls.leagueRoundLeaderboardByRound(facilityId, leagueId, roundId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  body,
  transform: (responseJson: RoundLeaderboardDto) => {
    return {
      leagueRoundLeaderboard: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueRoundLeaderboard: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const putAutoAssignScorecardsToRound = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: AssignAndRankScorecardsRequestDto
): AppQueryConfig => ({
  url: urls.leagueAutoAssignRoundPlayerScorecards(
    facilityId,
    leagueId,
    roundId
  ),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  body,
  transform: (responseJson: RoundLeaderboardDto) => {
    return {
      leagueRoundLeaderboard: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueRoundLeaderboard: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const getRankedRoundScorecards = (
  facilityId: string,
  leagueId: string,
  roundId: string
): AppQueryConfig => ({
  url: urls.leagueRankedScorecards(facilityId, leagueId, roundId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: RankedScorecardsDto) => {
    return {
      leagueRoundRankedScorecards: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueRoundRankedScorecards: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const putRankRoundScorecards = (
  facilityId: string,
  leagueId: string,
  roundId: string
): AppQueryConfig => ({
  url: urls.leagueRankedScorecards(facilityId, leagueId, roundId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  transform: (responseJson: RankedScorecardsDto) => {
    return {
      leagueRoundRankedScorecards: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueRoundRankedScorecards: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const getLeagueRoundPlayerScorecards = async (
  facilityId: string,
  leagueId: string,
  roundId: string
) => {
  const { data } = await axios.get(
    urls.leagueRoundPlayerScorecards(facilityId, leagueId, roundId),
    {
      headers: {
        ...leagueQueryDefaultOptions().headers,
      },
    }
  );
  return data;
};

export const getLeagueScorecardsByRound = (
  facilityId: string,
  leagueId: string,
  roundId: string
): AppQueryConfig => ({
  url: urls.leagueRoundPlayerScorecards(facilityId, leagueId, roundId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: GameScorecard[]) => {
    let obj: { [id: string]: GameScorecard } = {};

    responseJson?.map((scorecard) => {
      return (obj[scorecard?.saveGameId] = scorecard);
    });

    return {
      scorecards: {
        byFacilityId: {
          [facilityId]: obj,
        },
      },
    };
  },
  update: {
    scorecards: (oldValue, newValue) => ({
      ...oldValue,
      byFacilityId: {
        ...oldValue?.byFacilityId,
        ...{
          [facilityId]: {
            ...oldValue?.byFacilityId?.[facilityId],
            ...newValue.byFacilityId[facilityId],
          },
        },
      },
    }),
  },
});

export const patchApplyPointsToScorecards = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  body: PointScorecardsDto
): AppQueryConfig => ({
  url: urls.leagueRankedScorecards(facilityId, leagueId, roundId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PATCH",
  },
  body,
  transform: (responseJson: RankedCompetitionResult) => {
    return {
      leagueRoundRankedScorecards: {
        byRoundId: {
          [roundId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueRoundRankedScorecards: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const putUpdateRoundScorecard = (
  facilityId: string,
  leagueId: string,
  roundId: string,
  scorecardId: string,
  body: ScorecardValues
): AppQueryConfig => ({
  url: urls.leagueUpdateRoundScorecard(
    facilityId,
    leagueId,
    roundId,
    scorecardId
  ),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  body,
  transform: (responseJson: {
    player: RoundLeaderboardPlayerDto;
    roundLeaderboardId: string;
    roundId: string;
    selectedHolesPar: RoundPar;
    wholeCoursePar: RoundPar;
    handicapMode: HandicapMode;
  }) => {
    let updatedLeagueRoundLeaderboardPlayer = {
      roundId: responseJson.roundId,
      roundLeaderboardId: responseJson.roundLeaderboardId,
      selectedHolesPar: responseJson.selectedHolesPar,
      wholeCoursePar: responseJson.wholeCoursePar,
      players: [responseJson.player],
    };

    return {
      leagueRoundLeaderboard: {
        byRoundId: {
          [roundId]: updatedLeagueRoundLeaderboardPlayer,
        },
      },
    };
  },
  update: {
    leagueRoundLeaderboard: (oldValue, newValue) => ({
      ...oldValue,
      byRoundId: {
        ...oldValue?.byRoundId,
        ...{
          [roundId]: {
            ...oldValue?.byRoundId?.[roundId],
            ...newValue.byRoundId[roundId],
          },
        },
      },
    }),
  },
});

export const putUpdateRoundScorecardAxios = async (
  facilityId: string,
  leagueId: string,
  roundId: string,
  scorecardId: string,
  body: ScorecardValues
): Promise<any> => {
  return await axios
    .put(
      urls.leagueUpdateRoundScorecard(
        facilityId,
        leagueId,
        roundId,
        scorecardId
      ),
      body,
      leagueQueryDefaultOptions()
    )
    .then(async (res) => {
      return {
        status: res?.status,
        data: res?.data,
      };
    })
    .catch((error) => {
      return { error: error?.response, status: error?.response?.status };
    });
};

export const getLeagueLeaderboardByLeagueId = (
  facilityId: string,
  leagueId: string
): AppQueryConfig => ({
  url: urls.leagueLeaderboardByRound(facilityId, leagueId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: LeagueLeaderboardDto) => {
    return {
      leagueLeaderboard: {
        byLeagueId: {
          [leagueId]: responseJson,
        },
      },
    };
  },
  update: {
    leagueLeaderboard: (oldValue, newValue) => ({
      ...oldValue,
      byLeagueId: {
        ...oldValue?.byLeagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});
