export const styles = {
  simCardContainer: [
    "shadow-lg",
    "w-full",
    "h-full",
    "text-gray-900",
    " dark:bg-zinc-800 ",
    "dark:border",
    "dark:border-white",
    "dark:text-gray-200",
    "rounded-t-xl",
    "sm:rounded-xl",
  ].join(" "),
  simHeaderContainer: [
    "border-gray-300 border-b dark:bg-zinc-800 rounded-tl-lg rounded-tr-lg",
  ],
  simHeaderNameContainer: ["flex w-full border-1"].join(" "),
  simHeaderName: ["w-2/3 p-3 ml-4 text-left font-semibold m-auto"].join(" "),
  checked: ["border-4 border-green-500"].join(" "),
  unchecked: ["border-4 border-white-400"].join(" "),
  active: ["text-black bg-white"].join(" "),
  inactive: ["text-gray-400 bg-gray-200"].join(" "),
  checkbox: ["mr-2 mb-1 border-gray-400 rounded-sm disabled:bg-gray-300"].join(
    " "
  ),
  checkboxLabel: ["brand-heading text-md md:text-lg dark:text-gray-200"].join(
    " "
  ),
  commandButtonContainer: ["w-1/3 text-right rounded-tr-lg"].join(" "),
  simNameContainer: [
    "py-4 px-4 sm:py-6 sm:px-6 dark:bg-zinc-800 text-gray-500 dark:text-gray-200 h-52",
  ].join(" "),
  simStatus: ["text-xl md:text-2xl text-gray-500 dark:text-gray-200"].join(" "),
  timerStatus: [
    "text-xl md:text-2xl text-gray-500 dark:text-gray-200 float-right",
  ].join(" "),
  simulatorIsOffContainer: [
    "absolute h-full w-full text-white font-semibold flex flex-col justify-center items-center rounded-xl z-9 bg-opacity-50",
  ].join(" "),
  simulatorIsOffOverlay: [
    "absolute h-full w-full bg-white/5 backdrop-blur-sm dark:bg-zinc-700/9 rounded-xl",
  ].join(" "),
  simulatorIsOffContent: [
    "z-9999 text-center bg-gray-800  dark:bg-zinc-800  dark:text-gray-200 rounded-xl px-4 py-1 text-sm text-white poppins",
  ].join(" "),
  simulatorIsOffIcon: [
    "fas fa-exclamation-triangle block text-gray-300 text-2xl text-md",
  ].join(" "),
  simulatorIsOffButton: [
    "bg-gray-300 border-2 rounded-xl px-2 py-1 mt-4 border-gray-400 opacity-100 text-sm shadow-md",
  ].join(" "),
  scorecardTextButton: [
    "text-lg mx-1 my-2- text-gray-400 hover:text-gray-600 leading-3",
  ].join(" "),
  scorecardImageButton: [
    "text-lg my-2- text-gray-400 hover:text-gray-600 leading-3",
  ].join(" "),
  simSendMessageContainer: ["SimSendMessage flex flex-col flex-auto "].join(
    " "
  ),
  simSendMessage: ["text-gray-800 font-semibold dark:text-gray-200 pb-2"].join(
    " "
  ),
  simSendMessageTextbox: [
    "p-3 my-1 bg-white dark:bg-zinc-900 border-2 border-gray-200 rounded-xl shadow-sm h-20 text-sm dark:text-gray-50",
  ].join(" "),
  simSendMessageButtonContainer: [
    "flex flex-col sm:flex-row items-left sm:space-x-5 pt-2",
  ].join(" "),
  simSendMessageButton: [
    "text-sm border shadow rounded border-gray-300 bg-brandGreen  dark:bg-zinc-800  text-white px-2 py-1 poppins",
  ].join(" "),
  simSendMessageIcon: ["fas fa-comment-dots mr-2"].join(" "),
  simUnlockButton: [
    "dark:bg-gray-900 dark:text-gray-200 w-1/2 h-full inline font-semibold text-sm text-gray-500 bg-white hover:bg-gray-100 rounded-tr-lg hover:shadow-inner float-right disabled:cursor-not-allowed disabled:bg-gray-200 animate-pulse items-center text-center",
  ].join(" "),
  simUnlockText: [
    "hidden lg:inline command-button overflow-hidden animate-pulse",
  ].join(" "),
  simLockButton: [
    "dark:bg-gray-900 dark:text-gray-200 w-1/2 h-full inline pr-2 font-semibold text-sm text-center text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-200 hover:shadow-inner disabled:cursor-not-allowed disabled:bg-gray-200",
  ].join(" "),
  simLockText: ["hidden lg:inline command-button overflow-hidden"].join(" "),
  ShowLoadingAnimation: ["w-1/2 text-gray-700 font-semibold text-center"].join(
    " "
  ),
  showListAnimation: [
    "w-12 text-gray-700 font-semibold text-center text-xs",
  ].join(" "),
  listViewContainer: ["relative inline w-24"].join(" "),
};
export default styles;
