import { SimulatorReservation } from "Models";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  useDeleteSimulatorReservations,
  useSendSimulatorReservationToSim,
} from "modules/simulator-reservations/simulatorReservationHooks";
import { useEffect } from "react";
import {
  queueItemTrayAction,
  queueTrayAction,
  simulatorReservationTrayAction,
} from "ui-modules/nav/navSlice";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { useAppDispatch } from "utils/hooks";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import { styles } from "./SimulatorReservation.styles";
import { useSimulatorState } from "modules/simulator-state/simulatorStateHooks";
import { simulatorStateIsActive } from "modules/simulator-state/simulatorStateHelpers";
import { FaEdit, FaPaperPlane, FaTrash } from "react-icons/fa";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";
import { setSelectedQueueItem } from "ui-modules/queue/queueSlice";

type OwnProps = {
  simulatorReservation: SimulatorReservation;
};

const SimulatorReservationDetails: React.FC<OwnProps> = (props) => {
  const dispatch = useAppDispatch();
  const { simulatorReservation } = props;
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const roundInfo = simulatorReservation.roundConfiguration?.roundInfo;

  const [simulatorState] = useSimulatorState(simulatorReservation.simulatorId);
  const stateIsActive = simulatorStateIsActive(simulatorState);

  const [
    deleteSimulatorReservationState,
    deleteSimulatorReservation,
  ] = useDeleteSimulatorReservations(
    simulatorReservation.id,
    currentFacilityId
  );
  const [
    sendSimulatorReservationState,
    sendSimulatorReservation,
  ] = useSendSimulatorReservationToSim(simulatorReservation.id);

  const handleDeleteSimulatorReservation = () => {
    if (window.confirm("Do you want to delete this reservation?")) {
      deleteSimulatorReservation();
      pendoTrackBasicEvent(
        ClubhouseEvents.DELETE_RESERVATION,
        simulatorReservation.id
      );
    }
  };

  const handleEditSimulatorReservation = (queueItemId: string) => {
    dispatch(
      setSelectedQueueItem({
        selectedQueueItem: queueItemId,
      })
    );
    dispatch(queueItemTrayAction({ isOpen: true }));
    dispatch(queueTrayAction({ isOpen: true }));
    pendoTrackBasicEvent(
      ClubhouseEvents.EDIT_RESERVATION,
      simulatorReservation.id
    );
  };

  const handleSendSimulatorReservation = (reservationId: string) => {
    sendSimulatorReservation(reservationId);
    pendoTrackBasicEvent(ClubhouseEvents.SEND_TO_SIM, simulatorReservation.id);
  };

  useEffect(() => {
    if (deleteSimulatorReservationState.isFinished) {
      showCustomNotification(
        "Simulator reservation deleted",
        GenericNotificationType.SUCCESS
      );
    }
  }, [deleteSimulatorReservationState.isFinished]);

  useEffect(() => {
    if (sendSimulatorReservationState) {
      if (sendSimulatorReservationState.isFinished) {
        showCustomNotification(
          "Reservation has been sent to sim",
          GenericNotificationType.SUCCESS
        );
        dispatch(simulatorReservationTrayAction({ isOpen: false }));
      }
      if (sendSimulatorReservationState.isPending) {
        showCustomNotification("Reservation is loading...");
      }
    }
  }, [sendSimulatorReservationState, dispatch]);

  const booleanList = {
    options: [
      { value: false, label: "Off" },
      { value: true, label: "On" },
    ],
  };

  return (
    <table className="table-auto w-full text-xs">
      <tbody>
        <tr>
          <td>Auto Drop:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              booleanList.options.find(
                (option) => option.value === roundInfo?.autoDrop
              )?.label
            }
          </td>
        </tr>
        <tr>
          <td>Auto Concede:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.autoConcede.find(
                (option) => option.value === roundInfo?.autoConcede
              )?.label
            }
          </td>
        </tr>
        <tr>
          <td>Auto Gimme:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.autoGimme.find(
                (option) => option.value === roundInfo?.autoGimmes
              )?.label
            }
          </td>
        </tr>
        <tr>
          <td>Gimme Distance:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.gimmeDistance.find(
                (option) => option.value === roundInfo?.gimmeDistance
              )?.label
            }
          </td>
        </tr>
        <tr>
          <td>Handicap:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.handicapMode.find(
                (option) => option.value === roundInfo?.handicapMode
              )?.label
            }
          </td>
        </tr>
        <tr>
          <td>Mulligans:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              booleanList.options.find(
                (option) => option.value === roundInfo?.mulligans
              )?.label
            }
          </td>
        </tr>
        <tr>
          <td>Pin Type:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.pinType.find(
                (option) => option.value === roundInfo?.pinType
              )?.label
            }
          </td>
        </tr>
        <tr>
          <td>Scoring Type:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.scoringType.find(
                (option) => option.value === roundInfo?.scoringType
              )?.label
            }
          </td>
        </tr>
        <tr>
          <td>Wind Speed:</td>
          <td className={styles.queuePreviewRightColumn}>
            {
              roundConfigEnumsAsReactSelectOptions.windSpeed.find(
                (option) => option.value === roundInfo?.windSpeed
              )?.label
            }
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="py-2 border-b-2"></td>
        </tr>
        <tr>
          <td colSpan={2} className="pt-2 mt-2 pb-1 text-center text-gray-400">
            {!stateIsActive ? (
              <span>
                Turn on sim to enable Send to Sim, or Edit this round to select
                a different sim
              </span>
            ) : null}
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="align-right">
            <button
              className="w-1/3 text-left text-xs pt-2 active:text-green-700 disabled:opacity-50 disabled:cursor-not-allowed hover:border-gray-200 hover:border hover:rounded-xl"
              onClick={() =>
                handleEditSimulatorReservation(simulatorReservation.id)
              }
            >
              <FaEdit className="text-sm" /> Edit
            </button>
            <button
              className="w-1/3 text-xs pt-2 text-red-700 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => handleDeleteSimulatorReservation()}
            >
              <FaTrash className="text-sm" /> Delete
            </button>
            <button
              disabled={stateIsActive ? false : true}
              className="send-sim-reservation w-1/3 text-right text-xs pt-2 text-green-600 disabled:text-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() =>
                handleSendSimulatorReservation(simulatorReservation.id)
              }
            >
              <FaPaperPlane className="text-sm" /> Send to Sim
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default SimulatorReservationDetails;
