import Modal from "components/shared/Modal";
import Row from "components/ui/layout/Row";
import { useScorecardImageState } from "modules/scorecard/scorecardHooks";
import { getSimulatorBySimId } from "modules/simulator/simulatorSelectors";
import { FiPrinter } from "react-icons/fi";
import { MdEmail } from "react-icons/md";
import { useCurrentFacilityFeatureFlags } from "../../../modules/facility/facilityHooks";
import { styles } from "./dashboard.styles";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { useState } from "react";

const ScorecardModal: React.FC<ScorecardModalProps> = (props) => {
  const simulator = useSelector((state: RootState) =>
    getSimulatorBySimId(state, { simId: props.simulatorId })
  );
  const [scorecardImageState] = useScorecardImageState(simulator.id);
  const featureFlags = useCurrentFacilityFeatureFlags();

  const [scorecardHeight, setScorecardHeight] = useState(
    window.innerHeight - 250
  );

  function handleResize() {
    const innerHeight = window.innerHeight - 250;

    setScorecardHeight(innerHeight);
  }

  window.addEventListener("resize", handleResize);

  const PrintScoreCard = () => {
    return (
      <button onClick={window.print} className={styles.scorecardModalButton}>
        <FiPrinter /> Print
      </button>
    );
  };

  const EmailScoreCard = () => {
    return (
      <button className={styles.scorecardModalButton}>
        <MdEmail /> Email
      </button>
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      disableContentScrolling={true}
      body={
        <Row id="scorecard" cssClasses="text-center">
          {
            <img
              src={`data:image/jpeg;base64,${scorecardImageState}`}
              alt="Current Scorecard"
              style={{ height: scorecardHeight }}
            />
          }
        </Row>
      }
      header={
        <Row cssClasses={styles.scoreCardModalContainer}>
          <PrintScoreCard />
          {featureFlags?.["futureFeatures"] ? <EmailScoreCard /> : null}
        </Row>
      }
    />
  );
};

type ScorecardModalProps = {
  title?: string;
  modalName: string;
  simulatorId: string;
};

ScorecardModal.defaultProps = {
  title: "Get Scorecard",
};

export default ScorecardModal;
