import { FaBeer } from "react-icons/fa";
import { HiHand } from "react-icons/hi";
import { IoMdAlert } from "react-icons/io";
import {
  ServiceRequestType,
  SimStatus,
  TeeType,
  TimerStatus,
} from "types/enums";
import { GiGrass, GiHighGrass } from "react-icons/gi";
import {
  MdNorth,
  MdNorthEast,
  MdEast,
  MdSouthEast,
  MdSouth,
  MdSouthWest,
  MdWest,
  MdNorthWest,
  MdSportsGolf,
  MdGrass,
} from "react-icons/md";
import { IoMdSunny, IoIosPartlySunny, IoMdCloudOutline } from "react-icons/io";
import { FaWind, FaChild, FaMedal } from "react-icons/fa";
import { FiWind } from "react-icons/fi";
import { BsCloudFog2, BsCloudsFill } from "react-icons/bs";
import { TbWindOff } from "react-icons/tb";
import { WiHurricaneWarning } from "react-icons/wi";
import { RiCactusFill } from "react-icons/ri";
import { IconType } from "react-icons";

export type iconOptions = {
  icon: IconType;
  color: string;
};

export const serviceAlertIconHelper = {
  [ServiceRequestType.CC_REQUEST_FOOD.toString()]: FaBeer,
  [ServiceRequestType.CC_REQUEST_HELP.toString()]: HiHand,
  [ServiceRequestType.CC_REQUEST_STUCK.toString()]: IoMdAlert,
};

export const serviceAlertIconColorHelper = {
  [ServiceRequestType.CC_REQUEST_FOOD.toString()]: "#22c55e",
  [ServiceRequestType.CC_REQUEST_HELP.toString()]: "#fcd34d",
  [ServiceRequestType.CC_REQUEST_STUCK.toString()]: "#ef4444",
};

export const teeTypeIconColorHelper = {
  [TeeType.BLACK.toString()]: "text-black",
  [TeeType.BLUE.toString()]: "text-blue-500",
  [TeeType.GOLD.toString()]: "text-yellow-500",
  [TeeType.RED.toString()]: "text-red-500",
  [TeeType.WHITE.toString()]: "text-white bg-black",
  [TeeType.JUNIOR.toString()]: "text-yellow-900",
  [TeeType.SKILL.toString()]: "text-green-600",
};

export const simStatusIconHelper = {
  [TimerStatus.EXPIRED.toString()]: "far fa-alarm-exclamation",
  [TimerStatus.OFF.toString()]: "fas fa-power-off",
  [TimerStatus.ON.toString()]: "fas fa-power-off",
  [TimerStatus.PAUSED.toString()]: "fas fa-pause-circle",
  [TimerStatus.RUNNING.toString()]: "fas fa-play-circle",
};

export const simStatusIconColorHelper = {
  [TimerStatus.EXPIRED.toString()]: "text-red-500",
  [TimerStatus.OFF.toString()]: "text-gray-800",
  [TimerStatus.ON.toString()]: "text-blue-500",
  [TimerStatus.PAUSED.toString()]: "text-yellow-500",
  [TimerStatus.RUNNING.toString()]: "text-green-800",
  [TimerStatus.CLEARED.toString()]: "text-red-500",
};

export const timerStatusColorHelper = {
  [TimerStatus.EXPIRED.toString()]: "bg-red-100",
  [TimerStatus.OFF.toString()]: "bg-gray-300",
  [TimerStatus.ON.toString()]: "bg-green-800",
  [TimerStatus.PAUSED.toString()]: "bg-yellow-100",
  [TimerStatus.RUNNING.toString()]: "bg-green-800",
};

export const simStatusColorHelper = {
  [SimStatus[0]]: "text-gray-300",
  [SimStatus[1]]: "text-green-800",
  [SimStatus[2]]: "text-green-800",
  [SimStatus[3]]: "text-gray-300",
  [SimStatus[4]]: "text-green-800",
  [SimStatus[5]]: "text-green-800",
  [SimStatus[6]]: "text-green-800",
  [SimStatus[7]]: "text-gray-300",
  [SimStatus[8]]: "text-amber-800",
  [SimStatus[9]]: "text-green-800",
  [SimStatus[10]]: "text-green-800",
  [SimStatus[11]]: "text-gray-300",
  [SimStatus[12]]: "text-amber-800",
  [SimStatus[13]]: "text-amber-800",
  [SimStatus[14]]: "text-amber-800",
  [SimStatus[15]]: "text-gray-300",
  [SimStatus[16]]: "text-gray-300",
};

export const radioButtonWidthHelper = (options: number) => {
  switch (options) {
    case 1:
      return "w-auto";
    case 2:
      return "w-1/2";
    case 3:
      return "w-1/3";
    case 4:
      return "w-1/4";
    case 5:
      return "w-1/5";
    case 6:
      return "w-1/6";
    case 7:
      return "w-1/7";
    case 8:
      return "w-1/8";
    default:
      return "w-auto";
  }
};

export const groundConditionsIconHelper = [
  {
    icon: RiCactusFill,
    color: "#a16207",
  },
  {
    icon: RiCactusFill,
    color: "#eab308",
  },
  {
    icon: GiGrass,
    color: "#16a34a",
  },
  {
    icon: MdGrass,
    color: "#38bdf8",
  },
  {
    icon: GiHighGrass,
    color: "#2563eb",
  },
];

export const windDirectionIconHelper = [
  {
    icon: MdNorth,
    color: "#3b82f6",
  },
  {
    icon: MdNorthEast,
    color: "#3b82f6",
  },
  {
    icon: MdEast,
    color: "#3b82f6",
  },
  {
    icon: MdSouthEast,
    color: "#3b82f6",
  },
  {
    icon: MdSouth,
    color: "#3b82f6",
  },
  {
    icon: MdSouthWest,
    color: "#3b82f6",
  },
  {
    icon: MdWest,
    color: "#3b82f6",
  },
  {
    icon: MdNorthWest,
    color: "#3b82f6",
  },
];

export const weatherIconHelper = [
  {
    icon: IoMdSunny,
    color: "#eab308",
  },
  {
    icon: IoIosPartlySunny,
    color: "#ca8a04",
  },
  {
    icon: IoMdCloudOutline,
    color: "#1e40af",
  },
  {
    icon: BsCloudsFill,
    color: "#374151",
  },
  {
    icon: BsCloudFog2,
    color: "#9ca3af",
  },
];

export const windSpeedIconHelper = [
  {
    icon: TbWindOff,
    color: "#9ca3af",
  },
  {
    icon: FiWind,
    color: "#bfdbfe",
  },
  {
    icon: FiWind,
    color: "#60a5fa",
  },
  {
    icon: FiWind,
    color: "#2563eb",
  },
  {
    icon: FaWind,
    color: "#1e3a8a",
  },
  {
    icon: WiHurricaneWarning,
    color: "#7f1d1d",
  },
];

export const teeTypeIconHelper = [
  {
    icon: MdSportsGolf,
    color: "#000000",
  },
  {
    icon: MdSportsGolf,
    color: "#fbbf24",
  },
  {
    icon: MdSportsGolf,
    color: "#1e3a8a",
  },
  {
    icon: MdSportsGolf,
    color: "#9ca3af",
  },
  {
    icon: MdSportsGolf,
    color: "#7f1d1d",
  },
  {
    icon: FaChild,
    color: "#374151",
  },
  {
    icon: FaMedal,
    color: "#374151",
  },
];
