import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { aGHandicapAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { styles } from "./Events.styles";
import LockerUserEditForm from "../../control/events/LockerUserEditForm";

const LockerUserEditTray = () => {
  const dispatch = useAppDispatch();

  const isLockerUserHandicapTrayOpen = useSelector(
    (state: RootState) => state.nav?.lockerUserEditTray?.isOpen
  );

  const setLockerUserHandicapTray = () =>
    dispatch(aGHandicapAction({ isOpen: !isLockerUserHandicapTrayOpen }));

  return (
    <AnimatePresence>
      {isLockerUserHandicapTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: 0,
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.4 }}
            className={styles.trayContainer}
            data-testid="lockerUserHandicapTray"
            id="lockerUserHandicapTray"
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => setLockerUserHandicapTray()}
                  className={styles.button}
                >
                  Close
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>Locker aG Handicap Index</h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <LockerUserEditForm />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => setLockerUserHandicapTray()}
            className={styles.overlay}
            data-testid="lockerUserHandicapTrayOverlay"
            id="lockerUserHandicapTrayOverlay"
          />
        </>
      )}
    </AnimatePresence>
  );
};
export default LockerUserEditTray;
