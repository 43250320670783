import Row from "components/ui/layout/Row";
import {
  useScorecardTextState,
  useScorecardImageState,
} from "modules/scorecard/scorecardHooks";
import { FiFileText, FiImage } from "react-icons/fi";
import { modalAction } from "ui-modules/modals/modalSlice";
import { useAppDispatch } from "utils/hooks";
import styles from "./DashboardComponents.styles";

const ScorecardLinks: React.FC<ScorecardLinksProps> = (props) => {
  const dispatch = useAppDispatch();
  const [scorecardTextState] = useScorecardTextState(props.simId);
  const [scorecardImageState] = useScorecardImageState(props.simId);

  const setModal = (modalName: string) =>
    dispatch(modalAction({ isOpen: true, modalName }));

  const handleImageScorecardClick = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    setModal(`scorecardModal-${props.simId}`);
  };

  const handleTextScorecardClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    var wnd = window.open("", "Text Scorecard");
    const text = scorecardTextState ? atob(scorecardTextState) : "";
    wnd?.document.write(`<pre>${text}</pre>`);
  };

  return (
    <Row cssClasses="flex flex-row text-xs text-gray-300 poppins uppercase text-xs">
      {props.roundIsActive ? "Latest Scorecard:" : " Scorecard:"}
      {scorecardTextState ? (
        <button
          onClick={handleTextScorecardClick}
          value="text"
          className={`${styles.scorecardTextButton} ml-1`}
        >
          <FiFileText />
        </button>
      ) : null}
      {scorecardImageState ? (
        <button
          onClick={handleImageScorecardClick}
          value="image"
          className={`${styles.scorecardImageButton}`}
        >
          <FiImage />
        </button>
      ) : (
        " N/A "
      )}
    </Row>
  );
};
export default ScorecardLinks;

type ScorecardLinksProps = {
  simId: string;
  roundIsActive?: boolean;
  stateIsActive?: boolean;
  className?: string;
  useIcons?: boolean;
};
ScorecardLinks.defaultProps = {
  roundIsActive: false,
  stateIsActive: false,
  className: "mb-4 min-h-[50px]",
  useIcons: false,
};
