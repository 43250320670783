import Modal from "components/shared/Modal";
import StartAdHocRoundContainer from "./round-configuration-form/StartAdHocRoundContainer";
import Row from "components/ui/layout/Row";

const RoundControlModal: React.FC<RoundControlModalProps> = (props) => {
  return (
    <Modal
      modalName={props.modalName}
      hideHeader={false}
      title={props.title}
      hideFooter={true}
      modalSize="w-full h-1/2 md:w-1/2 md:h-auto"
      body={
        <Row cssClasses="h-full overflow-auto">
          <StartAdHocRoundContainer
            simulatorId={props.simulatorId}
            modalName={props.modalName}
            buildId={props?.buildId}
          />
        </Row>
      }
    />
  );
};

type RoundControlModalProps = {
  title?: string;
  buildId?: number;
  modalName: string;
  simulatorId: string;
};

RoundControlModal.defaultProps = {
  title: "Sim Name",
};

export default RoundControlModal;
