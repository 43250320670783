export const styles = {
  headerRow: ["flex", "w-full"].join(" "),
  innerContainer: ["flex", "flex-col", "w-full"].join(" "),
  titleRow: ["w-1/2", "sm:w-3/4", "p-3", "ml-4", "text-left"].join(" "),
  span: ["poppins", "text-md", "text-brandDarkGray dark:text-gray-50"].join(
    " "
  ),
  buttonContainer: ["w-1/2", "sm:w-1/4", "text-right"].join(" "),
  footerContainer: [
    "w-full",
    "md:text-right",
    "rounded-none",
    "md:rounded-br-lg",
  ].join(" "),
  closeButton: [
    "p-4",
    "font-normal poppins",
    "text-sm",
    "bg-gray-50",
    "border-l-2",
    "float-right",
    "hover:bg-gray-100",
    "rounded-none",
    "md:rounded-br-lg",
    "w-full",
    "sm:w-auto",
    "hover:shadow-none",
    "text-black",
    "shadow-inner",
  ].join(" "),
  closeIcon: ["fas", "fa-window-close", "mr-2", "text-red-500"].join(" "),
  innerModalContainer: [
    "bg-white dark:bg-zinc-800 ",
    "pointer-events-auto relative flex max-h-[90%] w-full flex-col overflow-hidden rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none dark:bg-surface-dark",
  ].join(" "),
  showInnerModalHeader: [
    "flex flex-shrink-0 items-center justify-between",
    "bg-white dark:bg-zinc-900",
    "border-b",
    "border-gray-300",
    "rounded-none",
    "md:rounded-t-lg",
    "cursor-move",
  ].join(" "),
  showInnerModalBody: [
    "scrollbar scrollbar-thumb-gray-400 scrollbar-track-gray-300 relative overflow-y-auto overflow-x-hidden p-4",
  ].join(" "),
  showInnerModalFooter: [
    "w-full",
    "items-left",
    "bg-white",
    "border-t",
    "border-gray-300",
    "bg-gray-50",
    "rounded-none",
    "md:rounded-b-lg",
    "flex flex-shrink-0 flex-wrap items-center justify-end ",
    "bottom-0",
    "h-12",
  ].join(" "),
  modalBackground: [
    "inset-0",
    "fixed",
    "flex",
    "z-9998",
    "bg-gray-600/50",
    "left-0 top-0 h-full w-full outline-none",
  ].join(" "),
};
export default styles;
