import Tippy from "@tippyjs/react";
import { IoMdInformationCircleOutline } from "react-icons/io";

const FormRow: React.FC<FormRowProps> = (props) => {
  return (
    <div className={`${props.hidden ? "hidden" : ""} mt-1 relative w-full`}>
      <div className={`${props.fontSize} flex flex-row pt-2`}>
        <div className="flex w-4/5">{props.header}</div>
        <div className="flex w-1/5 justify-end">
          {props.tooltip && (
            <Tippy
              content={props.tooltip}
              className="poppins text-black text-xs bg-gray-100 dark:bg-zinc-800 dark:text-gray-50 p-2 border-2 rounded float-right inline"
              placement="left"
            >
              <div>
                <IoMdInformationCircleOutline className="size-5 text-zinc-800 dark:text-gray-50" />
              </div>
            </Tippy>
          )}
        </div>
      </div>
      {props.children}
    </div>
  );
};
export default FormRow;

type FormRowProps = {
  header?: string;
  content?: React.ReactNode;
  fontSize?: string;
  hidden?: boolean;
  tooltip?: string;
};
FormRow.defaultProps = {
  header: "",
  fontSize: "text-xs poppins text-black dark:text-gray-200 pb-2",
};
