import React, { useState } from "react";
import { updateEntities } from "redux-query";
import { useAppDispatch } from "utils/hooks";
import { styles } from "../../ui/layout/Header.styles";
import { useAuthenticateToken, logoutUser } from "modules/user";

const LogoutButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoggedOut, setLoggedOut] = useState(false);

  const handleSignOut = async () => {
    await logoutUser();
    setLoggedOut(true);

    dispatch(
      updateEntities({
        facilities: () => undefined,
        simulators: () => undefined,
      })
    );
  };

  useAuthenticateToken(isLoggedOut);

  return (
    <div className="text-center">
      <button
        onClick={() => handleSignOut()}
        type="button"
        id="logoutButton"
        data-tip={"Logout"}
        data-for="headerButtonsTooltip"
      >
        <svg
          className={`text-zinc-800 dark:text-white fill-current`}
          fill-current="text-zinc-800 dark:text-white"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.50215 8.69774C7.05562 8.69774 7.5043 9.14644 7.5043 9.69994C7.5043 10.2534 7.05562 10.702 6.50215 10.702C5.94868 10.702 5.5 10.2534 5.5 9.69994C5.5 9.14644 5.94868 8.69774 6.50215 8.69774ZM10 1.55194V7.69774L10.0005 8.20274L17.442 8.20174L15.7196 6.47801C15.4534 6.2117 15.4292 5.79503 15.6471 5.50145L15.7198 5.41735C15.9861 5.15113 16.4027 5.12699 16.6963 5.3449L16.7804 5.41753L19.777 8.41514C20.043 8.68124 20.0674 9.09744 19.85 9.39104L19.7775 9.47514L16.7809 12.4785C16.4884 12.7718 16.0135 12.7723 15.7203 12.4798C15.4537 12.2138 15.429 11.7972 15.6465 11.5033L15.7191 11.4191L17.432 9.70174L10.0005 9.70274L10 16.4477C10 16.9141 9.5788 17.2674 9.1196 17.1863L0.61955 15.685C0.26121 15.6217 0 15.3103 0 14.9464V2.94777C0 2.58046 0.26601 2.2672 0.62847 2.20768L9.1285 0.81185C9.5851 0.73687 10 1.08921 10 1.55194ZM8.5 2.43514L1.5 3.58465V14.3173L8.5 15.5537V2.43514ZM11 15.699L11.7652 15.6992L11.867 15.6923C12.2335 15.6425 12.5158 15.3281 12.5152 14.9479L12.508 10.6977H11V15.699ZM11.002 7.19777L11 5.92311V2.19776L11.7453 2.19777C12.1245 2.19777 12.4381 2.47929 12.4883 2.84488L12.4953 2.94653L12.502 7.19777H11.002Z" />
        </svg>
      </button>
      <span className={styles.buttonText}>Logout</span>
    </div>
  );
};
export default LogoutButton;
