import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { styles } from "./Leagues.styes";
import { MdClose } from "react-icons/md";
import { FaSort } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { useAppDispatch } from "utils/hooks";
import {
  ClubhouseLeagueRoundPlayerDto,
  ClubhouseRoundDto,
  PointPlayerDto,
  PointScorecardsDto,
  PointTeamDto,
  RankedPlayerResult,
  RankedTeamResult,
  RoundLeaderboardPlayerDto,
} from "Models";
import { IoSearchOutline } from "react-icons/io5";
import { leagueScorecardTrayAction } from "ui-modules/nav/navSlice";
import { FormProvider, useForm } from "react-hook-form";
import { leagueModalVariant } from "utils/animations/animationHelper";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useLeagueGroupByFacilityLeagueRoundId } from "modules/leagues/leagueGroupHooks";
import {
  useApplyPointsToScorecards,
  useAutoAddScorecardsToRound,
  useLeagueLeaderboardByLeagueId,
  useLeagueRoundLeaderboardByRoundId,
  useLeagueRoundRankedScorecards,
} from "modules/leagues/leagueScorecardHooks";
import { getLeagueRoundCompetitionId } from "modules/leagues/leagueHelpers";
import {
  useGetClubhouseLeagueRoundDto,
  useSelectedLeagueRoundId,
} from "modules/leagues/leagueRoundHooks";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import LeaguePlayerScorecard from "./LeaguePlayerScorecard";
import LeaguePlayerRankedScorecard from "./LeaguePlayerRankedScorecard";
import LeaguePlayerNoRoundScorecard from "./LeaguePlayerNoRoundScorecard";
import { isApiStatusSuccess } from "utils/api/apiHelper";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import { setSelectedRoundId } from "modules/leagues/leagueSlice";
import { useSelectedLeague } from "modules/leagues/leagueHooks";
import { LeagueHandicapMethod } from "types/enums";
import LeagueRoundFinalizeConfirm from "./rounds/LeagueRoundFinalizeConfirm";
import { disableBackgroundScroll } from "utils/ui/uiHelpers";
import { AssignAndRankScorecardsRequestDto } from "ScorecardModels";
import { nestedSort } from "utils/arrays/arrayHelpers";
import LeagueRankedTeamScorecard from "./LeagueRankedTeamScorecard";
import LoadingAnimation from "components/svg/LoadingAnimation";

type LeagueRoundLeaderboardModalProps = {
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
  leagueRound?: ClubhouseRoundDto;
};

const LeagueRoundTeamLeaderboardModal: React.FC<LeagueRoundLeaderboardModalProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const { callback, leagueRound } = props;

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeague = useSelectedLeague();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();

  const methods = useForm();

  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [sortProperty, setSortProperty] = useState<string>("");

  const [
    leagueRoundLeaderboard,
    leagueRoundLeaderboardQueryStatus,
    refreshRoundLeaderboard,
  ] = useLeagueRoundLeaderboardByRoundId(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [showNet, setShowNet] = useState(
    selectedLeague?.settings.handicapMethod === LeagueHandicapMethod.NET
      ? true
      : false
  );
  const [finalizeLeaderboard, setFinalizeLeaderboard] = useState(false);
  const [addPointsToRound, setAddPointsToRound] = useState(false);
  const [autoAssignScorecards, setAutoAssignScorecards] = useState(false);

  const [
    leagueRoundRankedScorecards,
    leagueRoundRankedScorecardsQueryStatus,
    refreshLeagueRoundRankedScorecards,
  ] = useLeagueRoundRankedScorecards(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [, , refreshLeagueLeaderboard] = useLeagueLeaderboardByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );

  const [, , refreshLeagueRound] = useGetClubhouseLeagueRoundDto(
    currentFacilityId,
    selectedLeague.id,
    selectedLeagueRoundId
  );

  const [leagueRoster, ,] = useLeagueGroupByFacilityLeagueRoundId(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [, applyPointsToScorecard] = useApplyPointsToScorecards(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [
    autoAddScorecardToRoundQueryState,
    autoAddScorecardToRound,
  ] = useAutoAddScorecardsToRound(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const selectedLeagueRoundCompetitionId = getLeagueRoundCompetitionId(
    leagueRoundRankedScorecards?.scores
  );

  const selectedLeagueRoundIsActive =
    selectedLeagueRoundCompetitionId &&
    leagueRoundRankedScorecards?.scores[selectedLeagueRoundCompetitionId]
      ?.players.length > 0
      ? true
      : false;

  const playersWithNoRoundScorecard = leagueRoster.filter(
    (player: ClubhouseLeagueRoundPlayerDto) => {
      return !leagueRoundLeaderboard?.players?.find(
        (currentPlayer: RoundLeaderboardPlayerDto) => {
          return player.playerId === currentPlayer.playerGuid;
        }
      );
    }
  );

  const setScorecardTray = (value: boolean) => {
    disableBackgroundScroll();
    dispatch(leagueScorecardTrayAction({ isOpen: true }));
  };

  const handleLeagueRoundLeaderboardSubmit = async (data: PointPlayerDto[]) => {
    let payload = {} as PointScorecardsDto;
    const teams = methods.getValues("teams");

    payload.roundLeaderboardId = leagueRoundRankedScorecards?.roundLeaderboardId?.toString();
    payload.roundId = leagueRoundRankedScorecards?.roundId?.toString();
    payload.scores = {
      [selectedLeagueRoundCompetitionId]: {
        teams: teams?.map((team: PointTeamDto) => {
          return {
            teamLeaderboardId: team.teamLeaderboardId,
            points: team.points,
          };
        }),
      },
    };

    let response = await applyPointsToScorecard(payload);

    if (isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        "Round leaderboard updated successfully.",
        GenericNotificationType.SUCCESS
      );
      setAddPointsToRound(false);
      refreshLeagueRoundRankedScorecards();
      refreshLeagueRound();
    } else {
      showCustomNotification(
        response?.body?.detail,
        GenericNotificationType.ERROR
      );
    }
  };

  const finalizeLeaderboardCallback = () => {
    setFinalizeLeaderboard(false);
    refreshLeagueLeaderboard();
  };

  function handleRosterSortOnClick(sortProperty: string) {
    setSortProperty(sortProperty);
    setSortAscending(!sortAscending);
  }

  const LeagueModalHeader = () => {
    const handleCloseModal = async () => {
      dispatch(
        setSelectedRoundId({
          selectedLeagueRoundId: "",
        })
      );
      callback && callback(true);
    };

    const handleNetGrossToggle = (
      e: React.MouseEvent<HTMLButtonElement>,
      netScore: boolean
    ) => {
      e.preventDefault();
      setShowNet(netScore);
    };

    return (
      <div className={styles.modalHeader} id="modalHeader">
        <div className={`${styles.modalHeading} flex-col`}>
          <div className="flex w-full">
            <button
              type="button"
              tabIndex={-1}
              className={styles.modalCloseButton}
              onClick={(e) => {
                e.preventDefault();
                handleCloseModal();
              }}
            >
              <IoMdClose className="size-4" />
            </button>
            {leagueRound?.name} Leaderboard
          </div>
          <div className="flex w-full text-xs text-gray-400 dark:text-gray-50 ml-4 pl-6 pt-1">
            League: {selectedLeague?.name}
          </div>
        </div>
        <div className={styles.modalToggleContainerOuter}>
          <div>
            <button
              className={styles.modalSearchButton}
              onClick={() => setScorecardTray(true)}
            >
              <IoSearchOutline className="size-4 font-normal" />
              <span className="inline md:hidden poppins pr-2">Search</span>
            </button>
          </div>
          <div className={`${styles.modalToggleContainer}`}>
            <button
              className={`${showNet ? styles.modalToggleActive : ""} ${
                styles.modalToggleInactive
              }`}
              id="net"
              onClick={(e) => handleNetGrossToggle(e, true)}
            >
              <span className="">Net</span>
            </button>
            <button
              className={` ${!showNet ? styles.modalToggleActive : ""} ${
                styles.modalToggleInactive
              }`}
              id="gross"
              onClick={(e) => handleNetGrossToggle(e, false)}
            >
              <span className="">Gross</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const LeagueModalLoading = () => {
    return (
      <div className={styles.modalLoading}>
        <SpinnerAnimation color={"#f2f2e6"} />
      </div>
    );
  };

  const LeagueModalNoResults = () => {
    return (
      <div className={styles.modalNoResults}>
        <MdClose className={styles.noLeaguesIcon} />
        There are no scorecards for this leaderboard yet. Use the
        <button
          onClick={() => setScorecardTray(true)}
          className="border-b border-blue-400 mx-1"
        >
          Scorecard Lookup
        </button>
        to add scorecards.
      </div>
    );
  };

  useEffect(() => {
    if (!autoAssignScorecards) {
      autoAddScorecardToRound({} as AssignAndRankScorecardsRequestDto);
      setAutoAssignScorecards(true);
    }
  }, [autoAddScorecardToRound, autoAssignScorecards]);

  return (
    <>
      {finalizeLeaderboard && (
        <LeagueRoundFinalizeConfirm
          modalName={`FinalizeRound`}
          bodyText={`Are you sure you want to finalize ${leagueRound?.name}?`}
          confirmButtonText="Yes, Finalize"
          cancelButtonText="Cancel"
          callback={() => finalizeLeaderboardCallback()}
        />
      )}
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="w-full h-dvh"
        variants={leagueModalVariant}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleLeagueRoundLeaderboardSubmit)}
          >
            <LeagueModalHeader />
            {autoAddScorecardToRoundQueryState?.isFinished ? (
              <div className={styles.modalBody} id="modalBody" tabIndex={0}>
                {leagueRoundRankedScorecardsQueryStatus?.isFinished &&
                leagueRoundLeaderboardQueryStatus?.isFinished ? (
                  leagueRoundRankedScorecards && leagueRoundLeaderboard ? (
                    <div className="w-full">
                      <div className={styles.modalResultsHeaderRow}>
                        <div className="col-span-1">
                          <div
                            className="hover:cursor-pointer"
                            onClick={() =>
                              handleRosterSortOnClick("currentRound.place")
                            }
                          >
                            POS <FaSort className="h-4 w-4 mr-2" />
                          </div>
                        </div>
                        <div className="col-span-3">
                          <div
                            className="hover:cursor-pointer"
                            onClick={() => handleRosterSortOnClick("teamName")}
                          >
                            Team/Player <FaSort className="h-4 w-4 mr-2" />
                          </div>
                        </div>
                        <div className="hidden md:inline-block col-span-2">
                          Handicap
                        </div>
                        <div className="col-span-2 text-left">
                          <div
                            className="hover:cursor-pointer"
                            onClick={() =>
                              handleRosterSortOnClick(
                                showNet ? "netScore" : "grossScore"
                              )
                            }
                          >
                            Strokes <FaSort className="h-4 w-4 mr-2" />
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div
                            className="hover:cursor-pointer"
                            onClick={() =>
                              handleRosterSortOnClick("currentRound.points")
                            }
                          >
                            Points <FaSort className="h-4 w-4 mr-2" />
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div
                            className="hover:cursor-pointer"
                            onClick={() =>
                              handleRosterSortOnClick(
                                showNet ? "netScore" : "grossScore"
                              )
                            }
                          >
                            Total <FaSort className="h-4 w-4 mr-2" />
                          </div>
                        </div>
                        <div className="col-span-1"></div>
                      </div>
                      <div className="max-h-[500px] overflow-y-auto">
                        {!selectedLeagueRoundIsActive &&
                          leagueRoundLeaderboard?.players?.map(
                            (player: RoundLeaderboardPlayerDto, index) => (
                              <LeaguePlayerScorecard
                                leaderboardPlayer={player}
                                key={`${player?.playerGuid}-scorecard`}
                                finalizeScorecards={addPointsToRound}
                                index={index}
                                showNetScore={showNet}
                                roundPar={
                                  leagueRoundLeaderboard?.selectedHolesPar
                                }
                              />
                            )
                          )}
                        {selectedLeagueRoundIsActive &&
                          leagueRoundRankedScorecards?.scores[
                            selectedLeagueRoundCompetitionId
                          ]?.players &&
                          leagueRoundRankedScorecards.scores[
                            selectedLeagueRoundCompetitionId
                          ]?.teams
                            ?.sort(
                              nestedSort(
                                sortProperty.split(".")[0] || sortProperty,
                                sortProperty.split(".")[1] || null,
                                sortAscending ? "asc" : "desc"
                              )
                            )
                            .map((team: RankedTeamResult, index) => (
                              <>
                                <LeagueRankedTeamScorecard
                                  teamScorecard={team}
                                  showNetScore={showNet}
                                  addPointsToRound={addPointsToRound}
                                  index={index}
                                />
                                {leagueRoundRankedScorecards?.scores[
                                  selectedLeagueRoundCompetitionId
                                ]?.players
                                  .filter(
                                    (player: RankedPlayerResult) =>
                                      player.roundTeamId === team.teamId
                                  )
                                  .map((player: RankedPlayerResult, index) => (
                                    <LeaguePlayerRankedScorecard
                                      leaderboardPlayer={player}
                                      key={`${player?.playerGuid}-scorecard`}
                                      roundPar={
                                        leagueRoundLeaderboard?.selectedHolesPar
                                      }
                                      index={index}
                                      isTeamLeague={true}
                                      showNetScore={showNet}
                                      callback={() => {
                                        refreshRoundLeaderboard();
                                        refreshLeagueRoundRankedScorecards();
                                      }}
                                    />
                                  ))}
                              </>
                            ))}

                        {playersWithNoRoundScorecard.map((player) => (
                          <LeaguePlayerNoRoundScorecard
                            leaderboardPlayer={player}
                            key={`${player?.playerId}-scorecard`}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <LeagueModalNoResults />
                  )
                ) : (
                  <LeagueModalLoading />
                )}
              </div>
            ) : (
              <LoadingAnimation
                loadingText={"Searching for round scorecards..."}
              />
            )}
            <div
              className={`${
                playersWithNoRoundScorecard?.length === 0 && "hidden"
              } px-6 text-xs text-gray-300`}
            >
              <small>
                -- designates players in the round who have no scorecard
                assigned
              </small>
            </div>
            <div className={`${styles.modalFooter} mt-8`} id="modalFooter">
              {addPointsToRound && (
                <>
                  <button
                    className={styles.createButton}
                    type="submit"
                    tabIndex={100}
                  >
                    Save
                  </button>
                  <button
                    className={`${styles.leagueCancelButton}`}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setAddPointsToRound(!addPointsToRound);
                    }}
                    tabIndex={99}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </form>
        </FormProvider>
        <div className={`${styles.modalFooter}`}>
          {!addPointsToRound && (
            <>
              <button
                className={`${styles.finalizeRoundButton} w-auto mr-2 disabled:cursor-not-allowed
                }`}
                onClick={() => setFinalizeLeaderboard(!finalizeLeaderboard)}
                type="button"
                disabled={leagueRound?.isCompleted}
              >
                {leagueRound?.isCompleted
                  ? "Round has been Completed"
                  : "Finalize Round"}
              </button>
              <button
                className={`${styles.leagueCancelButton} w-auto disabled:hover:bg-brandGreyText disabled:cursor-not-allowed `}
                onClick={() => setAddPointsToRound(!addPointsToRound)}
                type="button"
                disabled={leagueRound?.isCompleted}
              >
                {leagueRound?.isScored ? "Edit Points" : "Add Points"}
              </button>
            </>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default LeagueRoundTeamLeaderboardModal;
