import toast from "react-hot-toast";
import { LockerUser } from "Models";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
import { FormRow } from "components/ui/form";
import { styles } from "./RosterAndGroups.styles";
import { titleCase } from "utils/enums/enumHelper";
import { ErrorMessage } from "@hookform/error-message";
import { FormProvider, useForm } from "react-hook-form";
import { isApiStatusSuccess } from "utils/api/apiHelper";
import { parsePhoneNumber } from "react-phone-number-input";
import { createLockerUser } from "modules/leagues/leagueRosterQueries";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";

const CreateLeaugeRosterLockerUser: React.FC<CreateLeaugeRosterLockerUserProps> = (
  props
) => {
  const { showHideComponentCallback, returnLockerUserGuidCallback } = props;

  const methods = useForm<LockerUser>();

  const handleCreateLockerUser = async (data: LockerUser) => {
    const response = createLockerUser(data);

    response.then((res) => {
      if (isApiStatusSuccess(res?.status)) {
        toast.success(data.firstName + " " + data.lastName + " user created", {
          id: `toast-handleCreateLockerUser${uuidv4()}`,
        });
        returnLockerUserGuidCallback(res.data.userId?.toString());
        showHideComponentCallback(false);
        methods.reset();
      } else {
        toast.error(titleCase(res.data), {
          id: `toast-handleCreateLockerUser${uuidv4()}`,
        });
      }
    });
  };

  const handlePhoneNumberChange = (value: string) => {
    if (value) {
      let phone = parsePhoneNumber(value)?.nationalNumber?.toString() || value;

      methods.setValue(
        "countryCode",
        parsePhoneNumber(value)?.countryCallingCode
      );
      methods.setValue("phoneNumber", phone);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="space-y-6"
        onSubmit={methods.handleSubmit(handleCreateLockerUser)}
        data-testid="form"
      >
        <motion.div className={styles.createLockerUserContainer}>
          <div className="poppins">Add Locker User</div>
          <FormRow
            header="First Name"
            fontSize={styles.lockerUserInputFormLabel}
          >
            <input
              {...methods.register("firstName", {
                required: "First name is required",
              })}
              className={
                methods.formState.errors.firstName
                  ? styles.lockerUserInputFormError
                  : styles.lockerUserInputForm
              }
              autoFocus={true}
              tabIndex={5}
            />
            <ErrorMessage
              errors={methods.formState.errors}
              name="firstName"
              render={({ message }) => (
                <div className="text-sm text-red-500 font-semibold">
                  {message}
                </div>
              )}
            />
          </FormRow>
          <FormRow
            header="Last Name"
            fontSize={styles.lockerUserInputFormLabel}
          >
            <input
              {...methods.register("lastName", {
                required: "Last name is required",
              })}
              className={
                methods.formState.errors.firstName
                  ? styles.lockerUserInputFormError
                  : styles.lockerUserInputForm
              }
              tabIndex={6}
            />
            <ErrorMessage
              errors={methods.formState.errors}
              name="lastName"
              render={({ message }) => (
                <div className="text-sm text-red-500 font-semibold">
                  {message}
                </div>
              )}
            />
          </FormRow>
          <FormRow header="Username" fontSize={styles.lockerUserInputFormLabel}>
            <input
              {...methods.register("userName")}
              className={
                methods.formState.errors.userName
                  ? styles.lockerUserInputFormError
                  : styles.lockerUserInputForm
              }
              tabIndex={6}
              onKeyUp={(e) => {
                methods.setValue(
                  "userName",
                  e.currentTarget.value.replace(/[^A-Za-z0-9 _]/g, "")
                );
              }}
              onBlur={(e) => {
                methods.setValue("userName", e.target.value.trim());
              }}
            />
            <span className="text-sm text-gray-500 font-semibold">
              Username can only be letters, numbers, underscores (_) and spaces
            </span>
          </FormRow>
          <FormRow
            header="Phone Number"
            fontSize={styles.lockerUserInputFormLabel}
          >
            <PhoneInputWithCountry
              name="phoneNumberControl"
              onChange={(event: string) => {
                handlePhoneNumberChange(event);
              }}
              rules={{ required: "Phone number is required" }}
              tabIndex={7}
              className={
                methods.formState.errors.phoneNumber
                  ? styles.lockerUserInputFormError
                  : styles.lockerUserInputForm
              }
              defaultCountry="US"
              limitMaxLength={true}
              withCountryCallingCode={true}
              countryCallingCodeEditable={false}
              countryOptionsOrder={["US", "CA", "AU", "|", "..."]}
            />
            <ErrorMessage
              errors={methods.formState.errors}
              name="phoneNumberControl"
              render={({ message }) => (
                <div className="text-sm text-red-500 font-semibold">
                  {message}
                </div>
              )}
            />
          </FormRow>
          <FormRow
            header="Email Address"
            fontSize={styles.lockerUserInputFormLabel}
          >
            <input
              {...methods.register("email", {
                required: "Email address is required",
              })}
              className={
                methods.formState.errors.email
                  ? styles.lockerUserInputFormError
                  : styles.lockerUserInputForm
              }
              tabIndex={8}
            />
            <ErrorMessage
              errors={methods.formState.errors}
              name="email"
              render={({ message }) => (
                <div className="text-sm text-red-500 font-semibold">
                  {message}
                </div>
              )}
            />
          </FormRow>
          <FormRow>
            <div className="flex justify-start mb-2">
              <button
                type="submit"
                className={styles.lockerUserInputFormButton}
                tabIndex={9}
              >
                Create Locker User
              </button>
              <button
                onClick={() => showHideComponentCallback(false)}
                className={styles.lockerUserInputFormButtonAlt}
                tabIndex={10}
              >
                Cancel
              </button>
            </div>
          </FormRow>
        </motion.div>
      </form>
    </FormProvider>
  );
};

export default CreateLeaugeRosterLockerUser;

type CreateLeaugeRosterLockerUserProps = {
  showHideComponentCallback: React.Dispatch<React.SetStateAction<boolean>>;
  returnLockerUserGuidCallback: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};
